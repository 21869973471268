import React, { Component } from 'react';
import { getListPath } from '../constants/pathConstants';
import { FETCH_RELATED_LIST_API } from '../constants/apiConstants';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';

class wholeSearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedLists: [],
      relatedLoading: false,
      listTitle: '',
    };
  }
  onHandleChange = async (e) => {
    const { name, value } = e.target;

    await this.setState({
      [name]: value,
    });
    if (name === 'listTitle') this.checkForRelatedList(e);
  };
  checkForRelatedList = async (e) => {
    /* if (this.props.token) return; */

    e.preventDefault();
    let title = this.state.listTitle;
    console.log(title);
    let relatedLists = [];
    if (title.length > 4) {
      this.setState({ relatedLoading: true });
      //fetch related list
      const relatedListsData = await this.fetchRelated(title);
      if (relatedListsData.status === 200) {
        relatedLists = relatedListsData.data.data;
      }
      this.setState({ relatedLists, relatedLoading: false });
    } else {
      this.setState({ relatedLists, relatedLoading: false });
    }
  };

  fetchRelated = async (query) => {
    let data = [];

    const reqOptions = {
      method: 'POST',
      url: FETCH_RELATED_LIST_API,
      headers: {
        'x-access-token': this.props.token,
      },
      data: {
        search_query: query,
      },
    };

    console.log('Fetching related list with data:', reqOptions);

    await axios(reqOptions)
      .then((res) => {
        //response is an array of related lists
        data = res;
        console.log(res);
      })
      .catch((error) => {
        console.error(error);
      });
    return data;
  };

  render() {
    const { relatedLists, relatedLoading, listTitle } = this.state;
    const renderRelatedLists = () => {
      if (relatedLoading) {
        return (
          <p>
            Loading <i className='fas fa-spinner'></i>
          </p>
        );
      }
      return relatedLists.length !== 0 ? (
        relatedLists.map((item, id) => (
          <li key={id}>
            <Link
              to={getListPath(item.slug, item._id)}
              target='_blank'
              rel='noopener noreferrer'
            >
              {item.name}
            </Link>
          </li>
        ))
      ) : (
        <>
          {listTitle.length > 0 ? (
            <p className={'related-no-data'}>
              Your list seems to be unique. <br /> No similar lists found.
            </p>
          ) : null}
        </>
      );
    };
    console.log(listTitle);
    console.log(relatedLists);
    return (
      <div>
        <input
          placeholder='search'
          value={listTitle}
          onChange={this.onHandleChange}
          onBlur={this.checkForRelatedList}
        />
        <div>
            <span>
              <SearchIcon />
            </span>
            <input
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
         
          </div>

        {renderRelatedLists()}
      </div>
    );
  }
}
export default wholeSearchBar;
