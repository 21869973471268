import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import MyLoader from "./Nested/FolderLoader";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {
    fetchStack,
    fetchFldrItemDetails,
    pinStackItem,
    deleteStackItem,
} from "../actions/apiActions";
import {
    getListPath,
    getStackPath,
    getProfilePath,
} from "../constants/pathConstants";
import Swal from "sweetalert2";
import ErrorAlert from "./Nested/ErrorAlert";
import StackModal from "./Nested/StackModal";
import ShareIcons from "./ShareIcons";
import QRShareModal from "./Nested/QRShareModal";

export default function Stack(props) {
    const [filteredLists, setFilteredLists] = useState([]);
    const [query, setQuery] = useState("");
    const [alert, setAlert] = useState({ show: false, msg: null, type: null });
    const [folders, setFolders] = useState([]);
    const [pinned, setPinned] = useState([]);
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { loading: true, stack: {}, lists: [] }
    );

    const AutoHideSnackbar = () => {
        setAlert({
            show: false,
        });
    };

    const snackBar = (type, msg) => {
        setAlert({
            show: true,
            msg: msg,
            type: type,
        });
    };

    useEffect(() => {
        (async () => {
            let { aid, token } = props;
            let { id, name } = props.match.params;
            let dataForStack = {
                    aid,
                    name,
                    folderId: id,
                },
                dataForDetails = {
                    aid,
                    fid: id,
                };
            let fetchedStack = await fetchStack(dataForStack, token);
            let details = await fetchFldrItemDetails(dataForDetails, token);
            fetchFolders();

            if (fetchedStack.success) {
                if (details.success) {
                    let allLists = details.data.lists;
                    let pinnedLists = allLists.filter((list) => {
                        return list.pinned;
                    });
                    let unpinnedLists = details.data.lists.filter((list) => {
                        return !list.pinned;
                    });
                    setPinned(pinnedLists);
                    setState({
                        lists: unpinnedLists,
                    });
                }
                setState({
                    stack: fetchedStack.data,
                    loading: false,
                });
            } else {
                props.history.push("/notfound");
            }
        })();
    }, []);

    const fetchFolders = async () => {
        let res = await props.fetchBoards(
            { aid: props.aid, requester: props.user_name },
            props.token
        );

        if (res) {
            let folders = res.folderData,
                boards = res.boardData;

            boards.map((board) => {
                board.folders.map((item) => {
                    folders.push(item);
                });
            });
            setFolders(folders);
        }
    };

    const handleLink = (e, list) => {
        e.preventDefault();
        props.history.push(getListPath(list.slug, list.id));
    };

    const handleSearch = (e) => {
        e.preventDefault();
        let query = e.target.value;
        setQuery(query);
        if (!query) {
            return;
        }
        let filteredPinned = pinned.filter((list) => {
                return list.name.toLowerCase().includes(query);
            }),
            filteredLists = lists.filter((list) => {
                return list.name.toLowerCase().includes(query);
            });

        let filtered = filteredPinned.concat(filteredLists);

        setFilteredLists(filtered);
    };

    const handleEditFolder = async (data) => {
        let { name, privacy, purpose } = data;

        if (
            folders.filter((folder) => {
                return folder.name === name;
            }) &&
            folders[0].name === name
        ) {
            snackBar("error", "You already have this Stack.");
            return;
        }
        data = {
            aid: props.aid,
            fldrId: stack._id,
            name: name,
            purpose: purpose,
            private: privacy,
        };
        let res = await props.editFolder(data, props.token);
        if (res) {
            props.history.push(getStackPath(name, stack._id));
            let updatedStack = stack;
            updatedStack.name = name;
            updatedStack.purpose = purpose;
            updatedStack.private = privacy;
            setState({
                stack: updatedStack,
            });
            snackBar("success", "Stack edited successfully.");
        } else {
            snackBar("error", "Something went wrong.");
        }
    };

    const handleDeleteFolder = async (e) => {
        e.preventDefault();
        const { value: cta } = await Swal.fire({
            title: "Are you sure?",
            text: "This action can't be undone",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        });

        if (cta) {
            const { aid, token } = props;
            let data = {
                aid,
                folderId: stack._id,
            };
            await props.deleteFolder(data, token);
            props.history.push("/");
        }
    };

    const handlePinStackItem = async (e, lsid) => {
        e.stopPropagation();
        e.preventDefault();
        let data = {
            aid: props.aid,
            folderId: stack._id,
            lsid,
        };

        let res = await pinStackItem(data, props.token);
        if (res) {
            let tempPinned = pinned,
                tempLists = lists;
            let indexInPinned = tempPinned
                .map((pinned) => {
                    return pinned.id;
                })
                .indexOf(lsid);
            setState({
                lists: [],
            });
            setPinned([]);
            if (indexInPinned !== -1) {
                let item = tempPinned[indexInPinned];
                item.pinned = false;
                tempPinned.splice(indexInPinned, 1);
                tempLists.splice(0, 0, item);
                setPinned(tempPinned);
                setState({
                    lists: tempLists,
                });
            } else {
                let indexInLists = tempLists
                    .map((list) => {
                        return list.id;
                    })
                    .indexOf(lsid);
                let item = lists[indexInLists];
                item.pinned = true;
                tempPinned.splice(0, 0, item);
                tempLists.splice(indexInLists, 1);
                setPinned(tempPinned);
                setState({
                    lists: tempLists,
                });
            }
        }
    };

    const handleDeleteStackItem = async (e, lsid) => {
        e.stopPropagation();
        e.preventDefault();
        const { value: cta } = await Swal.fire({
            title: "Are you sure?",
            text: "This action can't be undone",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        });

        if (cta) {
            let data = {
                aid: props.aid,
                folderId: stack._id,
                lsid,
            };

            let res = await deleteStackItem(data, props.token);
            if (res) {
                let updatedPinned = pinned.filter((list) => {
                        return list.id !== lsid;
                    }),
                    updatedLists = lists.filter((list) => {
                        return list.id !== lsid;
                    });
                setPinned(updatedPinned);
                setState({
                    lists: updatedLists,
                });
            }
        }
    };

    const handleGoBack = () => {
        if (props.history.action === "PUSH") {
            props.history.goBack();
        } else {
            props.history.push(getProfilePath(props.user_name));
        }
    };

    const visitUserProfile = e => {
        e.preventDefault();
        props.history.push(getProfilePath(stack.profile[0].user_name))
    }

    const renderList = (list) => {
        let key = Object.keys(list)[0];
        let items = list[key];
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary
                    aria-controls="panel-content"
                    id="panel-header"
                >
                    <img
                        src={"/assets/lists.png"}
                        alt=""
                        className="list-icon"
                    />
                    <span className="ml-3 list-title-name">{list.name}</span>
                    <div className="icon-container">
                        <i
                            className="fas fa-link"
                            onClick={(e) => handleLink(e, list)}
                        ></i>
                        {stack.aid === props.aid ? (
                            <i
                                className="fas fa-trash-alt"
                                onClick={(e) =>
                                    handleDeleteStackItem(e, list.id)
                                }
                            />
                        ) : null}
                        {list.pinned ? (
                            stack.aid === props.aid ? (
                                <i
                                    className="fas fa-thumbtack"
                                    style={{ color: "#5270ff" }}
                                    onClick={(e) =>
                                        handlePinStackItem(e, list.id)
                                    }
                                />
                            ) : (
                                <i
                                    className="fas fa-thumbtack"
                                    style={{ opacity: "100%" }}
                                />
                            )
                        ) : stack.aid === props.aid ? (
                            <i
                                className="fas fa-thumbtack"
                                onClick={(e) => handlePinStackItem(e, list.id)}
                            />
                        ) : null}
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <ol className={"alpha list-show-modal"}>
                        {items
                            ? items.map((item, i) => {
                                  return (
                                      <li key={i} className="list-item-folder">
                                          {i + 1}. {item}
                                      </li>
                                  );
                              })
                            : null}
                        <li key={items.length - 1} className="list-item-folder">
                            ...
                        </li>
                    </ol>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    const renderShareIcons = () => {
        const shareUrl = window.location.href;
        const title = "Hey, Checkout this Stack!";
        return <ShareIcons shareUrl={shareUrl} title={title} />;
    };

    let { loading, lists, stack } = state;

    return (
        <div className="container-fluid stack-container">
            <ErrorAlert
                AutoHideSnackbar={AutoHideSnackbar}
                showAlert={alert.show}
                message={alert.msg}
                status={alert.type}
            />
            <div className="container">
                {loading ? (
                    <MyLoader />
                ) : (
                    <>
                        <i
                            className="fa fa-times close-btn-general mt-3"
                            onClick={handleGoBack}
                            title="Go back"
                        />
                        <div className="shareIcon-container-aboveMdSize">
                            {renderShareIcons()}
                        </div>
                        <div className="stack-list-purpose mb-2 mt-3">
                            <div className="row">
                                <div className="list-title mx-auto">
                                    <h4>
                                        {stack.name.toUpperCase()}
                                    </h4>
                                    {stack.purpose ? (
                                        <h6>
                                            <b>{stack.purpose}</b>
                                        </h6>
                                    ) : (
                                        <h4>{""}</h4>
                                    )}
                                </div>
                            </div>
                            <div className="stack-footer">
                                <h5 onClick={e => visitUserProfile(e)}>@{stack.profile[0].user_name}</h5>

                                {stack._id && stack.aid === props.aid ? (
                                    <>
                                        <div className="stack-actions">
                                            <StackModal
                                                mode="Update"
                                                name={stack.name}
                                                private={stack.private}
                                                purpose={stack.purpose}
                                                handleEditFolder={
                                                    handleEditFolder
                                                }
                                            />
                                            <div
                                                className="dropdown-item"
                                                style={{cursor: "pointer"}}
                                                onClick={(e) =>
                                                    handleDeleteFolder(e)
                                                }
                                            >
                                                Delete
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                        <div className="discover-search">
                            <input
                                type={"search"}
                                placeholder={"Search folder..."}
                                onFocus={(e) => (e.target.placeholder = "")}
                                onBlur={(e) =>
                                    (e.target.placeholder = "Search folder...")
                                }
                                className={"home_search"}
                                value={query}
                                onChange={handleSearch}
                            />
                        </div>
                        <div className="row">
                            {pinned.length && !query
                                ? pinned.map((list, i) => (
                                      <div
                                          className="col col-lg-4 col-md-6 col-sm-12 mb-3 mt-3"
                                          key={i}
                                      >
                                          {renderList(list)}
                                      </div>
                                  ))
                                : null}
                            {query ? (
                                filteredLists.length ? (
                                    filteredLists.map((list, i) => (
                                        <div
                                            className="col col-lg-4 col-md-6 col-sm-12 mb-3 mt-3"
                                            key={i}
                                        >
                                            {renderList(list)}
                                        </div>
                                    ))
                                ) : (
                                    <div className="col mx-auto text-center m-4">
                                        <span className="ml-3 list-title-name text-center">
                                            Nothing to see here :)
                                        </span>
                                    </div>
                                )
                            ) : lists.length ? (
                                lists.map((list, i) => (
                                    <div
                                        className="col col-lg-4 col-md-6 col-sm-12 mb-3 mt-3"
                                        key={i}
                                    >
                                        {renderList(list)}
                                    </div>
                                ))
                            ) : pinned.length === 0 ? (
                                <div className="col mx-auto text-center m-4">
                                    <span className="ml-3 list-title-name text-center">
                                        Nothing to see here :)
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </>
                )}
            </div>
            <QRShareModal />
        </div>
    );
}

Stack.propTypes = {
    aid: PropTypes.string.isRequired,
    user_name: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    fetchBoards: PropTypes.func.isRequired,
    editFolder: PropTypes.func.isRequired,
    deleteFolder: PropTypes.func.isRequired,
};
