import React, { Component } from 'react';
import { Doughnut, Chart } from "react-chartjs-2";

class ListCategories extends Component {
  state = {
    data: {
      labels: [],
      datasets: [
        { 
          total: 1,
          data: [],
          backgroundColor: ["#afb42b66", "#3f51b566", "#e91e6366", "#79554866", "#00968866", "#259b2466", "#8bc34a66", "#ff572266", "#ff980066", "#00bcd466", "#5677fc66", "#607d8b66", "#9c27b066", "#03a9f466", "#673ab766", "#e51c2366"],
          borderColor: ["#afb42b80", "#3f51b580", "#e91e6380", "#79554880", "#00968880", "#259b2480", "#8bc34a80", "#ff572280", "#ff980080", "#00bcd480", "#5677fc80", "#607d8b80", "#9c27b080", "#03a9f480", "#673ab789", "#e51c2380"],
        }
      ]
    }
  }
  
  componentWillReceiveProps(props) {
    var temp = this.state.data;
    temp.datasets[0].total = this.props.total;
    temp.labels = this.props.categArr.categ;
    temp.datasets[0].data = this.props.categArr.listCnt;
    this.setState({ data: temp });
  }
  
  render() {
    return (
      <React.Fragment>
        <span className="chart-title">{this.props.chartName}</span>
        <Doughnut
          data={this.state.data}
          options={{
            cutoutPercentage: 40,
            legend: {
              display: true,
              position: "right",
              labels: {
                fontColor: "#68667a",
                fontSize: 12,
                fontStyle: "bold",
                generateLabels: (chart) => {
                  var data = chart.data, total = data.datasets[0].total;
                  if (data.labels.length && data.datasets.length) {
                    return data.labels.map(function (label, i) {
                      let meta = chart.getDatasetMeta(0),
                          ds = data.datasets[0],
                          arc = meta.data[i],
                          custom = arc && arc.custom || {},
                          getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault,
                          arcOpts = chart.options.elements.arc,
                          fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor),
                          stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor),
                          bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                      let value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];
                      return {
                        text: label + " " + (Math.round((100*(value/total))*10)/10) + "%",
                        fillStyle: fill,
                        strokeStyle: stroke,
                        lineWidth: bw,
                        hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                        index: i
                      };
                    });
                  } else {
                    return [];
                  }
                }
              }
            },
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 20,
                bottom: 0
              }
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
        ></Doughnut>
      </React.Fragment>
    )
  }
}

export default ListCategories;