import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ErrorAlert from '../Nested/ErrorAlert';
import { getProfilePath } from '../../constants/pathConstants';
import VerifyEmailModal from './VerifyEmailModal';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  }
});

class ChangeEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aid: this.props.aid,
      oldpassword: '',
      password: '',
      rePassword: '',
      wrongPassword: false,
      showPassword: false,
      errorMessage: null,
      showAlert: false,
      alertCode: '',
      message: '',
      disabled: true,
      email: '',
      showVerifyEmailModal: false,
      errorMessage: null,
      attempts: 3,
      timer: false,
      disabled: true
    };
  }

  componentWillReceiveProps() {
    this.setState({
      email: this.props.profile.email
    });
  }

  showSnackBar = (code, msg) => {
    this.setState({
      showAlert: true,
      alertCode: code,
      message: msg
    });
  };

  toggleVerifyModal = () => {
    this.setState({
      showVerifyEmailModal: !this.state.showVerifyEmailModal
    });
  };

  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false
    });
  };

  showPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handleChange = name => async event => {
    await this.setState({
      [name]: event.target.value,
      errorMessage: null
    });
    this.checkInput(name);
  };

  reHandleChange = name => async event => {
    //hide red color when the input is empty.
    if (event.target.value == '') {
      this.setState({
        wrongPassword: false
      });
    }
    await this.setState({
      [name]: event.target.value,
      errorMessage: null
    });
    this.checkInput(name);
  };

  handleSubmit = async () => {
    let data = {
      aid: this.props.aid,
      password: this.state.password,
      email: this.state.email
    };

    //console.log('Calling checkPassword');
    if (this.state.email === this.props.profile.email) {
      this.showSnackBar('error', 'Email is same');
    } else {
      await this.props.changeEmail(data, this.props.token);

      if (this.props.checkPasswordMessage === 'success') {
        this.props.sendVerifyEmail(data, this.props.token);
        // if (this.props.sendVerifyEmailMessage === 'success') {
        this.showSnackBar(
          'success',
          'A verification otp has send to your email. Please check your mail.'
        );
        this.setState({
          showVerifyEmailModal: true
        });
        // }
      } else {
        // these errors are given by api: maybe
        // - server error
        // - network error
        // - account not found
        // - invalid parameters
        // - old password not matched (high chances)
        // use some div to displayy as they occur in this.state.errorMessage
        this.setState({
          errorMessage: this.props.checkPasswordMessage,
          wrongPassword: true
        });
      }
    }
  };

  checkInput = name => {
    //console.log(name);
    this.setState({
      disabled: true
    });
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (name === 'email') {
      if (re.test(String(this.state.email))) {
        //valid email
      } else {
        //invalid email type
      }
    }
    if (name === 'password') {
      if (this.state.password === '') {
        //empty password box
      } else {
        //not empty password
      }
    }

    if (re.test(String(this.state.email)) && this.state.password !== '') {
      this.setState({
        disabled: false
      });
    }
  };

  setParentState = data => {
    this.setState(data);
  };

  handleGoBack = () => {
    if (this.props.history.action === 'PUSH') {
      this.props.history.goBack();
    } else {
      this.props.history.push(getProfilePath(this.props.user_name));
    }
  };

  render() {
    return (
      <div className='profile-setting-container container-fluid'>
        <div className='profile-setting-box'>
          <div className='row'>
            <VerifyEmailModal
              aid={this.props.aid}
              email={this.state.email}
              toggleVerifyEmailModal={this.toggleVerifyModal}
              VerifyEmailModalVisible={this.state.showVerifyEmailModal}
              changeEmailMessage={this.props.changeEmailMessage}
              verifyEmailOTP={this.props.verifyEmailOTP}
              token={this.props.token}
              handleGoBack={this.handleGoBack}
              setParentState={this.setParentState}
              disabled={this.state.disabled}
              sendVerifyEmail={this.props.sendVerifyEmail}
              fetchProfile={this.props.fetchProfile}
            />
            <ErrorAlert
              AutoHideSnackbar={this.AutoHideSnackbar}
              showAlert={this.state.showAlert}
              code={this.state.alertCode}
              message={this.state.message}
            />
            <div className='col col-12 col-sm-12'>
              <FormControl style={{ width: '100%' }}>
                <InputLabel
                  className="edit-user-input"
                  error={this.state.errorMessage}
                  htmlFor='re-password'
                  variant="outlined"
                >
                  Enter New Email
                </InputLabel>
                <Input
                  id='oldpassword'
                  label='Old password'
                  value={this.state.email}
                  type={'text'}
                  onChange={this.handleChange('email')}
                  margin='normal'
                  className='password-form'
                />
              </FormControl>
              <FormControl style={{ width: '100%' }}>
                <InputLabel
                  className="edit-user-input"
                  error={this.state.errorMessage}
                  htmlFor='re-password'
                >
                  Enter password
                </InputLabel>
                <Input
                  id='password'
                  label='New password'
                  value={this.state.password}
                  type={this.state.showPassword ? 'text' : 'password'}
                  onChange={this.handleChange('password')}
                  margin='normal'
                  className='password-form'
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={this.showPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                <div
                  className='wrong-password'
                  style={
                    this.state.wrongPassword
                      ? { display: 'block' }
                      : { display: 'none' }
                  }
                >
                  {this.state.errorMessage}
                </div>
              </FormControl>
              <div style={{display: "flex", justifyContent: "center", marginTop: "50px" }}>
                <button
                  className='btn-username btn-return'
                  onClick={this.handleGoBack}
                >
                  Return
                </button>
                <button
                  className='btn-username'
                  onClick={this.handleSubmit}
                  disabled={this.state.disabled}
                >
                  Change Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ChangeEmail.propTypes = {
  token: PropTypes.string.isRequired,
  aid: PropTypes.string.isRequired
};

export default withStyles(styles)(ChangeEmail);
