import React from "react";
import { connect } from "react-redux";

import {
  clearAuthError,
  loginUser,
  logoutUser,
  registerUser,
  toggleEmailVerified,
  retrySignup,
  verifyOTP,
  resendOtp,
  forgetPassword,
  resetPassword,
  googleLogin,
  linkedinLogin,
  twitterLogin,
} from "../actions/authActions";
import Nav from "../components/Nav";
import {
  hideLoginModal,
  showLoginModal,
  toggleLoginModal,
} from "../actions/baseActions";

const NavContainer = (props) => <Nav {...props} />;

const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    token: state.auth.token,
    aid: state.auth.aid,
    emailSent: state.auth.emailSent,
    authError: state.error.authError,
    loginModalVisible: state.base.loginModalVisible,
    resetPasswordBool: state.auth.resetPasswordBool,
    verify_email_type: state.auth.verify_email_type,
    loading: state.auth.loading,
  };
};

export default connect(mapStateToProps, {
  loginUser,
  registerUser,
  logoutUser,
  verifyOTP,
  resendOtp,
  clearAuthError,
  toggleLoginModal,
  toggleEmailVerified,
  showLoginModal,
  hideLoginModal,
  forgetPassword,
  resetPassword,
  googleLogin,
  linkedinLogin,
  twitterLogin,
  retrySignup,
})(NavContainer);
