import {CLEAR_ASK_ERR, HIDE_LOGIN_MODAL, SHOW_LOGIN_MODAL, TOGGLE_LOGIN_MODAL} from "../constants/actionTypes";

export const toggleLoginModal = () => dispatch => {
  dispatch({ type: TOGGLE_LOGIN_MODAL });
};

export const hideLoginModal = () => dispatch => {
  dispatch({ type: HIDE_LOGIN_MODAL });
};

export const showLoginModal = () => dispatch => {
  dispatch({ type: SHOW_LOGIN_MODAL });
};

export const clearAskError = () => dispatch => {
  dispatch({ type: CLEAR_ASK_ERR });
};