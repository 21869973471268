import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { HOME_PATH, SHARE_PATH, LOGIN_PATH } from '../constants/pathConstants';
import ShareList from './Nested/ShareList';

class Share extends Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   width: window.innerWidth,
    // };
  }

  // componentWillMount() {
  //   window.addEventListener('resize', this.handleWindowSizeChange);
  // }

  // // make sure to remove the listener
  // // when the component is not mounted anymore
  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.handleWindowSizeChange);
  // }

  // handleWindowSizeChange = () => {
  //   this.setState({ width: window.innerWidth });
  // };

  render() {
      return (
        <Fragment>
          <ShareList {...this.props} />
        </Fragment>
      );
    }
  }

Share.propTypes = {
  saveList: PropTypes.func.isRequired,
  saveListItem: PropTypes.func.isRequired,
  aid: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  createdList: PropTypes.array.isRequired,
};

export default Share;
