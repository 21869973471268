import React, { useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import new_icon from "./assets/listiee_new_icon.gif";
import { Zoom } from '@material-ui/core';




function CongratulateModal(props) {

    useEffect(() => {


	// blurring the background when the component loads

	
	let listCards = document.querySelectorAll(".list-cards");
	listCards.forEach((list) => { list.style.filter="blur(8px)"; } );

	document.querySelector(".MuiToolbar-regular").style.filter="blur(8px)";



	//closing the modal after 3 seconds

	setTimeout(function(){

	    props.closeCongratulate(true);

		// unblurring the background when the user clicks the close button

		let listCards = document.querySelectorAll(".list-cards");
		listCards.forEach((list) => { list.style.filter="none"; } );

		document.querySelector(".MuiToolbar-regular").style.filter="none";
		
	}, 3000);

	
    });


    let congratulateScreen = (

	<Zoom in="true">

	<div className="congratulate-modal" style={{ backgroundColor: "white", height: "63vh", width: "56vw", zIndex: "1", borderRadius: "19px", position: "absolute", top: "17vh", left: "20%", boxShadow: "9px 12px 10px -7px", overflowY: "auto"}}>

	    <img style={{position: "absolute", height: "100px", top: "1em"}} src={new_icon} />


	    {/* <CloseIcon style={{position: "absolute", top: "27px", right: "17px"}}  onClick={() => { 

		
		
		props.closeCongratulate(true);


		// unblurring the background when the user clicks the close button

		let listCards = document.querySelectorAll(".list-cards");
		listCards.forEach((list) => { list.style.filter="none"; } );

		document.querySelector(".MuiToolbar-regular").style.filter="none";
		


		} } /> */}

	    <h1 style={{textAlign: "center" }}> <img src="https://media2.giphy.com/media/1wX5TJZPqVw3HhyDYn/giphy.gif?cid=6c09b952d33f1d024d6604d9955292b05eac99f042a3c8e0&rid=giphy.gif&ct=s" style={{height: "200px", width: "200px", position: "relative", top: "3vh", left: "3vh"}} /></h1>

	    {/* Gif taken from */}

	    {/* https://giphy.com/gifs/party-tada-popper-1wX5TJZPqVw3HhyDYn */}

	    <h1 style={{margin: "30px 10px 10px 26px", fontSize: "2.2em", fontFamily: 'Lato', textAlign: "center"}}> You're all set! </h1>

	    <p style={{ margin: "27px 10px 10px 26px",fontSize: "1.2em",fontFamily: 'Open Sans', textAlign: "center"}}> Redirecting you to the Discover Page <img style={{height: "1em", width: "1.5em"}} src="https://media.tenor.com/images/ce772a5e35bdc66bef3d45110208d8b8/tenor.gif" />  </p>

	    {/*   Gif taken from  */}

	    {/* 
		<div className="tenor-gif-embed" data-postid="13427673" data-share-method="host" data-width="100%" data-aspect-ratio="1.0"><a href="https://tenor.com/view/ellipse-dots-cycle-gif-13427673">Ellipse Dots GIF</a> from <a href="https://tenor.com/search/ellipse-gifs">Ellipse GIFs</a></div><script type="text/javascript" async src="https://tenor.com/embed.js"></script>

	      */}
	    


	</div>
	</Zoom>
    );

    return congratulateScreen;
    
}


export default CongratulateModal;
