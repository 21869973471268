import React, { useState, useEffect } from "react";
import ChangeUserName from "./settings/ChangeUserName";
import EditProfile from "./settings/EditProfile";
import CloseIcon from "@material-ui/icons/Close";
import Cookies from "js-cookie";
import { COOKIE_AUTH } from "../constants/baseConstants";
import ErrorAlert from "./Nested/ErrorAlert";
import Checkbox from "@material-ui/core/Checkbox";

function WelcomeModal(props) {
  let [isUsernameSet, setIsUsernameSet] = useState(false);
  let [showAlert, setShowAlert] = useState(false);
  let [alertCode, setAlertCode] = useState("");
  let [message, setMessage] = useState("");
  let [checked, setChecked] = useState(false);
  let [isSocialLinkAdded, setIsSocialLinkAdded] = useState(false);

  let showSnackBar = (code, msg) => {
    setShowAlert(true);
    setAlertCode(code);
    setMessage(msg);
  };

  let AutoHideSnackbar = () => {
    setShowAlert(false);
  };

  let handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    // blurring the background when the component loads

    let listCards = document.querySelectorAll(".list-cards");
    listCards.forEach((list) => {
      list.style.filter = "blur(8px)";
    });

    document.querySelector(".MuiToolbar-regular").style.filter = "blur(8px)";

    //checking if the username has been set

    if (JSON.parse(Cookies.get(COOKIE_AUTH)).user_name != "") {
      console.log("username set");
      setIsUsernameSet(true);
    }
  });

  const checkBox = (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      onClick={(e)=>{e.stopPropagation()}}
      inputProps={{ "aria-label": "primary checkbox" }}

    />
  );

    const onClose = () => {


              if (isUsernameSet && checked && isSocialLinkAdded) {

                showSnackBar("info", "Your info can also be changed later under profile settings");

                setTimeout(()=>{
                  props.closeWelcome(true);
                }, 3000);



                // unblurring the background when the user clicks the close button

                let listCards = document.querySelectorAll(".list-cards");
                listCards.forEach((list) => {
                  list.style.filter = "none";
                });

                document.querySelector(".MuiToolbar-regular").style.filter =
                  "none";
              } else if (!isUsernameSet) {
                showSnackBar("error", "Please pick a username");
              } else if (!checked) {
                showSnackBar(
                  "error",
                  "Please confirm that you have read the username policy"
                );
              }
                else if (!isSocialLinkAdded) {
                    showSnackBar("warning", "You can also add links to other social media profiles");
                    setIsSocialLinkAdded(true);
                }
    }

  const anySocialLinkAdded = (flag) => {
    setIsSocialLinkAdded(flag);
  }



  let welcomeScreen = (
    <div
      style={{
        position: "absolute",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className="username-modal"
        style={{
          backgroundColor: "white",
          overflowX: "hidden",
          height: "800px",
          //   width: "700px",
          maxWidth: "700px",
          width: "85vw",
          border: "5px solid lightsteelblue",
          zIndex: "1",
          borderRadius: "19px",
          position: "relative",
          //   position: "absolute",
          //   top: "4vh",
          //   left: "23%",
          boxShadow: "5px 10px 10px -7px",
          overflowY: "auto",
        }}
      >
        {/*  Snackbar */}

        <ErrorAlert
          AutoHideSnackbar={AutoHideSnackbar}
          showAlert={showAlert}
          status={alertCode}
          message={message}
        />

        {/*  banner */}

        <img
          src="/assets/lists.png"
          height="30px"
          width="1100px"
          style={{ marginLeft: "-150px", marginTop: "0" }}
        />
        {/* header */}

        <h1
          className="username-modal-heading"
          style={{
            margin: "30px 10px 10px 26px",
            fontSize: "2.2em",
            fontFamily: "Lato",
            textAlign: "center",
          }}
        >
          {" "}
          Hi! Welcome to Listiee!{" "}
          <img
            src="https://camo.githubusercontent.com/e8e7b06ecf583bc040eb60e44eb5b8e0ecc5421320a92929ce21522dbc34c891/68747470733a2f2f6d656469612e67697068792e636f6d2f6d656469612f6876524a434c467a6361737252346961377a2f67697068792e676966"
            height="42"
            width="42"
            style={{ marginTop: "-9px" }}
          />
        </h1>

        {/* close button  */}

        <CloseIcon
          style={{ position: "absolute", top: "27px", right: "17px" }}
          onClick={onClose}
        />

        <p
          style={{
            margin: "27px 10px 10px 26px",
            fontSize: "1.2em",
            fontFamily: "Open Sans",
            textAlign: "center",
          }}
        >
          {" "}
          We're so glad you're here.{" "}
        </p>
        <p
          style={{
            margin: "60px 10px 10px 26px",
            fontSize: "1em",
            fontFamily: "Open Sans",
            textAlign: "center",
          }}
        >
          *Please pick a username:
        </p>

        <div className="username-policy" style={{ position: "relative" }}>
          <checkBox/>
          <ChangeUserName
            aid={props.aid}
            token={props.token}
            checkbox={checkBox}
            profile={props.profile}
            changeUserName={props.changeUserName}
            fetchProfile={props.fetchProfile}
            history={props.history}
            user_name={props.user_name}
          />
        </div>

        <hr
          style={{
            height: "4px",
            borderTop: "3px dotted lightsteelblue",
            width: "100",
          }}
        />

        <p
          style={{
            margin: "34px 10px 10px 34px",
            fontSize: "1em",
            fontFamily: "Open Sans",
            textAlign: "center",
          }}
        >
          {" "}
          Get your profile up to date.
        </p>
        <p style={{fontSize: "0.8em", color: "darkgray", textAlign: "center"}}> (Profile details can be changed anytime under profile settings).</p>

        <EditProfile
          aid={props.aid}
          token={props.token}
          editProfile={props.editProfile}
          profile={props.profile}
          fetchProfile={props.fetchProfile}
          editProfileMessage={props.editProfileMessage}
          history={props.history}
          user_name={props.user_name}
          closeWelcomeModal={onClose}
          anySocialLinkAdded={anySocialLinkAdded}
        />
        <div style={{ textAlign: "center", color: "red" }}>
          <button
            className="btn-username"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );

  return welcomeScreen;
}

export default WelcomeModal;
