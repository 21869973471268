import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  DISCOVER_PATH,
  getListPath,
  HOME_PATH,
  LOGIN_PATH,
} from "../constants/pathConstants";
import queryString from "query-string";
import SideBar from "./Utils/SideBar";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { ListLoaderForDiscover } from "./ListLoader";
import ListCard from "./ListCard";
import Helmet from "react-helmet";
import { Card, CardContent, Typography, Box } from "@material-ui/core";
import ErrorAlert from "./Nested/ErrorAlert";
import { isMobileOnly, isTablet } from "mobile-device-detect";
import Cookies from "js-cookie";
import { COOKIE_AUTH } from "../constants/baseConstants";
import WelcomeModal from "./WelcomeModal.jsx";
import CongratulateModal from "./CongratulateModal.jsx";
import FeedBackModal from "./FeedBackModal"


class Discover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
      showListModal: false,
      selectedList: 0,
      selectedListId: null,
      selectedListTitle: null,
      activePage: 1,
      currentPage: 1,
      todosPerPage: 36,
      category: "",
      folderClick: false,
      folders: [],
      FolderOptions: {},
      searchTags: ["alpha", "beta", "gamma"],
      searchFilters: ["Type 1", "Type 2", "Type 3", "Type 4", "Type 5"],
      isFetching: false,
      searchedLists: [],
      showAlert: false,
      alertMessage: "",
      alertCode: null,
      isNewUser: false,
      showCongratulateModal: false,
    };
  }

  //Snackbar auto-hide method
  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false,
    });
  };

  snackBar = (code, message) => {
    this.setState({
      showAlert: true,
      alertMessage: message,
      alertCode: code,
    });
  };

  listRef = React.createRef();

  componentDidMount = () => {
    if (this.props.token) {
      this.fetchFolders();
    }
    // console.log("scrolling")
    // this.activateOnRendered();
    // document.body.scrollTop = 0; // For Safari
    // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    // setting and updating the number of visits
    let visits = 0;
    if(document.cookie.indexOf("visits") !== -1){
      visits = Cookies.get("visits");
    }
    Cookies.set("visits", parseInt(visits)+1, { expires: 730 });

 };

  // activateOnRendered = () => {
  //   document.querySelector('.discover_container').focus();
  // };

  /***********************************************function for search list via elastic search*****************************/
  elasticSearch = async () => {
    let data = {
      aid: this.props.aid,
      email: this.props.email,
      query: "dummy",
    };
    this.setState({ isFetching: true });
    await this.props.searchList(data, this.props.token);
    this.setState({ isFetching: false });
  };
  /*****end**************** */

  navigateThroughKeyBoard = (e) => {
    e.preventDefault();
    console.log("keyboard navigation");
    const lists = document.querySelectorAll(".list-cards");
    const listArr = [...lists];
    const index = listArr.indexOf(document.activeElement);
    let nextIndex = 0;

    if (e.keyCode === 37 || e.keyCode === 38) {
      // up arrow
      nextIndex = index > 0 ? index - 1 : 0;
      lists[nextIndex].focus();
    } else if (e.keyCode === 39 || e.keyCode === 40) {
      // down arrow
      nextIndex = index + 1 < listArr.length ? index + 1 : index;
      lists[nextIndex].focus();
    } else if (e.keyCode === 13) {
      const listIndex = lists[index].getAttribute("listIndex");
      const lsid = lists[index].getAttribute("lsid");
      const title = lists[index].getAttribute("slug");
      this.setSelectedList(listIndex, lsid, title);
    }
  };

  async componentWillMount() {
    // if (this.props.location.state && this.props.location.state.login_required) {
    // 	this.props.showLoginModal();
    // }

    if (!this.props.token) {
      // this.props.history.push(LOGIN_PATH, {
      //   redirect_to: DISCOVER_PATH,
      // });
      this.setState({ isFetching: true });

      await this.props
        .fetchListv4({
          page: this.state.currentPage,
          query: this.state.query,
        })
        .then(() => {
          this.setState({ isFetching: false });
        });
    } else {
      // var queryStr = queryString.parse(this.props.location.search);
      // console.log(queryStr);
      // this.props.fetchList(queryStr, this.props.token);
      const data = {
        aid: this.props.aid,
        username: this.props.user_name,
      };
      if (
        !Object.keys(this.props.pstate).length ||
        this.props.refreshDiscover
      ) {
        this.setState({ isFetching: true });
        if (this.state.query) {
          console.log("Query", this.state.query);
          const data = {
            aid: this.props.aid,
            email: this.props.email,
            query: this.state.query,
            page: this.state.currentPage,
          };
          await this.props.searchList(data, this.props.token).then(async () => {
            // this.fetchListsBookmarkedByUser();
            await this.props
              .fetchAllUserBookmarks(data, this.props.token)
              .then(() => {
                this.setState({
                  isFetching: false,
                });
              });
          });
        } else {
          console.log("Query", this.state.query, this.state.currentPage);
          await this.props
            .fetchListv4(
              {
                page: this.state.currentPage,
                query: this.state.query,
              },
              this.props.token
            )
            .then(async () => {
              await this.props
                .fetchAllUserBookmarks(data, this.props.token)
                .then(() => {
                  this.setState({
                    isFetching: false,
                  });
                });
            });
        }
      } else {
        this.setState({
          currentPage: this.props.pstate.state.page,
          query: this.props.pstate.state.query,
        });
      }
      this.props.unsetRefreshDiscover();
    }

    // checking for new users by checking if their username is blank

    // Update: displaying the modal to all users who haven't seen the modal before, so now checking if the user has been shown the modal before or not

      // if(JSON.parse(Cookies.get(COOKIE_AUTH)).user_name == "" || document.cookie.indexOf('MODAL_SHOWN=') === -1)  {
      if(JSON.parse(Cookies.get(COOKIE_AUTH)).user_name == "")  {
	  console.log("new user");
	  this.setState({isNewUser: true});
      };
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.pstate).length) {
      this.setState({ query: nextProps.pstate.state.query });
    }
  }

  async fetchListasync(page, query) {
    if (!this.props.token)
      this.props.history.push(HOME_PATH, {
        login_required: true,
        redirect_to: DISCOVER_PATH,
      });
    else {
      if (query) {
        const data = {
          aid: this.props.aid,
          email: this.props.email,
          query: this.state.query,
          page,
        };
        await this.props.searchList(data, this.props.token).then(() => {
          this.setState({
            isFetching: false,
          });
        });
      } else {
        await this.props
          .fetchListv4(
            {
              page,
              query,
            },
            this.props.token
          )
          .then(() => {
            this.setState({ isFetching: false });
          });
      }
    }
    this.props.pageState({
      state: {
        page: page,
        query: this.state.query,
      },
    });
  }

  async setSelectedList(index, lsid, lsName, slug, aid, isAskList) {
    if (!this.state.folderClick) {
      await this.setState({
        selectedList: index,
        selectedListId: lsid,
        selectedListTitle: slug,
      });
      // set correct path in url

        console.log("isAskList: " + isAskList );
	(isMobileOnly || isTablet) ? (      this.props.history.push({

    pathname: `/l/${slug}---${lsid}----`,
    state: {
      isAskList: isAskList == true ? true : false
    }
  }))
      :

      (      this.props.history.push( {
        pathname: getListPath(this.state.selectedListTitle, this.state.selectedListId),
        state: {
          isAskList: isAskList == true ? true : false
        }

      })
	);

      this.props.pageState({
        state: {
          page: this.state.currentPage,
          query: this.state.query,
        },
      });
      // if(this.props.aid != aid){
      // 	this.handleView();
      // }
    }
    this.state.folderClick = false;
  }

  handleClickOnFolder = () => {
    this.state.folderClick = true;
  };

  // async handleView() {
  // 	await this.props.increaseView(this.state.selectedListId, this.props.aid);
  // }

  async handlePageLeft(e) {
    e.preventDefault();
    window.scrollTo(0, 0);
    this.setState({ isFetching: true });
    if (Object.keys(this.props.pstate).length) {
      await this.fetchListasync(
        this.state.currentPage - 1,
        this.props.pstate.state.query
      ).then(() => {
        this.setState({
          isFetching: false,
          currentPage: this.state.currentPage - 1,
          query: "",
        });
      });
    } else {
      await this.fetchListasync(this.state.currentPage - 1, "").then(() => {
        this.setState({
          isFetching: false,
          currentPage: this.state.currentPage - 1,
          query: "",
        });
      });
    }
  }

  async handlePageRight(e) {
    window.scrollTo(0, 0);

    e.preventDefault();
    this.setState({ isFetching: true });
    if (Object.keys(this.props.pstate).length) {
      await this.fetchListasync(
        this.state.currentPage + 1,
        this.props.pstate.state.query
      ).then(() => {
        this.setState({
          isFetching: false,
          currentPage: this.state.currentPage + 1,
          query: this.props.pstate.state.query,
        });
      });
    } else {
      await this.fetchListasync(this.state.currentPage + 1, "").then(() => {
        this.setState({
          isFetching: false,
          currentPage: this.state.currentPage + 1,
          query: "",
        });
      });
    }
  }

  async handlePageChange(e, pg) {
    window.scrollTo(0, 0);
    e.preventDefault();

    this.setState({ isFetching: true });
    if (Object.keys(this.props.pstate).length) {
      await this.fetchListasync(pg, this.props.pstate.state.query).then(() => {
        this.setState({
          isFetching: false,
          currentPage: pg,
          query: this.props.pstate.state.query,
        });
      });
    } else {
      await this.fetchListasync(pg, "").then(() => {
        this.setState({ isFetching: false, currentPage: pg, query: "" });
      });
    }
  }

  handleCategoryChange = async (e) => {
    var category = e.target.value;
    this.props.history.push("/categories?query=" + category);
  };

  //styling stuff
  handleOpacity = (index) => {
    document.getElementById(index).style.opacity = "1";
  };

  // pre-fetching all the folders
  fetchFolders = async () => {
    await this.props
      .fetchBoards(
        { aid: this.props.aid, requester: this.props.user_name },
        this.props.token
      )
      .then(() => {
        this.props.boards.forEach((board) => {
          board.folders.forEach((folder) => {
            this.setState({
              folders: [
                ...this.state.folders,
                { _id: folder._id, name: folder.name },
              ],
            });
          });
        });
        this.props.folders.forEach((folder) => {
          this.setState({
            folders: [
              ...this.state.folders,
              { _id: folder._id, name: folder.name },
            ],
          });
        });
      });
  };

  handleCloseWelcome = (closeWelcome) => {
    // if close welcome is true then close the welcome modal by setting the new user state to be false
    if (closeWelcome) {
      this.setState({ isNewUser: false });
      this.setState({ showCongratulateModal: true });
      Cookies.set("MODAL_SHOWN", "true", { expires: 730 })
    }
  };

  handleCloseCongratulate = (closeCongratulate) => {
    if (closeCongratulate) {
      this.setState({ showCongratulateModal: false });
    }
  };

  render() {
    const { showListModal, selectedList, currentPage, todosPerPage } =
      this.state;
    const aid = this.props.aid;
    var lists = [];
    if (Object.keys(this.props.lists).length != 0) {
      if (this.props.lists.result.length != 0) {
        lists = this.props.lists.result;
      }
    }

    // Logic for displaying todos
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const currentTodos = lists.slice(indexOfFirstTodo, indexOfLastTodo);

    const renderTodos = currentTodos.map((todo, index) => {
      return <li key={index}>{todo}</li>;
    });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(this.props.lists.total / todosPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    const ListLoader = () => {
      return (
        <div className="container">
          <div className="row">
            <div className="col col-12 col-md-6 col-lg-4">
              <ListLoaderForDiscover />
            </div>
            <div className="col col-12 col-md-6 col-lg-4">
              <ListLoaderForDiscover />
            </div>
            <div className="col col-12 col-md-6 col-lg-4">
              <ListLoaderForDiscover />
            </div>
          </div>
          <div className="row">
            <div className="col col-12 col-md-6 col-lg-4">
              <ListLoaderForDiscover />
            </div>
            <div className="col col-12 col-md-6 col-lg-4">
              <ListLoaderForDiscover />
            </div>
            <div className="col col-12 col-md-6 col-lg-4">
              <ListLoaderForDiscover />
            </div>
          </div>
        </div>
      );
    };

    const renderLists = () => {
      // console.log(this.props.searchedLists);
      // console.log(this.props)
      if (lists.length <= 0 || this.state.isFetching) return <ListLoader />;

      if (this.props.searchedLists.took > 0 && this.state.query) {
        // console.log(this.props.searchedLists)
        return this.props.searchedLists.hits.hits.map((listItem, index) => {
          if (listItem._type === "listitem") {
            return (
              <Card>
                <CardContent>
                  <Typography color="textPrimary" gutterBottom>
                    {listItem._source.list_name}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    {listItem._type}
                  </Typography>
                </CardContent>
              </Card>
            );
          }
          if (listItem._type === "list") {
            return (
              <Card>
                <CardContent>
                  <Typography color="textPrimary" gutterBottom>
                    {listItem._source.name}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom>
                    {listItem._type}
                  </Typography>
                </CardContent>
              </Card>
            );
          }
        });
      }

      return lists.map((listItem, index) => (
        <div
          key={index}
          className="list-cards col-12 col-md-6 col-md-4 col-lg-4 mb-2"
          tabIndex="0"
          listIndex={index}
          lsid={listItem._id}
          slug={listItem.slug}
        >
          {/* New List Card Component */}
          <ListCard

            setSelectedList={(isAskList) => {
              console.log("Discover ask check: " + isAskList)
              this.setSelectedList(
                index,
                listItem._id,
                listItem.name,
                listItem.slug,
                listItem.profile.map((profile) => profile.aid)[0],
                isAskList
              )
            }
            }

            handleClickOnFolder={this.handleClickOnFolder}
            id={index}
            listItem={listItem}
            index={index}
            addListToFolder={this.props.addListToFolder}
            fetchedFolders={this.state.folders}
            aid={this.props.aid}
            token={this.props.token}
            fetchFolders={this.fetchFolders}
            snackBar={this.snackBar}
            {...this.props}
          />
        </div>
      ));
    };

    const deleteSearchTags = (index) => {
      let { searchTags } = this.state;
      searchTags.splice(index, 1);
      this.setState({ searchTags });
    };

    const renderSearchTags = () => {
      let { searchTags } = this.state;
      return searchTags.map((tag, i) => (
        <span>
          {tag}{" "}
          <i
            className="fa fa-times"
            onClick={(e) => deleteSearchTags(i)}
            style={{ color: "lightgray" }}
          />
        </span>
      ));
    };

    const addSearchTags = (e) => {
      if (e.keyCode === 13 || e.keyCode === 188) {
        const tag = e.target.value;
        this.setState({ searchTags: [...this.state.searchTags, tag] });
        e.target.value = "";
      }
    };

    return (
      <div
        className={"discover_container"}
        // tabIndex='-1'
        // onKeyDown={this.navigateThroughKeyBoard}
      >
        {this.state.isNewUser && (
          <WelcomeModal
            {...this.props}
            closeWelcome={this.handleCloseWelcome}
          />
        )}


        {/* <FeedBackModal feedBackModalOpen={true}/> */}
	  {
	      this.state.isNewUser &&
	  <WelcomeModal {...this.props} closeWelcome={this.handleCloseWelcome} />
	  }

	  {
	     !this.state.isNewUser && this.state.showCongratulateModal &&
	      <CongratulateModal closeCongratulate={this.handleCloseCongratulate} />
	  }

        <Helmet>
          <title>Listiee: Discover lists, products, stacks, people etc.</title>
          <meta
            name="keywords"
            content="Discover lists, discover list stacks, discover digital collections, discover products, meet people, discover stack boards on listiee, "
          />
        </Helmet>
        <ErrorAlert
          AutoHideSnackbar={this.AutoHideSnackbar}
          showAlert={this.state.showAlert}
          message={this.state.alertMessage}
          status={this.state.alertCode}
        />
        {/* <div className={'row mt-4'}> */}
        {/* <SideBar
						handleCategoryChange={this.handleCategoryChange}
						aid={this.props.aid}
					/> */}
        {/* <br />
          <br />
          <br />
        </div> */}
        <div
          className="col-12"
          // style={{ marginTop: '-3vw' }}
        >
          <Box
            maxWidth={"1400px"}
            m={"auto"}
            // ref={provided.innerRef}
            // innerRef={provided.innerRef}
            // {...provided.droppableProps}
            // className='list-card-container '
          >
            <div
              className="row"
              style={{
                maxWidth: "1100px",
                rowGap: "2em",
                justifyContent: "space-around",
                // margin: 'auto'
              }}
            >
              {renderLists()}
            </div>
          </Box>
        </div>
        <div className="col-12 disc-wrap">
          {this.state.isFetching ? (
            ""
          ) : (
            <nav
              className="page-ctrl-wrap"
              aria-label="Page navigation example"
            >
              {Math.min(...pageNumbers) !== this.state.currentPage &&
              pageNumbers.length > 1 ? (
                <IconButton
                  className="left-page-btn"
                  onClick={(e) => this.handlePageLeft(e)}
                >
                  <KeyboardArrowLeft fontSize="small" />
                </IconButton>
              ) : (
                <IconButton className="left-page-btn dis" disabled>
                  <KeyboardArrowLeft fontSize="small" />
                </IconButton>
              )}
              {}
              <div style={{width:"500px"}}>
              {pageNumbers.length === 0 ? (
                <div
                  className="page-number mr-2"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => this.handlePageChange(e, 1)}
                >
                  <b>{1}</b>
                </div>
              ) : (
                pageNumbers.map((pg) =>
                  pg == this.state.currentPage ? (
                    <div
                      className="page-number mr-2"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.handlePageChange(e, pg)}
                    >
                      <b>{pg}</b>
                    </div>
                  ) : (
                    <div
                      className="page-number mr-2"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.handlePageChange(e, pg)}
                    >
                      {pg}
                    </div>
                  )
                )
              )}
              </div>
              {Math.max(...pageNumbers) !== this.state.currentPage &&
              pageNumbers.length > 1 ? (
                <IconButton
                  className="right-page-btn"
                  onClick={(e) => this.handlePageRight(e)}
                >
                  <KeyboardArrowRight fontSize="small" />
                </IconButton>
              ) : (
                <IconButton className="right-page-btn dis" disabled>
                  <KeyboardArrowRight fontSize="small" />
                </IconButton>
              )}
            </nav>
          )}
        </div>
      </div>
    );
  }
}

Discover.propTypes = {
  lists: PropTypes.object.isRequired,
  aid: PropTypes.string.isRequired,
};

export default Discover;
