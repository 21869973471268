import React, { Component } from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

export default class ProfilePlusButton extends Component {
    handleLinkShare = (e) => {
        e.preventDefault();
        this.props.history.push("/share");
    };

    render() {
        return (
            <div className="add-list-btn" style={{display: 'none',}}>
                <Fab style={fabStyle} aria-label="add" className="pulse-btn">
                    <i onClick={(e) => this.handleLinkShare(e)}>
                        <AddIcon />
                    </i>
                </Fab>
            </div>
        );
    }
}

const btnStyle = {
    bottom: "40px",
    position: "absolute",
    zIndex: "999",
    right: "20px",
};

const fabStyle = {
    backgroundColor: "#ffffff",
};
