import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import Button from "@material-ui/core/Button";
import EmailOTPInput from "../Utils/EmailOTPInput";
import ErrorAlert from "../Nested/ErrorAlert";
import { VERIFY_API, VERIFY_FORGET_API } from "../../constants/authConstants";

const OTPModal = (props) => {
  const [otp, setOtp] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [attempts, setAttempts] = useState(3);
  const [timer, setTimer] = useState(true);
  const [modalOpen, setModalOpen] = useState(true);
  const [alert, setAlert] = useState({ show: false, msg: null, type: null });

  let timeLeft = Number(30);

  const AutoHideSnackbar = () => {
    setAlert({
      show: false,
    });
  };

  const showSnackBar = (msg, type) => {
    setAlert({
      show: true,
      msg: msg,
      type: type,
    });
  };

  const onSendOTP = async (e) => {
    e.preventDefault();
    props.clearAuthError();
    const { aid, verify_email_type } = props;
    if (otp.trim() === "" || otp.trim().length < 6) {
      showSnackBar("Please enter Valid OTP", "error");
    } else {
      const data = {
        aid,
        code: otp,
      };

      if (props.verify_email_type === 1) {
        //for register
        props.verifyOTP(data, VERIFY_API, verify_email_type);
      } else {
        // for forgot password
        await props.verifyOTP(data, VERIFY_FORGET_API, verify_email_type);
        // if(props.resetPasswordBool){
        //     setModalOpen(false);
        // }
      }
    }
  };

  const timerFunction = () => {
    let elem = document.getElementById("timerDisplay");
    let timer = setInterval(() => {
      if (timeLeft === -1) {
        clearTimeout(timer);
        setDisabled(false);
        return;
      } else {
        elem.innerHTML = `Retry in: 00:${
          timeLeft >= 10 ? timeLeft : `0${timeLeft}`
        }`;
        timeLeft--;
      }
    }, 1000);
  };

  const onResendOTP = async () => {
    setTimer(false);
    setDisabled(true);
    setAttempts(attempts - 1);

    const { aid } = props;
    const data = {
      aid,
      attempt: attempts,
    };

    await props.resendOtp(data);

    if (attempts <= 0) {
      setDisabled(true);
      setTimer(false);
    } else {
      timeLeft = 30;
      timerFunction();
    }
  };

  return (
    <>
      <ErrorAlert
        AutoHideSnackbar={AutoHideSnackbar}
        showAlert={alert.show}
        message={alert.msg}
        status={alert.type}
      />
      <Modal
        className={"custom-modal"}
        isOpen={props.emailSent}
        // toggle={props.toggleVerifyEmailModal}
        autoFocus={true}
      >
        <div className={"modal_form otp-form mx-auto"}>
          <h5 className={"text-success otp-form__heading"}>
            A verification email has been sent. Please check your inbox.
          </h5>
          <EmailOTPInput onChange={(otp) => setOtp(otp)} verify={onSendOTP} />
          <div className="text-center">
            <Button
              className={"modal_form__button"}
              color="primary"
              variant={"contained"}
              type={"submit"}
              style={{ display: "inline", width: "90px" }}
            >
              Verify
            </Button>
            {"   "}
            {disabled ? (
              <h5
                className="text-danger otp-form__heading"
                style={{ display: "inline" }}
                id="timerDisplay"
              ></h5>
            ) : (
              <Button
                onClick={onResendOTP}
                className={"modal_form__button"}
                color="secondary"
                variant={"contained"}
                type={"submit"}
                disabled={disabled}
                style={{ display: "inline", width: "110px" }}
              >
                Resend
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OTPModal;
