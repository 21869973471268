import React, { Component } from 'react';

export default class searchitem extends Component {
  render() {
    return (<div>
      
      <input
      placeholder='search'
      >
      
      
      </input>

    </div>);
  }
}
