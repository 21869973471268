import React, { Component } from "react";
import { Link } from "react-router-dom";
import InstallPWA from './InstallPWA'

export default class Footer extends Component {
	render() {
		return (
			<footer>
				<div className="footer">
					<div className="social-wrapper flex-item">
						<ul>
							<h1><img src="/assets/logo.svg" alt="" /> <div class="version-ta mt-2">version 0.6 alpha</div></h1>
							<li><a href="https://twitter.com/listiee01" target="_blank" rel="noopener noreferrer"><i class="fab fa-twitter"></i></a></li>
							<li><a href="https://linkedin.com/company/listiee" target="_blank" rel="noopener noreferrer"><i class="fab fa-linkedin-in"></i></a></li>
							<li><a href="https://facebook.com/listiee01" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-f"></i></a></li>
						</ul>
					</div>

					<div className="content-wrapper flex-item">
						<ul>
							<div>Listiee</div>
							<li><Link to="/about">About</Link></li>
							<li><Link to="/team">Team</Link></li>
							<li><Link to="/story">Story</Link></li>
						</ul>
					</div>

					<div className="policies-wrapper flex-item">
						<ul>
							<div>Policies</div>
							<li><Link to="/disclaimer">Disclaimer</Link></li>
							<li><Link to="/privacy-policy">Privacy policy</Link></li>
							<li><Link to="/terms-of-use">Terms of use</Link></li>
						</ul>
					</div>

					<div className="help-wrapper flex-item">
						<ul>
							<div>Help</div>
							<li><Link to="/media">Media Relations</Link></li>
							<li><Link to="/investors">For investors</Link></li>
							<li><Link to="/guidelines">User Guidelines</Link></li>
						</ul>
					</div>

					<div className="engage-wrapper flex-item">
						<ul>
							<div>Engage</div>
							<li><Link to="/share">Publish lists</Link></li>
							<li><Link to="/request">Request lists</Link></li>
							{/* <li>Organize information</li> */}
							<li><Link to="/">Discover lists</Link></li>
							{/* <li>List stacks</li> */}
						</ul>
					</div>
				</div>
                <div><InstallPWA /> </div>
				<div className="copyright">© 2021 Listiee. All rights reserved.</div>
			</footer>
		);
	}
}
