import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { Component, Suspense, Fragment } from "react";
import socketIOClient from "socket.io-client";
import { Link } from "react-router-dom";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import Tooltip from "@material-ui/core/Tooltip";
import { isMobileOnly, isTablet } from "mobile-device-detect";
import {
  ADMIN_PATH,
  ASK_PATH,
  DISCOVER_PATH,
  UPDATES_PATH,
  EDIT_PATH,
  HOME_PATH,
  LIST_PATH,
  PROFILE_PATH,
  USER_PROFILE_PATH,
  REQUEST_PATH,
  SHARE_PATH,
  DISCLAIMER_PATH,
  TERMS_OF_USE_PATH,
  PRIVACY_POLICY_PATH,
  PROFILE_SETTING_PATH,
  SEARCH_RESULTS_PATH,
  PEOPLE_PATH,
  TAG_PATH,
  CATEGORIES_PATH,
  REPORT_PATH,
  FEEDBACK_PATH,
  BLOG_PATH,
  USERNAME_POLICY,
  LINKEDIN,
  RESPOND_INVITE,
  STACK_PATH,
  BOARD_PATH,
  COVID_PATH,
  COVID_REQUEST,
  COVID_VERIFIER_PATH,
  REGISTER_PATH,
  LOGIN_PATH,
  FORGOT_PATH,
} from "../constants/pathConstants";
import createListIcon from "./assets/createList.svg";
import homeIcon from "./assets/homeIcon.svg";
import { API_HOSTNAME } from "../constants/baseConstants";
// import Home from "./Home";
import Discover from "./Discover";
import NavContainer from "../containers/NavContainer";
import FooterContainer from "../containers/FooterContainer";
import Error404 from "./Error404";
import Share from "./Share";
import List from "./List";
import AdminContainer from "../containers/AdminContainer";
import Ask from "./Ask";
// import Request from "./Request";
import EditList from "./EditList";
// import Profile from "./Profile";
import Profile from "./PublicProfile";
// import UserProfile from "./UserProfile";
import Disclaimer from "./Disclaimer";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
import ProfileSettings from "./ProfileSettings";
import SearchResults from "./SearchResults";
import People from "./People";
import Tags from "./tags";
import Categories from "./Categories";
import Report from "./Report";
import Feedback from "./Feedback";
import Blog from "./Blog";
import { getRegion } from "../utils/utils";
import UserNamePolicy from "./UserNamePolicy";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import Invitation from "./Invitation";
import Team from "./Team";
import Career from "./Career";
import Investors from "./Investors";
import MediaRelations from "./MediaRelations";
import Story from "./Story";
import About from "./About";
import GuideLines from "./GuideLines";
// import Notes from "./Notes";
import Stack from "./Stack";
import Board from "./Board";
import Covid from "./Covid";
import CovidVerifier from "./CovidVerifier";
import CovidRequest from "./CovidRequest";
import ForgotPassword from "./Auth/ForgotPassword";
import Register from "./Auth/Register";
import Login from "./Auth/Login";
import { Spinner } from "reactstrap";
import CreateListPage from "./CreateListPage/CreateListPage";
import ListMobile from "./ListMobile";
import { red } from "@material-ui/core/colors";
import zIndex from "@material-ui/core/styles/zIndex";
import BlogPage from "../components/blogs/BlogPage";
import PublishBlog from "./blogs/PublishBlog";
import ErrorAlert from "./Nested/ErrorAlert";

import Discover1 from "./Discover1";
import Updates from "./UpdatesPage/Updates";
import { display } from "@mui/system";
import { app } from "firebase";

const ListSpecificPage = React.lazy(() =>
  import("../components/ListSpecificPage/ListSpecificPage")
);
const DiscoverPage = React.lazy(() =>
  import("../components/DiscoverPage/Discover")
);
const HomePage = React.lazy(() => import("../components/HomePage/HomePage"));
const HomePageMob = React.lazy(() =>
  import("../components/HomePage/HomePageMob")
);
const BetaReg = React.lazy(() =>
  import("../components/BetaRegistrationPage/BetaRegistrationPage")
);
const SuccessPage = React.lazy(() =>
  import("../components/BetaRegistrationPage/successPage")
);
const FabLinks = (props) => {
  if (
    props.location.pathname === LOGIN_PATH ||
    props.location.pathname === REGISTER_PATH ||
    props.location.pathname === FORGOT_PATH
  ) {
    return null;
  }

  if (
    props.location.pathname === SHARE_PATH ||
    props.location.pathname === ASK_PATH
  ) {
    return (
      <Fab // discover page icon  on create list page
        position={{ bottom: 8, right: 8 }}
        icon={
          <Tooltip title="Discover" aria-label="Discover">
            <img
              src={homeIcon}
              style={{ width: "40px" }} // as and share page icon
              onClick={(e) => props.history.push(DISCOVER_PATH)}
            />
          </Tooltip>
        }
        mainButtonStyles={{ backgroundColor: "#fff" }}
        event="none"
      />
    );
  }

  if (props.location.pathname === DISCOVER_PATH) {
    return (
      <Fab //create item icon on discover page
        position={{ bottom: 8, right: 10 }}
        icon={
          <Tooltip title="Create List" aria-label="New List">
            <img
              src={createListIcon}
              onClick={(e) => props.history.push(SHARE_PATH)}
            />
          </Tooltip>
        }
        mainButtonStyles={{ backgroundColor: "#fff" }}
        event="none"
      />
    );
  }

  return (
    !isMobileOnly && (
      // <Fab className="fabIconCraete" // create item icon on Liste item page
      //   position={{ bottom: -5, right: -2 }}
      //   icon={
      //     <Tooltip title="Create List" aria-label="New List">
      //       <img
      //         src={createListIcon}
      //         onClick={(e) => props.history.push(SHARE_PATH)}
      //       />
      //     </Tooltip>
      //   }
      //   event="none"
      //   mainButtonStyles={{ backgroundColor: "#fff" }}
      //   style={{zIndex: "-10px !important"}}
      // />
      <button
        className="fabIconCraete"
        onClick={(e) => props.history.push(SHARE_PATH)}
      >
        <img src={createListIcon} />
      </button>
    )
  );
};

const RenderComponent = ({ Comp, props, routerProps }) => {
  return (
    <div className={"main_container"}>
      <NavContainer {...props} {...routerProps} />
      <Comp {...props} {...routerProps} />
      {/* <FabLinks {...props} {...routerProps} /> */}
    </div>
  );
};

const RenderComponentWithFooter = ({ Comp, props, routerProps }) => {
  return (
    <div className={"main_container"}>
      <NavContainer {...props} {...routerProps} />
      <Comp {...props} {...routerProps} />
      <FooterContainer {...routerProps} />
    </div>
  );
};

const RenderComponentWithoutFooter = ({ Comp, props, routerProps }) => {
  return (
    <div className={"main_container"}>
      <NavContainer {...props} {...routerProps} />
      <Comp {...props} {...routerProps} />
      {/* <FabLinks {...props} {...routerProps} /> */}
    </div>
  );
};
export default class Root extends Component {
  state = {
    socket: null,
    deferredPrompt: null
  };

  componentDidMount() {
    console.log(window.location.href);
 
  }

  
  componentWillMount = () => {
    const socket = socketIOClient(
      `${API_HOSTNAME}?path=${document.location.pathname.split("/")[1]}`,
      { transports: ["websocket", "polling"] }
    );
    this.setState({ socket: socket });
    console.log(API_HOSTNAME);

    if (!localStorage.getItem("user-counted")) {
      getRegion((data) => {
        if (data.status === "success") {
          socket.emit("USER_INFO", data);
          localStorage.setItem("user-counted", 1);
        }
      });
    }
  };

  render() {
    return (
      <Fragment>
        <Router>
          {!this.props.token &&
          !["/", "/login", "/register"].includes(window.location.pathname) ? (
            <>
              <ErrorAlert
                status="info"
                showAlert={true}
                addedStyles={{
                  width: "fit-content",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  border: "none",
                  borderRadius: "25px",
                }}
                message={
                  <Link to="/login" style={{ color: "#fff" }}>
                    {" "}
                    Kindly join Listiee to access all features
                  </Link>
                }
              />
              
            </>
          ) : null}
          <Suspense fallback={<div></div>}>
            <Switch>
              {/* {!this.props.token? console.log("fsldkjf"):<ErrorAlert/>}
              {!(isMobileOnly || isTablet) ? (
                <Route
                  exact
                  path="/"
                  render={(props) => <HomePage {...props} {...this.props} />}
                />
              ) : (
                <Route
                  exact
                  path="/"
                  render={(props) => <HomePageMob {...props} {...this.props} />}
                />
              )} */}
              <Route
                exact
                path="/"
                render={(props) => <HomePage {...props} {...this.props} />}
              />
              <Route
                path="/l/:slug"
                render={(props) => (
                  <ListSpecificPage props={this.props} routerProps={props} />
                )}
              />
              {/* <Route
              path="/create"
              render={(props) => <CreateListPage {...props} />}
            /> */}
              <Route
                exact
                path="/discover"
                render={(props) => (
                  <RenderComponentWithoutFooter
                    Comp={Discover}
                    // Comp={Discover1}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path="/success"
                render={(props) => <SuccessPage {...props} />}
              />
              <Route
                exact
                path={UPDATES_PATH}
                render={(props) => (
                  <RenderComponentWithoutFooter
                    Comp={Updates}
                    // Comp={Discover1}
                    props={this.props}
                    routerProps={props}
                  />
                )}
                // render={(props) => <Updates/>}
              />
              <Route
                exact
                path={DISCOVER_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={Discover}
                    // Comp={Discover1}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={LOGIN_PATH}
                render={
                  process.env.REACT_APP_BETA
                    ? (props) => <BetaReg {...props} {...this.props} />
                    : (props) => (
                        <RenderComponent
                          Comp={Login}
                          props={this.props}
                          routerProps={props}
                        />
                      )
                }
              />
              )
              <Route
                exact
                path={REGISTER_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={Register}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={FORGOT_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={ForgotPassword}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={SHARE_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={Share}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              {!isMobileOnly ? (
                <Route
                  exact
                  path={LIST_PATH}
                  render={(props) => (
                    <RenderComponent
                      Comp={List}
                      props={this.props}
                      routerProps={props}
                    />
                  )}
                />
              ) : (
                <Route
                  exact
                  path={LIST_PATH}
                  render={(props) => (
                    <RenderComponent
                      Comp={ListMobile}
                      props={this.props}
                      routerProps={props}
                    />
                  )}
                />
              )}
              <Route
                exact
                path={ASK_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={Ask}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={REQUEST_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={Request}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={ADMIN_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={AdminContainer}
                    props={{ ...this.props, socket: this.state.socket }}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={EDIT_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={EditList}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={PROFILE_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={Profile}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              {/* <Route
              exact
              path={USER_PROFILE_PATH}
              render={(props) => (
                <RenderComponent
                  Comp={UserProfile}
                  props={this.props}
                  routerProps={props}
                />
              )}
            /> */}
              <Route
                exact
                path={DISCLAIMER_PATH}
                render={(props) => (
                  <RenderComponentWithFooter
                    Comp={Disclaimer}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={TERMS_OF_USE_PATH}
                render={(props) => (
                  <RenderComponentWithFooter
                    Comp={TermsOfUse}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={PRIVACY_POLICY_PATH}
                render={(props) => (
                  <RenderComponentWithFooter
                    Comp={PrivacyPolicy}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={PROFILE_SETTING_PATH}
                render={(props) => (
                  <RenderComponentWithFooter
                    Comp={ProfileSettings}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={SEARCH_RESULTS_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={SearchResults}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={PEOPLE_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={People}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={TAG_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={Tags}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              {/* <Route
              exact
              path={CATEGORIES_PATH}
              render={(props) => (
                <RenderComponent
                  Comp={Categories}
                  props={this.props}
                  routerProps={props}
                />
              )}
            /> */}
              <Route
                exact
                path={REPORT_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={Report}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={FEEDBACK_PATH}
                render={(props) => (
                  <RenderComponentWithFooter
                    Comp={Feedback}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={USERNAME_POLICY}
                render={(props) => (
                  <RenderComponentWithFooter
                    Comp={UserNamePolicy}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={BLOG_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={Blog}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={"/linkedin"}
                render={(props) => (
                  <RenderComponent
                    Comp={LinkedInPopUp}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={RESPOND_INVITE}
                render={(props) => (
                  <RenderComponentWithFooter
                    Comp={Invitation}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path="/team"
                render={(props) => (
                  <RenderComponentWithFooter
                    Comp={Team}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path="/career"
                render={(props) => (
                  <RenderComponentWithFooter
                    Comp={Career}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path="/investors"
                render={(props) => (
                  <RenderComponentWithFooter
                    Comp={Investors}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path="/media"
                render={(props) => (
                  <RenderComponentWithFooter
                    Comp={MediaRelations}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path="/story"
                render={(props) => (
                  <RenderComponentWithFooter
                    Comp={Story}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path="/about"
                render={(props) => (
                  <RenderComponentWithFooter
                    Comp={About}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path="/guideLines"
                render={(props) => (
                  <RenderComponentWithFooter
                    Comp={GuideLines}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              {/* <Route
						exact
						path={"/notes"}
						render={props => (
							<RenderComponent
								Comp={Notes}
								props={this.props}
								routerProps={props}
							/>
						)}
					/> */}
              <Route
                exact
                path={STACK_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={Stack}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={BOARD_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={Board}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={COVID_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={Covid}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={COVID_REQUEST}
                render={(props) => (
                  <RenderComponent
                    Comp={CovidRequest}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              <Route
                exact
                path={COVID_VERIFIER_PATH}
                render={(props) => (
                  <RenderComponent
                    Comp={CovidVerifier}
                    props={this.props}
                    routerProps={props}
                  />
                )}
              />
              {/* Adding BlogPage */}
              <Route exact path="/blogPage">
                <BlogPage />
              </Route>
              <Route exact path="/publishBlog">
                <PublishBlog />
              </Route>
              <Route render={() => <Error404 {...this.props} />} />
            </Switch>
          </Suspense>{" "}
        </Router>
      </Fragment>
    );
  }
}
