import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import PasteModal from "./PasteModal";
import CSVBtnComponent from "./CSVBtnComponent";
import ReactDragListView from "react-drag-listview";
import PasteBtnComponent from "./PasteBtnComponent";
import CollabBtnComponent from "./collabBtnComponent";
import EmbeddedPlayer from "./EmbeddedPlayer";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { handleInputFocusTransfer } from "../../utils/utils";
import Select from "@material-ui/core/Select";
import LinkIcon from "@material-ui/icons/Link";
import {
  is_url,
  getIconFromLink,
  getNameFromLink,
  getValidURL,
  getMetaDatOfURL,
} from "../Utils/helperFunctions";
import AddLinkModal from "./AddLinkModal";
import ScrollableFeed from "react-scrollable-feed";

class ListItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.currentItem = React.createRef();
  }

  /************************************************ */

  handleKeyDown = (e) => {
    e = e || window.event;
    handleInputFocusTransfer(e, "custom-focus-management");
  };

  getYouTubeVideoId = (url) => {
    var regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return "error";
    }
  };

  onHandleKeyPress = (e) => {
    const { items, details } = this.props;
    let { value } = e.target;

    value = value.trim();

    let isURL = false;

    if (e.key === "Enter" && value !== "") {
      e.preventDefault();
      if (is_url(value)) {
        isURL = true;
      }

      // let title=value

      // try {
      //   const res = await getMetaDatOfURL(value, this.props.token)
      //   title = res.title
      // }catch(e){
      //   console.log("Error fetching meta", e)
      // }

      let NewName = isURL ? getNameFromLink(value) : value;

      if (this.props.checkDuplicateInItems(NewName)) {
        alert(NewName + " is already present in list !");
        return;
      }

      const shouldBeApproved = () => {
        if (
          this.props.mode == "share" ||
          (!this.props.totalList &&
            this.props.totalList.listAid === this.props.aid) ||
          ["mender", "amender"].includes(this.props.user_type)
        ) {
          return true;
        } else {
          return false;
        }
      };
      let detail = {
        itemName: NewName,
        description: "",
        approved: shouldBeApproved(),
        highlights: [],
        about: isURL
          ? [
              {
                link: getValidURL(value),
                icon: getIconFromLink(getValidURL(value)),
              },
            ]
          : [],
        sections: [],
        links: "",
        upv: 0,
        aid: this.props.aid,
      };
      // if (this.props.sensitive === true) {
      //   detail["sections"].push({
      //     title: "Source",
      //     items: [],
      //   });
      // }
      this.props.updateItems([
        ...items,
        { name: NewName, aid: this.props.aid },
      ]);
      this.props.updateDetails([...details, detail]);
      e.target.value = "";
    }
  };

  handleFocusNext = (e) => {
    if (e.key === "Enter" && e.target.value.length > 0) {
      e.preventDefault();
      document.querySelector("#last-list-item-input").focus();
    }
  };

  handleCheckTitle = (listName) => {
    if (!listName.toLowerCase().startsWith("list of"))
      listName = "list of " + listName;

    return listName;
  };

  render() {
    const { items, listStyle, isControlDisable } = this.props;

    /****************** */
    const renderItems = () => {
      let idx = 0;
      return items.map((item, index) => {
        console.log(idx);
        if (
          typeof this.props.details[index].approved === "undefined" ||
          this.props.details[index].approved ||
          this.props.details[index].aid === this.props.aid ||
          (this.props.totalList &&
            this.props.totalList.listAid === this.props.aid)
        ) {
          idx++;
          return (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  key={index}
                  className={
                    this.props.showEditDetails &&
                    index === this.props.currentListItemIndex
                      ? "list_items active-list-items"
                      : "list_items"
                  }
                  style={
                    !this.props.showEditDetails ? { boxShadow: "none" } : null
                  }
                  onClick={(e) => makeCurrentDetailPage(index)}
                >
                  <span className="list-index">
                    {listStyle === "radio" ? (
                      <i className="fas fa-circle listitem_bullet" />
                    ) : listStyle === "checkbox" ? (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            size="small"
                            style={{
                              fontSize: "1rem",
                              padding: "0px",
                            }}
                          />
                        }
                      />
                    ) : (
                      idx
                    )}
                  </span>
                  <input
                    className="list-item-text list-item-input custom-focus-management"
                    value={item.name}
                    onChange={(e) => handleListEdit(e, index)}
                    onFocus={(e) => makeCurrentDetailPage(index)}
                    onKeyPress={this.handleFocusNext}
                    onKeyDown={this.handleKeyDown}
                    disabled={isControlDisable(item.aid)}
                  />
                  {[item.aid, this.props.totalList.listAid].includes(
                    this.props.aid
                  ) || this.props.user_type === "mender" ? (
                    <Fragment>
                      {this.props.showEditDetails === true ? (
                        <Fragment>
                          {this.props.currentListItemIndex === index &&
                            (!isControlDisable(item.aid) ? (
                              <span
                                onClick={(e) => deleteItem(index)}
                                className={
                                  !this.props.showEditDetails
                                    ? "list-item-delete-btn"
                                    : "list-item-delete-btn list-item-delete-btn-on-detail-page"
                                }
                                title="Delete this item"
                              >
                                <i className={"fas fa-times"} />
                              </span>
                            ) : null)}
                        </Fragment>
                      ) : (
                        !isControlDisable(item.aid) && (
                          <span
                            onClick={(e) => deleteItem(index)}
                            className="list-item-delete-btn"
                          >
                            <i className={"fas fa-times"} />
                          </span>
                        )
                      )}
                    </Fragment>
                  ) : null}
                </span>
                {/* <LinkIcon onClick={openproofmodal}>Input test1</LinkIcon>
                 */}
                <AddLinkModal
                  isOpen={this.props.isopen}
                  toggle={this.props.toggle}
                  modalLink={this.state.modalLink}
                  handleChange={this.handleModalChange}
                  handleModelOk={this.handleSectionModelOk}
                  sectionTitle={"About"}
                  linkFromPlatform={this.state.linkFromPlatform}
                />
                {this.props.sensitive == true &&
                this.props.showEditDetails === false ? (
                  <LinkIcon
                    className="Proof-Link-Icon"
                    style={{ cursor: "pointer" }}
                    onMouseEnter={() => {
                      this.props.handleAddProofLink(index);
                    }}
                    onClick={() => {
                      this.props.handleAddProofSource();
                    }}
                    onDrop={(event) => this.props.drop_handler(event, index)}
                    onDragOver={(event) => this.props.dragover_handler(event)}
                  />
                ) : null}
              </div>
              {this.props.details[index].about.length !== 0 && (
                <EmbeddedPlayer
                  link={this.props.details[index].about[0].link}
                />
              )}
            </>
          );
        } else {
          return "";
        }
      });
    };

    const handleListEdit = (e, index) => {
      this.props.handleListEdit(e, index);
    };

    const deleteItem = (index) => {
      this.props.handleDeleting(index);
    };

    const makeCurrentDetailPage = (currentListItemIndex) => {
      this.props.changeCurrentListItemIndex(currentListItemIndex);
    };

    /************************** */
    //drag and drop list
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const details = that.props.details;
        const data = that.props.items;
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
        const detailItems = details.splice(fromIndex, 1)[0];
        details.splice(toIndex, 0, detailItems);
        that.setState({ item, detailItems });
      },
      nodeSelector: "span",
      handleSelector: "input",
    };
    //**************************/

    return (
      <div>
        {console.log("rendercheck")}
        <div className="list-items-container">
          <ScrollableFeed>
            <div className={"form-group list-item-container"}>
              {(this.props.mode === "edit" &&
                this.props.aid === this.props.fetchedList.result[0].aid) ||
              this.props.user_type === "amender" ? (
                <ReactDragListView {...dragProps}>
                  {renderItems()}
                </ReactDragListView>
              ) : (
                renderItems()
              )}

              <div className="add-list-item">
                <div id="list-input-icon" ref="listTypeSelector">
                  <Select
                    native
                    variant="outlined"
                    name="listStyle"
                    onChange={this.props.onHandleChange}
                    value={this.props.listStyle}
                  >
                    <option value="numbers" style={{ fontSize: "1em" }}>
                      &nbsp;
                      {this.props.details.filter((item, index) => {
                        if (
                          typeof item.approved === "undefined" ||
                          item.approved === true ||
                          [item.aid, this.props.listAid].includes(
                            this.props.aid
                          )
                        ) {
                          return item;
                        }
                      }).length + 1}
                    </option>
                    <option value="radio" style={{ fontSize: "1em" }}>
                      &#9899;&nbsp;
                    </option>
                    <option value="checkbox" style={{ fontSize: "1em" }}>
                      {" "}
                      &#10004;
                    </option>
                  </Select>
                </div>
                <input
                  ref={this.currentItem}
                  type={"text"}
                  id={"last-list-item-input"}
                  autoComplete="off"
                  className={"custom-focus-management"}
                  placeholder={" Item 1             ↵"}
                  onKeyPress={this.onHandleKeyPress}
                  onKeyDown={this.handleKeyDown}
                  onChange={this.onHandleChange}
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "You left me empty.")}
                  title="Type item name and press Enter"
                />
              </div>
            </div>
          </ScrollableFeed>
        </div>
        {/* {this.props.showEditDetails ? (
          <div className="paste-btn-container text-center">
            <CSVBtnComponent {...this.props} />
            <PasteBtnComponent {...this.props} /> */}
        {/* {this.props.mode === "share" || (this.props.mode === "edit" && this.props.aid === this.props.fetchedList.result[0].aid) ? (
              <CollabBtnComponent {...this.props} />
            ) : null} */}
        {/* </div>
        ) : null} */}
      </div>
    );
  }
}

ListItems.propTypes = {
  items: PropTypes.array,
  details: PropTypes.array,
  listName: PropTypes.string,
  onHandleChange: PropTypes.func,
  currentListItemIndex: PropTypes.number,
  changeCurrentListItemIndex: PropTypes.func,
  updateItems: PropTypes.func,
  updateDetails: PropTypes.func,
  showEditDetails: PropTypes.bool,
  handleDeleting: PropTypes.func,
  handleListEdit: PropTypes.func,
  shouldUpdateList: PropTypes.func,
  handleshowEditDetails: PropTypes.func,
};

export default ListItems;
