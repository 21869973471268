import axios from "axios";
import {
  CREATE_NOTE,
  FETCH_MY_NOTES,
  DELETE_NOTE,
  DELETE_NOTE_LIST,
  EDIT_NOTE,
  EDIT_NOTE_LIST,
  FETCH_ALL_LIST_NOTES,
  CLOSE_NOTE_SIDE_BAR,
  OPEN_NOTE_SIDE_BAR,
  DROP_NOTE_BUTTON
} from "../constants/actionTypes";
import {
  CREATE_NOTE_API,
  FETCH_NOTES_API,
  EDIT_NOTE_API,
  DELETE_NOTE_API
} from "../constants/apiConstants";

const fetchNotes = async config => {
  let notes = [];
  await axios(config)
    .then(res => {
      notes = res.data.data.result;
    })
    .catch(error => {
      console.log("error: ", error);
    });
  return notes;
};

export const createNote = (
  text,
  publicVal,
  listId,
  lsItemId
) => async (dispatch, getState) => {
  const data = {
    aid: getState().auth.aid,
    listId: listId,
    lsItemId: lsItemId,
    public: publicVal,
    text: text
  };
  const reqOptions = {
    method: "POST",
    url: CREATE_NOTE_API,
    data: data,
    headers: {
      "x-access-token": getState().auth.token,
      "Content-Type": "application/json"
    }
  };

  await axios(reqOptions)
    .then(res => {
      if (res.status === 200) {
        const note = {
          _id: res.data.data._id,
          public: res.data.data.public,
          text: res.data.data.text,
          aid: res.data.data.aid,
          user_name: getState().auth.user_name
        };
        dispatch({ type: CREATE_NOTE, payload: note });
      }
    })
    .catch(error => {
      console.log("error: ", error);
      alert("Could not create note, please try again!");
    });
};

export const fetchMyNotes = () => async (dispatch, getState) => {
  //fetch note here
  let myNotes = [];
  const data = {
    aid: getState().auth.aid,
    onlyUser: true
  };
  const reqOptions = {
    method: "POST",
    url: FETCH_NOTES_API,
    data: data,
    headers: {
      "x-access-token": getState().auth.token
    }
  };
  myNotes = await fetchNotes(reqOptions);
  dispatch({ type: FETCH_MY_NOTES, payload: myNotes });
};

export const deleteNote = (noteId, mode) => async (dispatch, getState) => {
  //delete notes here
  const data = {
    aid: getState().auth.aid,
    noteId: noteId
  };
  const reqOptions = {
    method: "POST",
    url: DELETE_NOTE_API,
    data: data,
    headers: {
      "x-access-token": getState().auth.token,
      "Content-Type": "application/json"
    }
  };
  await axios(reqOptions)
    .then(res => {
      if (res.status === 200) {
        console.log("DELETE NOTE SUCCESS", res);
        if(mode === "my"){
          dispatch({ type: DELETE_NOTE, payload: noteId });
        } else {
          dispatch({ type: DELETE_NOTE_LIST, payload: noteId });
        }
      }
    })
    .catch(error => {
      console.log("error: ", error);
      alert("Could not delete the note. please try again !");
    });
};

export const editNote = (noteId, note, mode) => async (dispatch,getState) => {
  //edit note here
  const data = {
    aid: getState().auth.aid,
    noteId: noteId,
    public: note.public,
    text: note.text,
  };
  const reqOptions = {
    method: "POST",
    url: EDIT_NOTE_API,
    data: data,
    headers: {
      "x-access-token": getState().auth.token,
      "Content-Type": "application/json"
    }
  };
  await axios(reqOptions)
    .then(res => {
      if (res.status === 200) {
        console.log("EDIT NOTE SUCCESS", res);
        if(mode === "my"){
          dispatch({ type: EDIT_NOTE, payload: note });
        } else {
          dispatch({ type: EDIT_NOTE_LIST, payload: note });
        }
      }
    })
    .catch(error => {
      console.log("error: ", error);
      alert("Could not Edit the note. please try again !");
    });
};

export const fetchAllListNotes = (listId, lsItemId) => async (dispatch, getState) => {
  // fetch all notes related to a list here...
  // alert("FETCH_ALL_LIST_NOTES");
  let allNotes = [];
  const data = {
    aid: getState().auth.aid,
    listId,
    lsItemId,
    onlyUser: false,
    public: true
  };
  const reqOptions = {
    method: "POST",
    url: FETCH_NOTES_API,
    data: data,
    headers: {
      "x-access-token": getState().auth.token
    }
  };
  allNotes = await fetchNotes(reqOptions);
  dispatch({
    type: FETCH_ALL_LIST_NOTES,
    payload: allNotes
  });
};

export const toggleNoteSideBar = () => (dispatch, getState) => {
  if (getState().noteReducer.noteSideBar) {
    dispatch({
      type: CLOSE_NOTE_SIDE_BAR
    });
  } else {
    dispatch({
      type: OPEN_NOTE_SIDE_BAR
    });
  }
};

export const handleNoteButtonDrop = (data) => (dispatch, getState) => {
    console.log("Dispatching  DROP_NOTE_BUTTON",data);

    dispatch({
      type: DROP_NOTE_BUTTON,
      payload: {...data}
    });

};
