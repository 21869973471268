import React from "react";
import { Link } from "react-router-dom";
import error404 from "../components/assets/err.png";
import "../styles/components/error404.css";

const Error404 = () => {
  return (
    // <div className="error-box">
    //   <div className="image404">
    //     <img src={error404} alt="404" border="0" />
    //     </div>
    //     <div className="contentError">
    //   <div className="oops-text">Oops!</div>
    //   <div><p>Page Not Found</p>
    //   <p>The page you were looking for could not be found. It might
    //       have been removed, renamed, or didn't exist in the place.</p></div>
    //   <div className="buttons-wrapper">
    //     <div><p>Here are some helpful links</p></div>
    //     <Link to="/share" className="helpful-link">Share</Link>
    //     <Link to="/" className="helpful-link">Discover</Link>
    //     <Link to="/ask" className="helpful-link">Ask</Link>
    //   </div>
    //   </div>
    // </div>
    <section className="error-page">
      <div className="image-404">
        <img src={error404} alt="404"></img>
      </div>
      <div className="error-content">
        <div className="oops-text">Oops!</div>
        <div className="pageNfound">Page not Found</div>
        <div className="error-code">Error-code: 404</div>
        <div className="error-detail">
          The page you were looking for could not be found. It might have been
          removed, renamed, or didn't exist in the place.
        </div>
        <div className="help-wrapper">
          <ul className="helpful-link">
            <div className="following-links">Here are some helpful links</div>
            <li className="backlink">
              <Link to="/">Home</Link>
            </li>
            <li className="backlink">
              <Link to="/discover">Discover</Link>
            </li>
            <li className="backlink">
              <Link to="/share">Share</Link>
            </li>
            <li className="backlink">
              <Link to="/ask">Ask</Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Error404;
