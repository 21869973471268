import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ListAuthor from "./ListAuthor";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { LOGIN_PATH, HOME_PATH } from "../../constants/pathConstants";
import Report from "../Report";
import EditLogs from "../EditLogs/EditLogs";

class ListSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      filteredData: [],
      query: "",
      isReportModalOpen: false,
      state: null,
    };
  }
  /*const [modal, setModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [query, setQuery] = useState('');
  const [isReportModalOpen, setReportModal] = useState(false);
  const [state, setState] = useState(null);*/

  toggle = () => {
    if (!this.props.token) {
      this.props.history.push(LOGIN_PATH, { redirect_to: HOME_PATH });
      return;
    }
    if (!this.state.modal && this.props.foldersList.length === 0) {
      this.props.fetchMyFolders();
    }
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleClose = () => {
    this.setState({
      filteredData: [],
    });

    this.setState({
      query: "",
    });
    //setFilteredData([]);
    //setQuery('');
  };

  shortenTitle = (str) => {
    let wordsLen = str.split(" ");
    if (wordsLen.length > 7) {
      let newWords = wordsLen.slice(0, 7).join(" ");
      return newWords + "...";
    } else {
      return str;
    }
  };

  handleSearch = (e) => {
    this.setState({
      query: e.target.value,
    });

    const data = this.props.foldersList.filter((element) => {
      return element.name
        .toLowerCase()
        .includes(this.state.query.toLowerCase());
    });

    this.setState({
      filteredData: data,
    });
  };

  handleClick = (e, folderId, listId) => {
    this.toggle();
    this.props.handleAddListToFolder(e, folderId, listId);
  };

  handleReportClose = () => {
    this.setState({
      isReportModalOpen: false,
    });
    //setReportModal(false);
  };

  handleReport = (e) => {
    e.preventDefault();
    if (!this.props.token) {
      this.props.history.push(LOGIN_PATH, { redirect_to: HOME_PATH });
      return;
    }
    /*props.history.push('/report', {
      lsId: props.currentList._id,
      lsItId:
        props.match.params.itemId !== 'item' ? props.match.params.itemId : '',
      listOwner: props.currentList.aid,
      type: 'list'
    });*/
    this.setState({
      state: {
        lsId: this.props.currentList._id,
        lsItId:
          this.props.match.params.itemId !== "item"
            ? this.props.match.params.itemId
            : "",
        listOwner: this.props.currentList.aid,
        type: "list",
      },
    });
    this.setState({
      isReportModalOpen: true,
    });
    //setReportModal(true);
  };

  render() {
    return (
      <div style={{ color: "gray" }} className="matrics-data">
        <span className="profile metrics-item">
          <ListAuthor
            {...this.props}
            profile={this.props.currentList.profile[0]}
          />
        </span>

        <span className="metrics-item noOfViews-holder">
          {this.props.promoters.length === 0 ||
          this.props.promoters.indexOf(this.props.user_name) === -1 ? (
            <i
              className="fa fa-arrow-circle-up"
              title="Promote"
              style={{ paddingBottom: "0" }}
              onClick={(e) => {
                this.props.handlePromoteList(e, 1);
              }}
            />
          ) : (
            <i
              className="fa fa-arrow-circle-up"
              title="Promote"
              style={{ color: "#F27531", paddingBottom: "0" }}
              onClick={(e) => {
                this.props.handlePromoteList(e, -1);
              }}
            />
          )}
          <span className="noOfViews mt-0">
            {this.props.promotes ? this.props.promotes : "0"}
          </span>
        </span>

        <span className="metrics-item">
          <i
            className="fas fa-folder-plus"
            title="add to folder"
            onClick={this.toggle}
          ></i>
        </span>

        {this.props.isEditable ? (
          <span className="metrics-item hide-On-Small-Screens">
            <i
              className="fas fa-pen"
              title="Edit List"
              onClick={this.props.handleEditlist}
            ></i>
          </span>
        ) : null}

        {this.props.currentList.aid === this.props.aid ||
        this.props.user_type === "amender" ? (
          <span className="metrics-item">
            <i
              title="Delete List"
              // data-toggle="modal"
              // data-target="#deleteModal"
              className={"far fa-trash-alt delete-btn"}
              onClick={this.props.handleDeleteList}
            />
          </span>
        ) : null}
        <span className="metrics-item">
          <i
            className="fas fa-flag"
            title="Report/Flag"
            onClick={(e) => this.handleReport(e)}
          ></i>
        </span>
        <span className="metrics-item noOfViews-holder">
          <i
            className="fa fa-eye"
            title="views"
            style={{ paddingBottom: "0" }}
          ></i>
          <span className="noOfViews">
            {this.props.currentList.views ? this.props.currentList.views : "0"}
          </span>
        </span>
        <span
          className="metrics-item noOfViews-holder"
          data-toggle="modal"
          data-target="#collabModal"
        >
          <i
            className="fa fa-users"
            title="contributors"
            style={{ paddingBottom: "0", cursor: "pointer" }}
          ></i>
          <span className="noOfViews">
            {this.props.currentList.collaborators
              ? this.props.currentList.collaborators.filter(
                  (collab) => collab.status === "accepted"
                ).length
              : 0}
          </span>
        </span>
        <span className="metrics-item">
          <i
            className="fa fa-comment"
            title="List comments"
            style={{ paddingBottom: "0" }}
            onClick={this.props.handleShowListNotes}
          />
        </span>
        <span className="metrics-item">
          <EditLogs />
        </span>
        {this.props.token ? (
          this.props.currentList.lsStatus !== "suspected" &&
          this.props.user_type !== "normal" ? (
            <span className="metrics-item">
              <i
                className="fa fa-exclamation-circle"
                title="Suspect list for review"
                style={{ paddingBottom: "0" }}
                onClick={this.props.handleMakeListSuspected}
              />
            </span>
          ) : this.props.currentList.lsStatus === "suspected" &&
            (this.props.aid === this.props.currentList.aid ||
              this.props.aid ===
                this.props.currentList.suspectedDetails.aaid) ? (
            <span className="metrics-item">
              <i
                className="fa fa-exclamation-circle"
                title="Suspected list updates"
                style={{ paddingBottom: "0" }}
                onClick={this.props.handleShowSuspectDrawer}
              />
            </span>
          ) : null
        ) : null}

        <span className="metrics-item">
          {/* <EditLog list={this.props.currentList}/> */}
        </span>
        {/*Modal*/}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          onClosed={this.handleClose}
          className="list-folder-modal"
        >
          <div className="folder-search">
            <input
              type="search"
              input={this.state.query}
              placeholder="🔍 Search.."
              name=""
              id=""
              onChange={(e) => {
                this.handleSearch(e);
              }}
            />
          </div>
          <ModalBody
            style={{ height: "400px", overflowY: "scroll", paddingTop: " 0px" }}
            className="list-display-modal"
          >
            <ul className="modal-folder-names">
              {this.state.filteredData.length === 0
                ? this.props.foldersList.map((folder, i) => (
                    <li key={i}>
                      <div className="row">
                        <div className="col-sm-10">
                          <div className="modal-folder-title">
                            {this.shortenTitle(folder.name)}
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="modal-folder-icon">
                            <AddRoundedIcon
                              onClick={(e) => {
                                this.handleClick(
                                  e,
                                  folder._id,
                                  this.props.currentList._id
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))
                : this.state.filteredData.map((folder, i) => (
                    <li key={i}>
                      <div className="row">
                        <div className="col-sm-10">
                          <div class="modal-folder-title">
                            {this.shortenTitle(folder.name)}
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="modal-folder-icon">
                            <AddRoundedIcon
                              onClick={(e) => {
                                this.handleClick(
                                  e,
                                  folder._id,
                                  this.props.currentList._id
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={this.toggle}
              style={{ fontSize: " 13px" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {this.state.isReportModalOpen && (
          <Report
            {...this.props}
            state={this.state.state}
            isReportModalOpen={this.state.isReportModalOpen}
            handleReportClose={this.handleReportClose}
          />
        )}
      </div>
    );
  }
}

ListSettings.propTypes = {
  isEditable: PropTypes.bool,
  aid: PropTypes.string,
  currentList: PropTypes.object,
  handleEditlist: PropTypes.func,
  handleShowListNotes: PropTypes.func,
  handlePromoteList: PropTypes.func,
};

export default ListSettings;
