import React, { Component } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { LOGIN_PATH, HOME_PATH } from "../../constants/pathConstants";
import { IconButton } from "@material-ui/core";

class StackSubmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFolderMenuOpen: false,
      isSnackbarOpen: false,
      folderName: null,
    };
  }

  async addToFolder(folderId, listId, folderName) {
    const { aid, token } = this.props;
    console.log(`
        aid : ${aid}
        folderId: ${folderId}
        lsid: ${listId}
        `);
    const data = {
      aid: aid,
      folderId: folderId,
      lsid: listId,
    };

    await this.props.addListToFolder(data, token).then(() => {
      this.setState({
        folderName: folderName,
        isSnackbarOpen: true,
      });
      setTimeout(() => {
        this.setState({
          isSnackbarOpen: false,
        });
      }, 5000);
    });
  }

  // no need to modify these ui stuffs -->
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  componentDidMount() {
    console.log(`AID: ${this.props.aid} . TOKEN: ${this.props.token}`);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Hide Menu if clicked on outside of element
   */

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isFolderMenuOpen: false,
      });
    }
  };
  toggleFolderMenu = () => {
    if (!this.props.token) {
      this.props.history.push(LOGIN_PATH, { redirect_to: HOME_PATH });
      return;
    }
    if (this.props.token && this.props.folders.length === 0) {
      this.props.fetchFolders();
    }
    this.setState({
      isFolderMenuOpen: !this.state.isFolderMenuOpen,
    });
    if (this.props.mode !== "mylist") {
      if (this.state.isFolderMenuOpen == false) {
        this.props.lowerOpacity();
      } else {
        this.props.increaseOpacity();
      }
    }
  };

  // <---End

  renderFolders = () => {
    if (this.props.folders.length > 0) {
      return this.props.folders.map((item) => (
        <p
          className="submenu-item"
          key={item.id}
          onClick={() =>
            this.addToFolder(item._id, this.props.listID, item.name)
          }
        >
          <i className="fas fa-folder"></i> {item.name}
        </p>
      ));
    } else {
      return (
        <p className="submenu-item">
          <i className="fas fa-folder"></i> No Folders !
        </p>
      );
    }
  };

  render() {
    return (
      <div className="submenu" ref={this.setWrapperRef}>
        <Snackbar
          open={this.state.isSnackbarOpen}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert severity="success">
            <p
              style={{
                marginBottom: "0px",
                fontWeight: "600",
                fontSize: "17px",
              }}
            >
              Added list to folder {this.state.folderName}
            </p>
          </Alert>
        </Snackbar>

        <IconButton size="small">
          {" "}
          <i
            className="fas fa-folder-plus"
            onClick={this.toggleFolderMenu}
          />{" "}
        </IconButton>
        <span className="tooltiptext">Add to folder</span>

        <div
          className="submenu-content"
          style={
            this.state.isFolderMenuOpen
              ? { display: "block" }
              : { display: "none" }
          }
        >
          {this.renderFolders()}
        </div>
      </div>
    );
  }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default StackSubmenu;
