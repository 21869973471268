import {HIDE_LOGIN_MODAL, SHOW_LOGIN_MODAL, TOGGLE_LOGIN_MODAL} from "../constants/actionTypes";

const initialState = {
  loginModalVisible: false
};

const base = (state = initialState, action) => {
  switch(action.type) {
    case TOGGLE_LOGIN_MODAL:
      return {
        ...state,
        loginModalVisible: !state.loginModalVisible,
      };
    case SHOW_LOGIN_MODAL:
      return {
        ...state,
        loginModalVisible: true,
      };
    case HIDE_LOGIN_MODAL:
      return {
        ...state,
        loginModalVisible: false,
      };
    default:
      return state;
  }
};

export default base;