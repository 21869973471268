import React, { Component } from "react";

class PrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="footer-doc-container">
        <h1 className="text-center">Privacy Policy</h1>
        <div className="footer-doc-content">
          <b>Effective date:</b> <i>November 13, 2018</i>
          <br />
          <b>
            VIZACK CREATIONS LLP ("us", "we", or "our") operates the
            www.listiee.com website.
          </b>
          <p>
            This page informs you of our policies regarding the collection, use,
            and disclosure of personal data when you use our Service and the
            choices you have associated with that data. Our Privacy Policy for
            VIZACK CREATIONS LLP is managed with the help of Privacy Policies.
          </p>
          <p>
            We use your data to provide and improve the Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, the terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions.
          </p>
          <br />
          <b>Information Collection And Use</b>
          <br />
          <p>
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>
          <b>Types of Data Collected:</b>
          <i>
            <b>Personal Data-</b>
          </i>
          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
          </p>
          <br />
          <i>Email address</i>
          <br />
          <i>First name and last name</i>
          <br />
          <i>Phone number</i>
          <br />
          <i>Cookies and Usage Data</i>
          <br />
          <b>Usage Data-</b>
          <p>
            We may also collect information that your browser sends whenever you
            visit our Service or when you access the Service by or through a
            mobile device ("Usage Data"). This Usage Data may include
            information such as your computer's Internet Protocol address (e.g.
            IP address), browser type, browser version, the pages of our Service
            that you visit, the time and date of your visit, the time spent on
            those pages, unique device identifiers and other diagnostic data.
            When you access the Service with a mobile device, this Usage Data
            may include information such as the type of mobile device you use,
            your mobile device unique ID, the IP address of your mobile device,
            your mobile operating system, the type of mobile Internet browser
            you use, unique device identifiers and other diagnostic data.
          </p>
          <b>Tracking & Cookies Data-</b>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and hold certain information. Cookies are
            files with small amount of data which may include an anonymous
            unique identifier. Cookies are sent to your browser from a website
            and stored on your device. Tracking technologies also used are
            beacons, tags, and scripts to collect and track information and to
            improve and analyze our Service. You can instruct your browser to
            refuse all cookies or to indicate when a cookie is being sent.
            However, if you do not accept cookies, you may not be able to use
            some portions of our Service.
          </p>
          <b>Examples of Cookies we use:</b>
          <br />
          <i>Session Cookies. We use Session Cookies to operate our Service.</i>
          <br />
          <i>
            Preference Cookies. We use Preference Cookies to remember your
            preferences and various settings.
          </i>
          <br />
          <i>
            Security Cookies. We use Security Cookies for security purposes.
          </i>
          <br />
          <b>Use of Data</b>
          <br />
          <b>
            VIZACK CREATIONS LLP uses the collected data for various purposes:
          </b>
          <br />
          <i>To provide and maintain the Service</i>
          <br />
          <i>To notify you about changes to our Service when needed</i>
          <br />
          <i>
            To allow you to participate in interactive features of our Service
            when you choose to do so at your consent.
          </i>
          <br />
          <i>To provide customer care and support.</i>
          <br />
          <i>
            To provide analysis or valuable information so that we can improve
            the Service
          </i>
          <br />
          <i>To monitor the usage of the Service.</i>
          <br />
          <i>To detect, prevent and address technical issues</i>
          <br />
          <b>Transfer Of Data</b>
          <p>
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction.
          </p>
          <p>
            If you are located outside India and choose to provide information
            to us, please note that we transfer the data, including Personal
            Data, to India and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            VIZACK CREATIONS LLP will take all steps reasonably necessary to
            ensure that your data is treated securely and in accordance with
            this Privacy Policy and no transfer of your Personal Data will take
            place to an organization or a country unless there are adequate
            controls in place including the security of your data and other
            personal information.
          </p>
          <b>Disclosure Of Data</b>
          <br />
          <b>Legal Requirements:</b>
          <p>
            VIZACK CREATIONS LLP may disclose your Personal Data in the good
            faith, if such action is necessary to:
          </p>
          <br />
          <i>
            To comply with any legal obligations such as to comply with the
            government and legal entities, in cases of any act of crime or
            terrorism(and things of that nature).
          </i>
          <br />
          <i>
            To protect and defend the rights or property of VIZACK CREATIONS
            LLP.
          </i>
          <br />
          <i>
            To prevent or investigate possible wrongdoing in connection with the
            Services.
          </i>
          <br />
          <i>
            To protect the personal safety of users of the Service or the
            public.
          </i>
          <br />
          <i>To protect against legal liability.</i>
          <br />
          <b>Security Of Data:</b>
          <p>
            The security of your data is important to us, but remember that no
            method of transmission over the Internet, or method of electronic
            storage is 100% secure. We strive to use commercially acceptable
            means to protect your Personal Data.
          </p>
          <b>Service Providers</b>
          <p>
            We may employ third party companies and individuals to facilitate
            our Service ("Service Providers"), to provide the Service on our
            behalf, to perform Service-related services or to assist us in
            analyzing how our Service is used. These third parties have access
            to your Personal Data only to perform these tasks on our behalf and
            are obligated not to disclose or use it for any other purpose.
          </p>
          <b>Analytics:</b>
          <p>
            <i>
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.
            </i>
          </p>
          <b>
            <i>Google Analytics</i>
          </b>
          <br />
          <p>
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualize and personalize the ads of its own advertising
            network. For more information on the privacy practices of Google,
            please visit the Google Privacy & Terms web page:
            https://policies.google.com/privacy?hl=en
          </p>
          <b>
            <i>Piwik or Matomo</i>
          </b>
          <br />
          <p>
            Piwik or Matomo is a web analytics service. You can visit their
            Privacy Policy page here: https://matomo.org/privacy-policy
          </p>
          <b>
            <i>Links To Other Sites</i>
          </b>
          <br />
          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click on a third party link, you will be directed to
            that third party's site. We strongly advise you to review the
            Privacy Policy of every site you visit. We have no control over and
            assume no responsibility for the content, privacy policies or
            practices of any third party sites or services.
          </p>
          <b>Children's Privacy</b>
          <br />
          <p>
            Our Service does not address anyone under the age of 16 ("Children/
            Minor"). We do not knowingly collect personally identifiable
            information from anyone under the age of 18. If you are a parent or
            guardian and you are aware that your Child has provided us with
            Personal Data, please contact us. If we become aware that we have
            collected Personal Data from children without verification of
            parental consent, we would take steps to remove that information
            from our servers.
          </p>
          <b>Changes To This Privacy Policy</b>
          <br />
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            "effective date" at the top of this Privacy Policy. You are advised
            to review this Privacy Policy periodically for any changes. Changes
            to this Privacy Policy are effective when they are posted on this
            page.
          </p>
          <b>Contact Us</b>
          <br />
          <p>
            If you have any questions about this Privacy Policy, please contact
            us:
          </p>
          <p>
            By email: hello @ listiee . com or at
            <br />
            Bhojwani Consultancy, <br />
            New Market, Raigarh - 496001,
            <br />
            Chhattisgarh, India
            <br />
          </p>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
