import React, { useState, useEffect } from "react";
import FlagSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
import "react-flags-select/scss/react-flags-select.scss";

const CountrySelect = (props) => {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const handleSelect = (selected) => {
        setSelectedCountry(selected);
        props.ChangeMyCountry(selected);
    };

    return (
        <div class="country-select-wrapper">
            <FlagSelect
                defaultCountry={props.countryCode}
                searchable={true}
                searchPlaceholder="Search..."
                onSelect={handleSelect}
            />
        </div>
    );
};

export default CountrySelect;
