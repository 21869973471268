import React, { Component } from "react";
import {
  EDIT_PATH,
  getEditPath,
  HOME_PATH,
  SHARE_PATH,
} from "../constants/pathConstants";
import { FETCH_LIST_BY_ID_API } from "../constants/apiConstants";
import axios from "axios";
import ListLoader from "./ListLoader";
import ListEditor from "./Nested//ListEditor";

export default class EditList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalList: {},
      isLoading: true,
      fetched: false,
    };
    // this.loadingoff();
    this.totalList = {};
  }

  componentWillMount() {
    if (!this.props.token)
      this.props.history.push(HOME_PATH, {
        login_required: true,
        redirect_to: getEditPath(
          this.props.match.params.name,
          this.props.match.params.id
        ),
      });
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.token) {
      // console.log(this.props);
      var paramsId = this.props.match.params.id;
      var paramsName = this.props.match.params.name;
      this.setState({ fetched: false });

      await this.props
        .fetchListById(
          {
            nameSlug: paramsName,
            lsid: paramsId,
            aid: this.props.aid,
            email: this.props.email,
          },
          this.props.token
        )
        .then((d) => {
          this.setState({ fetched: true });
        });

      if (this.props.fetchedList.result) {
        this.setState((prevState) => {
          const data = this.props.fetchedList.result[0];
          const totalList = this.createTotalList(data);
          console.log("totalList DATA: ", totalList);
          return { totalList, isLoading: false };
        });
      } else {
        this.props.history.push(HOME_PATH);
      }
    }
  }

  createTotalList = (data) => {
    console.log("FETCHED DATA: ", data);
    return {
      listAid: data.aid,
      listName: data.name,
      description: data.description,
      category: data.category,
      primary: data.primary,
      collaborators: data.collaborators,
      tags: data.tags.map((item) => item.tagName),
      isPublic: data.public,
      isConcious: data.conscious,
      isClosed: data.close,
      isMedia: data.media,
      draft: data.draft,
      views: data.views,
      lsStatus: data.lsStatus,
      listStyle: data.listStyle,
      sensitive: data.sensitive,
      listItems: data.item.map((list) => {
        console.log(list);
        return {
          _id:list._id,
          itemName: list.list_name,
          approved:
            typeof list.approved === "undefined" || list.approved
              ? true
              : false,
          description: list.description,
          highlights: list.highlights,
          about: list.about,
          sections: list.sections,
          links: list.links,
          upv: list.upv,
          aid: list.aid,
        };
      }),
    };
  };

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <div
          style={{
            width: "1200px",
            overflow: "hidden",
            margin: "auto",
            padding: "30px",
            paddingTop: "64px",
            marginTop: "30px",
          }}
        >
          <ListLoader />
        </div>
      );
    }

    return (
      <div className={"share_container"}>
        <div>
          <ListEditor
            totalList={this.state.totalList}
            mode={"edit"}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}
