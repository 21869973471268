import React, { Fragment, useState } from "react";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import CloseIcon from "@material-ui/icons/Close";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";

const ShareSettings = (props) => {
  const [Open, setOpen] = useState(false);
  const disabled = props.isControlDisable(props.listAid);
  const descriptionOf = {
    Public: [
      "List will be available to everyone",
      props.publicBtnVal,
      "publicBtnVal",
    ],
    Conscious: [
      "Elements of list will be sequentially frozen",
      props.conciousBtnVal,
      "conciousBtnVal",
    ],
    Close: [
      "Allow others to add items and information",
      props.closedBtnVal,
      "closedBtnVal",
    ],
    "Sensitive Content": [
      "Every item requires additional links",
      props.sensitive,
      "sensitive",
    ],
    "Covid-19": ["List is related to COVID-19", props.covid, "covid"],
    "Expires In": ["List won't be available after a period"],
  };
  const toggleVisibility = () => {
    setOpen(!Open);
  };
  const settings = () => {
    return [
      "Public",
      "Conscious",
      "Close",
      "Sensitive Content",
      "Covid-19",
    ].map((setting) => {
      return (
        <div className="setting-Option">
          <label>
            <input
              type="checkbox"
              disabled={disabled}
              onChange={() => {
                props.handleSwitchChange(
                  !descriptionOf[setting][1],
                  descriptionOf[setting][2]
                );
              }}
              checked={descriptionOf[setting][1]}
            />
            {setting}
          </label>
          <Tooltip placement="right" arrow title={descriptionOf[setting][0]}>
            <InfoOutlinedIcon style={{ fontSize: "15px" }} />
          </Tooltip>
        </div>
      );
    });
  };
  return (
    <Fragment>
      <IconButton onClick={toggleVisibility}>
        <SettingsIcon />
      </IconButton>
      <Modal
        open={Open}
        // open={true}
        onClose={toggleVisibility}
      >
        <div className="settings-modal">
          <div className="modal-header">
            <span>List Settings</span>
            <IconButton onClick={toggleVisibility}>
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            {settings()}
            <div className="setting-Option">
              <Tooltip
              placement="right"
              arrow
              title={'Feature Coming Soon'}
              >
                <label style={{color:"gray"}}> 
                  <input
                    type="checkbox"
                    disabled
                  />
                  Expires In
                </label>
              </Tooltip>
              <Tooltip
                placement="right"
                arrow
                title={descriptionOf["Expires In"][0]}
              >
                <InfoOutlinedIcon style={{ fontSize: "15px" }} />
              </Tooltip>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ShareSettings;
