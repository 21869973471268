import { Button } from "@material-ui/core";
import React, { useState } from "react";
import BlogNav from "./BlogNav";
import "./styles/publishBlog.css";
// import "draft-js/dist/Draft.css";
import Tooltip from "@material-ui/core/Tooltip";

function PublishBlog() {
  return (
    <div className="blog_publish">
      <BlogNav />
      <div className="publish">
        <h4 className="publish_heading">Welcome to Listee Blog</h4>
        <form className="publish_form">
          <Tooltip title="Give a suitable title" aria-label="PublishBlog">
            <input
              className="publish_title"
              type="text"
              placeholder="Enter a title"
            />
          </Tooltip>
          <input
            className="publish_image"
            type="file"
            placeholder="Choose a file"
          />
          <Tooltip title="Write your blog content" aria-label="PublishBlog">
            <textarea
              className="publish_content"
              type="text"
              placeholder="Enter your Content"
              rows="6"
            />
          </Tooltip>
          <Tooltip
            title="Select your blog category"
            aria-label="PublishBlog"
          >
            <select className="category_select" placeholder="Choose a category">
              <option>Travel</option>
              <option>Technology</option>
              <option>Personal</option>
              <option>Development</option>
            </select>
          </Tooltip>
          <Tooltip
            title="Click here to publish your blog"
            aria-label="PublishBlog"
          >
            <Button variant="contained" color="primary" className="publish_btn">
              Publish
            </Button>
          </Tooltip>
        </form>
      </div>
    </div>
  );
}

export default PublishBlog;
