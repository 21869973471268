import { getApiUrl } from "./baseConstants";

export const SAVE_LIST_API = getApiUrl("lists/cr_list_v2");
export const SAVE_LIST_VERSION_THREE = getApiUrl("lists/cr_list_v4");
export const SAVE_SINGLE_LIST_ITEM_API = getApiUrl("lists/ed_list_item"); //it's actually edit list item endpoint.
export const SAVE_LIST_ITEM_API = getApiUrl("lists/cr_list_item_mult");
export const FETCH_LIST_API = getApiUrl("lists/fetch_list_v2");
export const FETCH_LIST_V3_API = getApiUrl("lists/fetch_list_v3"); //change it to fetch_list_v4
export const FETCH_LIST_V4_API = getApiUrl("lists/fetch_list_v6");
export const FETCH_DRAFT_LIST_V4_API = getApiUrl("lists/fetch_drafts");
export const FETCH_LIST_BY_ID_API = getApiUrl("lists/fetch_list_by_id_v4");
export const FETCH_LIST_BY_ID_PUB_API = getApiUrl("lists/fetch_list_by_id_pub");
export const GET_RECOMMENDATION = getApiUrl("lists/get_recommendation");
export const SEARCH_LIST_API = getApiUrl("lists/search");
export const GET_USERS_LIST_API = getApiUrl(
  "collaborators/get_users_collab?query="
);
export const CREATE_ANNOUNCEMENT = getApiUrl(
  "notifications/create_announcement"
);
export const GET_ANNOUNCEMENT_API = getApiUrl("notifications/get_announcement");
export const ADD_LIST_COLLAB_API = getApiUrl("lists/add_collab_v3");
export const RESPOND_INVITE_API = getApiUrl("lists/invitation"); // ?lsid=5e2c89b070ed095fcbaba728&email=nktosni@gmail.com&status=accepted
export const DELETE_LIST_COLLAB_API = getApiUrl("lists/remove_collab");
export const ASK_QUESTION_API = getApiUrl("ask/ask_ques");
export const FETCH_REQUESTS_API = getApiUrl("ask/fetch_requests_v2");
export const FETCH_REQUEST_REPLIES_API = getApiUrl("ask/fetch_ques_v2");
export const REPLY_TO_ASK_API = getApiUrl("ask/reply");
export const DELETE_LIST_API = getApiUrl("lists/del_list");
export const DELETE_LIST_ITEM_API = getApiUrl("lists/del_list_item");
export const UPVOTE_LIST_ITEM_API = getApiUrl("listItems/upvote");
export const BOOKMARK_LIST_ITEM_API = getApiUrl("listItems/bookmark_a");
// export const FETCH_BOOKMARKS_API = getApiUrl('listItems/bookmark_r');
export const FETCH_USERFOLLOWING_API = getApiUrl("listItems/read_follow");
export const FETCH_BOOKMARKS_API = getApiUrl("listItems/bookmark_r_v2");
export const FOLLOWING_API = getApiUrl("listItems/follow");
export const UNFOLLOWING_API = getApiUrl("listItems/unfollow");
export const INCREASE_VIEW_API = getApiUrl("lists/views");
export const EDIT_PROFILE_API = getApiUrl("settings/upd_pro");
export const CHANGE_USERNAME_API = getApiUrl("settings/chng_usrname");
export const FETCH_USER_API = getApiUrl("settings/init_user");
// export const CHANGE_PASSWORD_API = getApiUrl('settings/chng_pass');
export const CHANGE_PASSWORD_API = getApiUrl("settings/chng_pass_v2");
export const CREATE_PASSWORD_API = getApiUrl("settings/create_pass");
export const CHANGE_EMAIL_API = getApiUrl("settings/change_email");
export const SEND_VERIFY_EMAIL_API = getApiUrl("settings/send_verify_email");
export const VERIFY_EMAIL_API = getApiUrl("settings/verify_email_otp");
export const FETCH_PEOPLE_API = getApiUrl("lists/people");
export const FETCH_TAG_API = getApiUrl("lists/tags");
export const FETCH_BOOKMARKS_LIST_API = getApiUrl("listItems/bookmark_rd_item");
export const DELETE_BOOKMARK_LIST_ITEM_API = getApiUrl(
  "listItems/bookmark_del"
);
export const DELETE_UPVOTE_LIST_ITEM_API = getApiUrl("listItems/upvote_d");
// export const FETCH_BOARDS_API = getApiUrl("profileFolders/fetch_board");
export const FETCH_BOARDS_API = getApiUrl("profileFolders/fetch_board_v2");
export const DELETE_FOLDER_API = getApiUrl("profileFolders/del_fldr");
export const DELETE_BOARD_API = getApiUrl("profileFolders/del_brd");
export const CHANGE_BOARD_VISIBILITY_API = getApiUrl(
  "profileFolders/brd_private"
);
// export const ADD_FOLDER_API = getApiUrl("profileFolders/cr_fldr");
export const ADD_FOLDER_API = getApiUrl("profileFolders/cr_fldr_v2");
export const RENAME_FOLDER_API = getApiUrl("profileFolders/rename_fldr");
export const PRIVATE_FOLDER_API = getApiUrl("profileFolders/fldr_private");
export const EDIT_FOLDER_API = getApiUrl("profileFolders/edit_fldr");
export const ADD_BOARD_API = getApiUrl("profileFolders/cr_brd");
export const ADD_LISTS_TO_FOLDERS_API = getApiUrl("profileFolders/cr_fldr_dtl");
export const FETCH_FOLDER_ITEMS_API = getApiUrl(
  "profileFolders/fetch_folderitems"
);
export const UPDATE_FOLDER_API = getApiUrl("profileFolders/update_fldr");
// export const FETCH_FODLER_ITEM_DETAILS_API = getApiUrl("lists/fetch_lsitid")
export const FETCH_FODLER_ITEM_DETAILS_API = getApiUrl("lists/fetch_lsitid_v2");
export const UPLOAD_BOOKMARK_API = getApiUrl("browserBookmarks/b_b_upload_v2");
export const FETCH_RELATED_LIST_API = getApiUrl("ask/related_lists");
// export const EDIT_LIST_API = getApiUrl("lists/edit_list_v2")
export const EDIT_LIST_API = getApiUrl("lists/edit_list_v4");
export const GET_PUBLIC_PROFILE_API = getApiUrl("settings/get_public_profile");
export const GET_NOTIFICATIONS_API = getApiUrl(
  "notifications/get_notifications"
);
export const MARK_READ_NOTIF_API = getApiUrl("notifications/mark_read");
export const MARK_ALLREAD_NOTIF_API = getApiUrl("notifications/mark_all_read");
export const REPORT_LIST_API = getApiUrl("reportList/report_v3");
export const PROMOTE_LIST_API = getApiUrl("promote/promote_list");
export const BOOKMARK_USER_API = getApiUrl("userBookmarks/add_bm");
export const UNBOOKMARK_USER_API = getApiUrl("userBookmarks/del_bm");
export const FETCH_USERS_BOOKMARKED_API = getApiUrl("userBookmark/fetch_bm");
export const ADD_LIST_ITEM_API = getApiUrl("lists/cr_list_item_v2");
export const FETCH_STACK_API = getApiUrl("profileFolders/fetch_folder");
export const RENAME_BOARD_API = getApiUrl("profileFolders/rename_board");
export const PIN_STACK_ITEM_API = getApiUrl("profileFolders/pin_stack_list");
export const ITEM_APPROVAL_API = getApiUrl("lists/approve_item");
export const FOLLOW_UNFOLLOW_BOOKMARK_API = getApiUrl(
  "listItems/bookmark_follow"
);
export const EDIT_HISTORY_API = getApiUrl("lists/edit_history");
export const DELETE_STACK_ITEM_API = getApiUrl(
  "profileFolders/delete_stack_list"
);
export const DELETE_ASKED_API = getApiUrl("ask/del_ask");
export const CHECKBOX_LIST_ITEM_API = getApiUrl("listItems/checkbox_item");

//Notes
export const CREATE_NOTE_API = getApiUrl("notes/cr_note");
export const FETCH_NOTES_API = getApiUrl("notes/fetch_notes");
export const EDIT_NOTE_API = getApiUrl("notes/edit_note");
export const DELETE_NOTE_API = getApiUrl("notes/delete_note");

export const FETCH_METADATA_API = getApiUrl("metaData/get");

// prospectives
export const CREATE_PROSPECTIVE_API = getApiUrl("prospective/cr_prospective");
export const CREATE_PROSPECTIVE_ITEM_API = getApiUrl(
  "prospective/cr_prospective_item"
);
export const EDIT_PROSPECTIVE_API = getApiUrl(
  "prospective/ed_prospective_item"
);
export const FETCH_PROSPECTIVE_API = getApiUrl("prospective/fetch_prospective");
export const DELETE_PROSPECTIVE_API = getApiUrl("prospective/del_prospective");

export const FETCH_USER_LISTS_API = getApiUrl("user/user_lists");
export const FETCH_USER_BOOKMARKS_API = getApiUrl("user/user_bookmarks");
export const FETCH_ALL_USER_BOOKMARKS_API = getApiUrl(
  "user/all_user_bookmarked_lists"
);

export const FETCH_USER_COLLAB_LISTS_API = getApiUrl("user/lists_as_collab");
export const FETCH_INVITATIONS_LEFT = getApiUrl("user/get_invitations_left");
export const SEND_INVITATION = getApiUrl("user/invite_new_user");

// covid
export const ADD_COVID_AREAS_API = getApiUrl("covid/cr_covid_item");
export const IS_COVID_VERIFIER_API = getApiUrl("covid/check_covid_admin");
export const GET_PENDING_COVID_AREAS_API = getApiUrl("covid/get_pending_areas");
export const REVIEW_PENDING_COVID_AREAS_API = getApiUrl(
  "covid/approve_listitem"
);
export const FETCH_COVID_PRIMARIES_API = getApiUrl("covid/fetch_details");
export const EDIT_COVID_LIST_API = getApiUrl("covid/edit_covid_item");

// URL SHORTENER API
export const GENERATE_SHORT_URL_CODE_API = window.location.href.includes(
  "listiee.com"
)
  ? "https://list.fit/generate"
  : "http://localhost:8000/generate";
export const GET_SHORT_URL_CODE_API = window.location.href.includes(
  "listiee.com"
)
  ? "https://list.fit/"
  : "http://localhost:8000/";

// suspect list
export const MAKE_LIST_SUSPECTED_API = getApiUrl("lists/make_list_suspect");
export const RESPOND_SUSPECT_LIST_API = getApiUrl(
  "lists/respond_suspected_list"
);
export const UPDATE_SUSPECT_STATUS_API = getApiUrl(
  "lists/status_suspected_list"
);

export const BETA_REG_URL = getApiUrl("accounts/beta_reg");
export const VERIFY_EMAIL_URL = getApiUrl("accounts/verify_email_beta");
export const SEARCH_URL = getApiUrl("lists/beta_search");
export const ADMIN_LOGIN_URL = getApiUrl("accounts/login");
export const GET_LIST = getApiUrl("admin/rd_beta_pro_act");
export const GET_UNVERIFIED_ACCOUNT = getApiUrl("admin/rd_beta_pro_unver");

// Comment
export const FETCH_COMMENT_API = getApiUrl("comments/fetch_comments");
export const CREATE_COMMENT_API = getApiUrl("comments/cr_comment");
export const EDIT_COMMENT_API = getApiUrl("comments/edit_comment");
export const DELETE_COMMENT_API = getApiUrl("comments/delete_comment");

//news_letter
export const NEWS_LETTER_API = getApiUrl("accounts/news_letter");
