import React, { Component } from "react";
import PropTypes from "prop-types";

import Avatar from "@material-ui/core/Avatar";
import Popper from "@material-ui/core/Popper";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";

import ClickOutside from "react-click-outside";

import { getProfilePath } from "../../constants/pathConstants";

import { Link, NavLink } from "react-router-dom";
import AnchorEmoji from "../assets/anchor.png";
import Tooltip from "@material-ui/core/Tooltip";
import "../../styles/components/ListAuthor.css";

export default class ListAuthor extends Component {
  state = {
    anchorEl: null,
    open: false,
    bmUserTags: [],
    bmUserNotes: null,
  };

  handleClick = (event) => {
    const { currentTarget } = event;
    this.setState((state) => ({
      anchorEl: currentTarget,
      open: !state.open,
    }));
  };

  hideMenu = () => {
    this.setState({
      open: false,
    });
  };

  onFollowUser = async (e) => {
    const { token, aid, fetchedList } = this.props;
    const { id } = this.props.match.params;
    const data = {
      aid,
      followId: this.props.profile.aid,
    };
    const res = await this.props.followUser(data, token);
    const data1 = { aid };
    await this.props.fetchFollowingUserList(data1, this.props.token);
  };

  onUnFollowUser = async (e) => {
    const { token, aid, fetchedList } = this.props;
    const { id } = this.props.match.params;
    const data = {
      aid,
      followId: this.props.aid,
    };
    const res = await this.props.unFollowUser(data, token);
    const data1 = { aid };
    await this.props.fetchFollowingUserList(data1, this.props.token);
  };

  onBookmarkUser = async (e) => {
    let data = {
      aid: this.props.aid,
      bm_aid: this.props.fetchedList.result[0].aid,
      user_name: this.props.fetchedList.result[0].profile[0].user_name,
    };
    if (this.state.tags.length !== 0) {
      data.tags = this.state.tags;
    }
    if (this.state.notes) {
      data.notes = this.state.notes;
    }

    await this.props.bookmarkUser(data, this.props.token);
    this.props.fetchUsersBookmarked({ aid: this.props.aid }, this.props.token);
  };

  onUnBookmarkUser = async (e) => {
    let data = {
      aid: this.props.aid,
      bm_aid: this.props.fetchedList.result[0].aid,
    };

    await this.props.unBookmarkUser(data, this.props.token);
    this.props.fetchUsersBookmarked({ aid: this.props.aid }, this.props.token);
  };

  onChangeTagsAndNotes = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    let { following, followingList, fetchedList } = this.props;
    let user_name, avatar, bio, name, company;
    if (!this.props.profile) {
      user_name = "deleted";
      avatar = bio = name = company = null;
    } else {
      ({ user_name, avatar, bio, name, company } = this.props.profile);
    }

    const { anchorEl, open } = this.state;
    const id = open ? "simple-popper" : null;
    const currentList = fetchedList.result[0];
    if (followingList && followingList.length !== 0) {
      if (followingList[0].following)
        following = followingList[0].following.includes(currentList.aid);
    }

    const styles = {
      paper: { padding: "1em" },
    };

    return (
      <div >
        <Avatar
          alt="Author"
          style={{ width: "1.4em", height: "auto", marginLeft:"0.4rem" }}
          src={avatar}
          title={"Author: " + user_name}
          aria-describedby={id}
          variant="contained"
          onClick={this.handleClick}
        />
        <Tooltip title="Anchor" aria-label="ListAuthor">
          <NavLink to="/guideLines#user-roles" target="_blank">
            <img
              className="anchor_emoji"
              src={AnchorEmoji}
              alt="Anchor Emoji"
            />
          </NavLink>
        </Tooltip>
        <Popper
          className="profile-popper"
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement={"right"}
          transition
          style={{ zIndex: "1000", width: "340px" }}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={this.hideMenu}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper style={styles.paper}>
                  <div className="profile-subMenu">
                    <div className="img-wrapper">
                      <img src={avatar} alt="" />
                      {this.props.profile &&
                      this.props.aid !==
                        this.props.fetchedList.result[0].aid ? (
                        <>
                          {following ? (
                            <div className="follow-btn-span">
                              <button
                                title="Unfollow"
                                className={"unfollow_button"}
                                onClick={(e) => this.onUnFollowUser(e)}
                              >
                                Following
                              </button>
                            </div>
                          ) : (
                            <div className="unFollow-btn-span">
                              <button
                                title="Follow"
                                className={"follow_button"}
                                onClick={(e) => this.onFollowUser(e)}
                              >
                                Follow
                              </button>
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>

                    <Link
                      to={
                        user_name === "deleted"
                          ? "#"
                          : getProfilePath(user_name)
                      }
                    >
                      <ul className="author-info-wrapper">
                        {name && <li className="full_name">{name}</li>}
                        <li>
                          {" "}
                          <span className="username">@{user_name}</span>
                        </li>
                        {bio && <li>{bio}</li>}
                        {company ? (
                          <li>
                            <BusinessCenterOutlinedIcon
                              style={{ fontSize: " 18px", marginRight: "5px" }}
                            />{" "}
                            {company}
                          </li>
                        ) : null}
                      </ul>
                    </Link>
                  </div>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      </div>
    );
  }
}

ListAuthor.propTypes = {
  profile: PropTypes.object.isRequired,
};
