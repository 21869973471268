import React, { Component } from 'react';
import ReactQuill from 'react-quill';

export class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: this.props.comments,
      replys: this.props.commentsReplys,
      value: '',
      text: true
    };
  }

  renderCommentReplys(comment) {
    return (
      <div className='commen-reply'>
        {this.state.replys[comment._id]
          ? this.state.replys[comment._id].map((reply) => <li>{reply.text}</li>)
          : null}
      </div>
    );
  }

  createComment(comment) {
    return (
      <>
        <div className='comment' key={comment._id}>
          <a
            href={`/profile/${comment.profile[0].user_name}`}
            className='comment-author'
            target='_blank'
          >
            @{comment.profile[0].user_name}
          </a>
          <div className='comment-text'>{comment.text}</div>
        </div>
        
      </>
    );
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props
      .createComment(this.props.match.params.id, this.state.value)
      .then(() => {
        this.setState({ value: '', comments: this.props.comments });
      });
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };
   

  render() {
    return (
      <div className='container comments-parent' style={{ width: '320px' }}>
        {this.props.comments.map((comment) => this.createComment(comment))}
        <form onSubmit={(e) => this.handleSubmit(e)} className='comment-form'>
          <input
            className="comment-form-input"
            type='text'
            name='comment'
            placeholder='Comment'
            value={this.state.value}
            onChange={this.handleChange}
          />
          <button className="comment-form-btn" type='submit' value='Submit'>
              <i class="fas fa-arrow-circle-right"></i>
          </button>
        </form>
      </div>
    );
  }
}

export default Comments;
