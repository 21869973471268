import { combineReducers } from 'redux';
import auth from './auth';
import api from './api';
import error from './error';
import base from './base';
import noteReducer from './noteReducer';
import prospective from './prospective';
import commentReducer from './commentReducer';

const rootReducer = combineReducers({
  auth,
  api,
  error,
  base,
  noteReducer,
  prospective,
  commentReducer,
});

export default rootReducer;
