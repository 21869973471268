import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import React, { useState } from "react";


/* Modal to collect feedback
 * takes in 1 prop:
 * feedBackModalOpen (type: boolean)*/

function FeedBackModal(props) {

  let [feedBackModalOpen, setFeedbackModalOpen] = useState(props.feedBackModalOpen);

  let toggle = () => {
    setFeedbackModalOpen(!feedBackModalOpen);
  }


  return   (      <Modal isOpen={feedBackModalOpen} toggle={toggle}>
                  <ModalHeader className="h5">
                    Thank you for your participation and join us early!
                  </ModalHeader>
                  <ModalBody>
                    <p>
                      Please fill up this form to help us understand how we are doing
                      with the platform.
                    </p>
                  </ModalBody>
                  <ModalFooter>
                    <a
                      href="https://docs.google.com/forms/d/14jy2u46erBWcTO7oM7OqQo4JYVjfv4cKKOnu2F4zs8c/viewform?edit_requested=true"
                      className="btn btn-primary"
                      target="_blank"
                      // not showing the modal if the user has already given feedback
                      onClick={(e) => {props.handleFeedBackClick(e)}}
                    >
                      Send Feedback
                    </a>{" "}
                  </ModalFooter>
                </Modal>
         )
}

export default FeedBackModal;
