import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  isCovidVerifier,
  getPendingCovidAreas,
  reviewArea,
  fetchDetails,
} from "../actions/apiActions";
import axios from "axios";

import Popup from "reactjs-popup";

import FlagSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
import "react-flags-select/scss/react-flags-select.scss";
import { countryList } from "./Map/CountryList";
import { confirmDeletion } from "./Utils/helperFunctions";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import ReactMapGL, { Marker } from "react-map-gl";
import Pin from "./Map/Pin";
import Modal from '@material-ui/core/Modal';

import {EDIT_COVID_LIST_API} from '../constants/apiConstants'

export default function CovidVerifier(props) {
  const [areas, setAreas] = useState([]);
  const [country, setCountry] = useState("India");
  const [countryCode, setCountryCode] = useState("IN");
  const [viewport, setViewPort] = useState({
    latitude: 28.6139,
    longitude: 77.209,
    zoom: 5,
    bearing: 0,
    pitch: 0,
  });
  const [showHotSpotIcon, setShowHotSpotIcon] = useState(false);
  const [countries, setCountries] = useState([]);
  const [reviews, setReviews] = useState(0);
  const [fields, setFields] = useState(3);
  const [isReviewed, setIsReviewed] = useState(false);
  const [coords, setCoords] = useState({
    latitude: 28.6139,
    longitude: 77.209,
  });

  // For edit
  const [open, setOpen] = React.useState(false);
  const [editIndex, setEditIndex]= React.useState(null)
  const [listName, setListName]=React.useState("")
  const [lat, setLat]=React.useState()
  const [long, setLong]=React.useState()
  const [editId, setEditId]= React.useState()

  useEffect(()=>{
    let currentArea={...areas[editIndex]}
    let {list_name,_id,coordinates}= currentArea
    let latitude = coordinates && coordinates[0]["latitude"]
    let longitude = coordinates && coordinates[0]["longitude"]
    setListName(list_name)
    setLat(latitude)
    setLong(longitude)
    setEditId(_id)
  },[editIndex])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const makeEditModalBody=()=>{
    return<>
      <h5 className="title"><input type="text" value={listName} onChange={e=>setListName(e.target.value.trim())}/></h5>
      {/* <p> Edit index-{editIndex}</p> */}
      <h5>Coordinates</h5>
      <small>Latitude: </small>
      <input type="text" value={lat} onChange={e=>setLat(e.target.value.trim())}/>
      <br/>
      <small>Longitutde: </small>
      <input type="text" value={long} onChange={e=>setLong(e.target.value.trim())}/>

      <div className="footer">
          <button className="btn btn-success" onClick={e=>saveEdit(editId,listName,lat,long)}>Save</button>
          <button className="btn btn-danger" onClick={handleClose}>Cancel edit</button>
      </div>
    </>
}

  const saveEdit=(_id,list_name,latitude,longitude)=>{
    fetch(EDIT_COVID_LIST_API,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token':props.token
          },
        body: JSON.stringify({
            aid: props.aid,
            lsitid: _id,
            list_name,
            latitude,
            longitude,
        })
    })
    .then(res=>res.json())
    .then(res=>{
        console.log(res)
    })
    .catch(e=>{
        console.log("Could not edit item",e);

    })
  }
  // end for edit
  let mapRef = React.createRef();

  const getCountryName = async (lat, lng) => {
    const url = `https://open.mapquestapi.com/nominatim/v1/reverse.php?key=CJv78tAkodj9Qu6eWykXZDMGeYJ3lWZi&format=json&lat=${lat}&lon=${lng}`;
    const {
      data: { address },
    } = await axios.get(url);
    return address;
  };

  const getCountry = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setViewPort({
            latitude: lat,
            longitude: lng,
            zoom: 10,
            bearing: 0,
            pitch: 0,
          });
          getCountryName(lat, lng).then(async ({ country, country_code }) => {
            setCountryCode(country_code.toUpperCase());
            setCountry(country);
            handleGetDetails(country);
          });
        },
        async () => {
          console.log("cant access your location");
          await handleGetDetails("India");
        }
      );
    } else {
      alert("Sorry, your browser does not support geolocation services.");
      handleGetDetails(country);
    }
  };

  useEffect(() => {
    (async () => {
      let { aid, email, token } = props;
      if (!token) {
        props.history.push("/", {
          login_required: true,
          redirect_to: props.location.pathname,
        });
      }
      let isVerifier = await isCovidVerifier({ aid, email }, token);
      if (!isVerifier.success) {
        props.history.push("/notfound");
        return;
      } else {
        getCountry();
      }
    })();
  }, []);

  const handleReviewUpdate = async (e, lsitid, status) => {
    e.preventDefault();
    if (!isReviewed && status !== "passed") {
      return;
    }
    let isConfirmed = await confirmDeletion();
    if (!isConfirmed) {
      return;
    }
    if (status === "approved" || status === "rejected") {
      let { aid, email, token } = props;
      let data = {
        aid,
        email,
        lsitid,
        status,
      };
      await reviewArea(data, token);
    }
    await handleSetAreas(country);

    let newAreas = areas.filter((area) => {
      return area._id !== lsitid;
    });
    setAreas(newAreas);
    setIsReviewed(false);
    setReviews(0);
    setFields(3);

    let cbs = document.getElementsByTagName("input");
    for (let i = 0; i < cbs.length; i++) {
      cbs[i].checked = false;
    }
  };

  const _updateViewport = (viewport) => {
    setViewPort(viewport);
  };

  const fetch_coordinates = async (place) => {
    const url = `https://www.mapquestapi.com/geocoding/v1/address?key=CJv78tAkodj9Qu6eWykXZDMGeYJ3lWZi&location=${place}&thumbMaps=false`;
    const {
      data: { results },
    } = await axios.get(url);
    const coordinates = results[0].locations[0].latLng;
    setViewPort({
      latitude: coordinates.lat,
      longitude: coordinates.lng,
      zoom: 10,
      bearing: 0,
      pitch: 0,
      transitionDuration: 1000,
    });
  };

  const handleCountrySelect = async (selected) => {
    const country = countryList.filter(
      (i) => i.code.toUpperCase() === selected
    )[0].name;
    fetch_coordinates(country);
    setShowHotSpotIcon(false);
    // setCountry(country);
    handleSetAreas(country);
  };

  const handleGetDetails = async (countr) => {
    let fetchedDetails = await fetchDetails({ countr });
    if (fetchedDetails.success) {
      let countriesList = [];
      fetchedDetails.data.countries.map((count) => {
        if (
          countryList.filter((cnt) => {
            return cnt.name == count;
          }).length
        ) {
          countriesList.push(
            countryList
              .filter((cnt) => {
                return cnt.name == count;
              })[0]
              .code.toUpperCase()
          );
        }
      });
      setCountries(countriesList);
    }
    if (
      fetchedDetails.data.countries.filter((cnt) => {
        return cnt === countr;
      }).length
    ) {
      await handleSetAreas(countr);
    } else {
      await handleSetAreas(fetchedDetails.data.countries[0]);
    }
  };

  const handleSetAreas = async (country) => {
    let { aid, email, token } = props;
    let areas = await getPendingCovidAreas({ aid, email, country }, token);
    setAreas(areas.data);
  };

  const handleMarkLocation = async (coords, oi, list) => {
    if (oi === 0 && fields === 3) {
      let field = 3;
      field += list.links.length;
      if (list.image) {
        field += 1;
      }
      if (list.sections.length > 1) {
        field += 1;
      }
      setFields(field);
    }
    console.log(coords);
    setViewPort({
      latitude: coords.latitude,
      longitude: coords.longitude,
      zoom: 10,
      bearing: 0,
      pitch: 0,
      transitionDuration: 1000,
    });
    setCoords({ latitude: coords.latitude, longitude: coords.longitude });
    setShowHotSpotIcon(true);
  };

  const cbUpdate = (e) => {
    // e.preventDefault();
    console.log(e.target.checked);
    if (e.target.checked) {
      if (reviews + 1 === fields) {
        setIsReviewed(true);
      } else {
        setIsReviewed(false);
      }
      setReviews(reviews + 1);
    } else {
      setReviews(reviews - 1);
      setIsReviewed(false);
    }
  };

  const handleAreaNameChange=(e,index)=>{
    // setAreas
    const list_name=e.target.value.trim()
    let newAreas=[...areas]
    newAreas[index].list_name=list_name
    console.log();
    setAreas(newAreas)
  }



  return (
    <div className="container-fluid stack-container covid-verifier-container">
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
           <div className="covid19-edit-modal-body">
            {makeEditModalBody()}
           </div>
        </Modal>
      <div class="country-select-wrapper">
        {countryCode !== "" ? (
          <FlagSelect
            defaultCountry={countryCode}
            onSelect={handleCountrySelect}
            countries={countries}
          />
        ) : null}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 map_container_fixed">
            {/*Map Component*/}
            <div style={{ height: "400px" }} className={"col-lg-8"}>
              <ReactMapGL
                ref={mapRef}
                {...viewport}
                width="100%"
                height="100%"
                onViewportChange={_updateViewport}
                mapboxApiAccessToken={MAPBOX_TOKEN}
              >
                {showHotSpotIcon ? (
                  <Marker
                    longitude={coords.longitude}
                    latitude={coords.latitude}
                    offsetTop={-20}
                    offsetLeft={-10}
                  >
                    <Pin size={20} />
                  </Marker>
                ) : null}
              </ReactMapGL>
            </div>
          </div>
          <div className="col-md-6 areas_container_scroll">
            <div className="approve-card-wrapper">
              {areas.length ? (
                areas.map((area, oi) => {
                  return (
                    <div className="approve-card">
                      <div className="approve-card_prim-btn">
                        {area.highlights[1]}
                      </div>
                      <div
                        className="approve-card_pass-btn"
                        onClick={(e) => {
                          if (oi === 0)
                            handleReviewUpdate(e, area._id, "passed");
                        }}
                        style={{
                          opacity: oi === 0 ? 1 : 0.5,
                        }}
                      >
                        {"Pass"}
                      </div>
                      <div className="approve-card-outline"></div>

                      <div
                        className="approve-card_content"
                        onClick={(e) =>
                          handleMarkLocation(area.coordinates[0], oi, area)
                        }
                      >
                        <div class="approve-card_content-area">
                          {oi === 0 ? (
                            <input
                              type="checkbox"
                              onChange={(e) => cbUpdate(e, area)}
                            />
                          ) : null}
                          Area: <span>{area.list_name}</span> <br />
                          {/* Area: <span> <input type="text" value={area.list_name} onChange={e=>handleAreaNameChange(e,oi)}/></span> <br /> */}
                          {oi === 0 ? (
                            <input
                              type="checkbox"
                              onChange={(e) => cbUpdate(e, area, oi)}
                            />
                          ) : null}
                          City: <span>{area.sections[0].items.city}</span>
                          <br />
                          {oi === 0 ? (
                            <input
                              type="checkbox"
                              onChange={(e) => cbUpdate(e, area, oi)}
                            />
                          ) : null}
                          State: <span>{area.sections[0].items.state}</span>
                          <br />
                          {/* Primary:{" "}
                                                <span>
                                                    {area.highlights[1]}
                                                </span>
                                                <br /> */}
                        </div>
                        <div class="approve-card_content-proof">
                          {area.links.map((link, i) => {
                            return (
                              <>
                                <span
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {oi === 0 ? (
                                    <input
                                      type="checkbox"
                                      style={{
                                        display: "inline",
                                      }}
                                      onChange={(e) => cbUpdate(e, area, oi)}
                                    />
                                  ) : null}
                                  <a
                                    href={
                                      area.links[i].includes("http")
                                        ? area.links[i]
                                        : `https://${area.links[i]}`
                                    }
                                    style={{
                                      fontSize: "15px",
                                      display: "inline",
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Link
                                    {i + 1}
                                  </a>
                                </span>
                              </>
                            );
                          })}
                          {area.image ? (
                            <>
                              <span
                                style={{
                                  display: "block",
                                }}
                              >
                                {oi === 0 ? (
                                  <input
                                    type="checkbox"
                                    style={{
                                      display: "inline",
                                    }}
                                    onChange={(e) => cbUpdate(e, area, oi)}
                                  />
                                ) : null}
                                <a
                                  href={area.image}
                                  style={{
                                    fontSize: "15px",
                                    display: "inline",
                                  }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Attachment
                                </a>
                              </span>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="approve-card_footer-btns">
                        {area.description !== "" ? (
                          <>
                            <Popup
                              trigger={
                                <div className="approve-card_note-btn">
                                  Notes
                                </div>
                              }
                              modal
                              closeOnDocumentClick
                            >
                              <span>{area.description}</span>
                            </Popup>
                          </>
                        ) : null}
                        {area.sections.length > 1 ? (
                          <>
                            <Popup
                              trigger={
                                <div className="approve-card_entity-btn">
                                  {oi === 0 ? (
                                    <input
                                      type="checkbox"
                                      onChange={(e) => cbUpdate(e, area, oi)}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    />
                                  ) : null}
                                  Contact
                                </div>
                              }
                              modal
                              closeOnDocumentClick
                              style={{
                                width: "50%",
                              }}
                            >
                              <div>Name: {area.sections[1].items.name}</div>
                              <div>
                                Contact: {area.sections[1].items.contact}
                              </div>
                              <div>Type: {area.sections[1].items.entity}</div>
                            </Popup>
                          </>
                        ) : null}

                        <div className="btns">
                          <div
                            className="approve-btn"
                            onClick={(e) => {
                              if (oi === 0 && isReviewed)
                                handleReviewUpdate(e, area._id, "approved");
                            }}
                            style={{
                              opacity: oi === 0 && isReviewed ? 1 : 0.5,
                            }}
                          >
                            Approve
                          </div>
                          <div
                            className="reject-btn"
                            onClick={(e) => {
                              if (oi === 0 && isReviewed)
                                handleReviewUpdate(e, area._id, "rejected");
                            }}
                            style={{
                              opacity: oi === 0 && isReviewed ? 1 : 0.5,
                            }}
                          >
                            Reject
                          </div>
                          <div
                            className="edit-btn"
                            onClick={(e) => {
                                setEditIndex(oi);
                                handleOpen();
                            }}
                            style={{
                              opacity: oi === 0 && isReviewed ? 1 : 0.5,
                            }}
                          >
                            Edit
                          </div>
                        </div>
                        {/* ) : null} */}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="noAreasToReview">No Areas to Review!</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="extras">
        <div>Covid-19 Lists</div>
        <div>Help</div>
      </div>
    </div>
  );
}

const MAPBOX_TOKEN =
  "pk.eyJ1Ijoicm9ja3RpbXNhaWtpYSIsImEiOiJjazk2MmlwbGQwNGI2M2ZxaHBxbDdqcnJ5In0.eAm8OSlFEAs7GM_hE3Ernw";

CovidVerifier.propTypes = {
  aid: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};
