import React, { Component,Fragment } from "react";
import CSVInput from "./CSVInput";

import {
  handleCheckTitle,
  getItemsAndDetailsArrayFromDataArray,
} from "../Utils/helperFunctions";

class CSVBtnComponent extends Component {
  state = {
    showCSVInput: true,
    csvFile: "",
  };

  handleCSVClick = () => {
    document.querySelector(".csv-input").click();
  };

  handleCSVOutput = (csvData) => {
    let { items, details, listName } = this.props;

    // Get 1D array of data from 2D array, remove the numering too
    let data = csvData.map((item) =>
      item[0].replace(/^\d+\.|^[a-z]{1}\.|^[A-Z]{1}\./g, "").trim()
    );

    // Create a set from data object to remove duplicates
    data = [...new Set(data)];

    if (!data) {
      alert("Invalid file");
      return;
    } else if (!data[0]) {
      alert("Invalid format");
      return;
    } else if (!data[1]) {
      alert("Incomplete data");
      return;
    }

    console.log("CSV DATA:", data);

    if (listName.length <= 8 && items.length === 0) {
      /*
      CASE 1: New list : if there is no item in the list and no list name has been added added
      -> Take the first item and make it the list name
      -> Take the rest of the items and make it items
    */

      if (data.length < 2) {
        this.showSnackBar(
          "Invalid format! The CSV file must have at lease two items!!",
          "error"
        );
        return;
      }

      const { newItems, newDetails } = getItemsAndDetailsArrayFromDataArray(
        data.slice(1, data.length),
        this.props.checkDuplicateInItems,
        this.props.aid
      );

      //changing listTitle:
      let listname = handleCheckTitle(data[0]);
      let evelistTitle = {
        target: {
          name: "listName",
          value: listname,
        },
      };

      this.props.onHandleChange(evelistTitle);

      for (let i = 0; i < newItems.length; i++) {
        items.push(newItems[i]);
        details.push(newDetails[i]);
      }

      this.props.updateItems(items);
      this.props.updateDetails(details);

      this.setState({
        csvFile: "",
        showCSVInput: false,
      });
    } else {
      /*
      CASE 2: New list : if some items is already present in the list and list already has a name
      -> Make every item of the array a list item
     */
      if (data.length < 1) {
        this.showSnackBar(
          "Invalid format!, Please enter at lease one item!!",
          "error"
        );
        return;
      }

      const { newItems, newDetails } = getItemsAndDetailsArrayFromDataArray(
        data,
        this.props.checkDuplicateInItems,
        this.props.aid
      );

      for (let i = 0; i < newItems.length; i++) {
        items.push(newItems[i]);
        details.push(newDetails[i]);
      }

      this.props.updateItems(items);
      this.props.updateDetails(details);
    }
  };

  render() {
    const { showCSVInput, csvFile } = this.state;
    return (
      <Fragment>
        {showCSVInput ? (
          <Fragment>
            <button className="btn" onClick={this.handleCSVClick}>
              <i className="fun-icon fa fa-upload" />{" "}
              <span className="btn-text">Upload CSV</span>
            </button>
            <CSVInput
              handleCSVOutput={this.handleCSVOutput}
              csvFile={csvFile}
            />
          </Fragment>
        ) : (
          <small className="csv-success-text">
            <i className="far fa-check-circle" /> File imported
          </small>
        )}
      </Fragment>
    );
  }
}
export default CSVBtnComponent;
