const { slugify } = require("../components/Utils/helperFunctions");
export const LANDING_PAGE = "/";
export const HOME_PATH = "/discover";
export const UPDATES_PATH = "/updates"
export const DISCOVER_PATH = "/discover";
export const SHARE_PATH = "/share";
export const REQUEST_PATH = "/request";
export const ASK_PATH = "/ask";
export const LIST_PATH = "/list/:id/:name/:itemId";
export const ADMIN_PATH = "/admin";
export const EDIT_PATH = "/edit/:id/:name";
export const PROFILE_PATH = "/profile/:user_name";
export const USER_PROFILE_PATH = "/user/:username";
export const DISCLAIMER_PATH = "/disclaimer";
export const TERMS_OF_USE_PATH = "/terms-of-use";
export const PRIVACY_POLICY_PATH = "/privacy-policy";
export const PROFILE_SETTING_PATH = "/profile-setting";
export const SEARCH_RESULTS_PATH = "/search";
export const PEOPLE_PATH = "/people";
export const TAG_PATH = "/tags";
export const CATEGORIES_PATH = "/categories";
export const REPORT_PATH = "/report";
export const FEEDBACK_PATH = "/feedback";
export const BLOG_PATH = "/blog";
export const USERNAME_POLICY = "/username-policy";
export const LINKEDIN = "/linkedin";
export const RESPOND_INVITE = "/invitation/:lsid/:collabEmail";
export const STACK_PATH = "/stack/:name/:id";
export const BOARD_PATH = "/board/:name/:id";
export const COVID_PATH = "/covid19";
export const COVID_REQUEST = '/covid19/request';
export const COVID_VERIFIER_PATH = "/covid19/volunteer";
// auth paths
export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";
export const FORGOT_PATH = "/forgot-password";

export const getListPath = (name, id, itemId) =>
  itemId
    ? "/list/" + id + "/" + name + "/" + itemId
    : "/list/" + id + "/" + name + "/item";
export const getEditPath = (name, id) => "/edit/" + id + "/" + name;
export const getUserProfilePath = (uname) => "/user/" + uname;
export const getProfilePath = (uname) => `/profile/${uname}`;

export const getStackPath = (name, id) => `/stack/${slugify(name)}/${id}`;
export const getBoardPath = (name, id) => `/board/${slugify(name)}/${id}`;
