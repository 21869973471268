import React, { Component } from 'react';

export class Recommendations extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='recommendations-container' style={{"width":"100%"}}>
        <div className='heading'>
          <h1 className='heading-text'>Recommended Lists</h1>
        </div>
        <div className='recommendations-list'>
          {this.props.recommendations.map((recommendation, index) => (
            <a
              className={'item' + (index % 2 ? '' : ' dark')}
              key={recommendation._id}
              href={
                '/list/' +
                recommendation._id +
                '/' +
                recommendation.slug +
                '/item'
              }
            >
              {recommendation.name}
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export default Recommendations;
