import React from 'react';
import { Link } from 'react-router-dom';
import partyHat from "../assets/party-hat.png";
import "../../styles/components/ListAuthor.css";
import { NavLink } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

const ProfileHeader = (props) => {
  const checkIfNull = (count) => (count > 0 ? count : 0);
  setTimeout(() => {
    const elem = document.getElementById("user_party_hat");
    elem.parentNode.removeChild(elem);
  }, 1209600000);

  const userIcon = ()=>{
    if(props.user_type==='normal'){
      return "/assets/partypoppers.svg"
    }else if(props.user_type==='amender'){
      return "/assets/handshake.svg"
    }else if(props.user_type ==='mender'){
      return "/assets/thumbsup.svg"
    }else{
      return "/assets/partypoppers.svg"
    }
  }

  return (
    <div className='main-container'>
      <div className='container-header'>
        <div className='img-wrapper'>
          <div className='img-container'>
            <span>
              <img
                src={props.avatar}
                alt='87582006-197295844688779-489064623165669376-n'
                border='0'
              />
            </span>
            <Tooltip title="Hello! Are you new? Start here" aria-label="ProfileHeader">
              <NavLink to="/guideLines#user-roles" target="_blank">
                <img
                  className="party_hat_emoji"
                  src={userIcon()}
                  alt="Party-Hat"
                  id="user_party_hat"
                  style={{ height: "40px" }}
                />
              </NavLink>
            </Tooltip>
          </div>
        </div>

        <div className='info-wrapper'>
          <div className='profile_name'>
            <h1>{props.name===""?props.username:props.name}</h1>
            {props.isSelfProfile && (
              <Link to='/profile-setting' className='edit_btn'>
                <i
                  className='fas fa-cog'
                  style={{
                    color: '#545454',
                    marginLeft: ' 6px',
                  }}
                />
              </Link>
            )}
          </div>

          <div className='profile_info'>
            <h1>@{props.username}</h1>
            <div>{props.bio}</div>
          </div>

          <div className='stats'>
            <ul className='stats_info'>
              {/* <li onClick={() => props.toggleBookMarkModal(3)}><span>{checkIfNull(props.bookmarks)}</span> Saved</li> */}
              <li onClick={() => props.toggleBookMarkModal(2)}>
                <span>{checkIfNull(props.followers)}</span> followers
              </li>
              <li onClick={() => props.toggleBookMarkModal(1)}>
                <span>{checkIfNull(props.following)}</span> following
              </li>
            </ul>

            <div className='profile_social-fallow'>
              <a
                className='link'
                href={
                  !props.fb_url
                    ? ` https://facebook.com/${props.username}`
                    : props.fb_url
                }
              >
                <i class='fab fa-facebook'></i>
              </a>
              <a
                className='link'
                href={
                  !props.linkedIn_url
                    ? `https://www.linkedin.com/${props.username}`
                    : props.linkedIn_url
                }
              >
                <i class='fab fa-linkedin-in link-icon'></i>
              </a>
              <a className='link' href={props.linkedIn_url}>
                <i class='fas fa-globe'></i>
              </a>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      {/* <ul className="next-tab">
                    <li><div className="mb-3"><EventNoteIcon style={{ color: '#f99696' }} /></div>Contributions</li>
                    <li><div className="mb-3"><SpeakerNotesIcon style={{ color: '#f99696' }} /></div>All Notes</li>
                    <li><div className="mb-3"><BookmarksIcon style={{ color: '#f99696' }} /></div>Bookmarks</li>
                </ul> */}
      {/* <ul className="social-icons">
                    <li className="fb">Facebook <i className="fab fa-facebook-square"></i></li>
                    <li className="twit">Twitter <i className="fab fa-twitter"></i></li>
                    <li className="linked">Linkedin <i className="fab fa-linkedin"></i></li>
                    <li className="website">Website <i className="fas fa-globe"></i> </li>
                </ul> */}
    </div>
  );
};

export default ProfileHeader;
