import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={260}
    viewBox="0 0 400 260"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="62" y="17" rx="3" ry="3" width="410" height="40" />
    <rect x="66" y="154" rx="0" ry="0" width="334" height="40" />
    <rect x="65" y="89" rx="3" ry="3" width="300" height="40" />
    <rect x="9" y="153" rx="3" ry="3" width="40" height="40" />
    <rect x="7" y="18" rx="3" ry="3" width="40" height="40" />
    <rect x="8" y="87" rx="3" ry="3" width="40" height="40" />
  </ContentLoader>
)

export default MyLoader