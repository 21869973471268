import React, { useState } from "react";
import "./styles/blogPage.css";
import Abdul from "../assets/abdul.jpg";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import IconButton from "@material-ui/core/IconButton";
import blogImage from "../assets/blog.jpg";
import { NavLink } from "react-router-dom";
import BlogNav from "./BlogNav";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Tooltip from "@material-ui/core/Tooltip";

function BlogDetails() {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <>
      <BlogNav />
      <div className="blog_details_page">
        <div className="categories">
          <h3 className="blog_categories_heading">Categories</h3>
          <NavLink
            to="#"
            style={{ textDecoration: "none", color: "black" }}
            title="Travel Blog"
          >
            <p className="blog_category_links">Travel</p>
          </NavLink>
          <NavLink
            to="#"
            style={{ textDecoration: "none", color: "black" }}
            title="Educational Blog"
          >
            <p className="blog_category_links">Education</p>
          </NavLink>
          <NavLink
            to="#"
            style={{ textDecoration: "none", color: "black" }}
            title="Personal Blog"
          >
            <p className="blog_category_links">Personal</p>
          </NavLink>
          <NavLink
            to="#"
            style={{ textDecoration: "none", color: "black" }}
            title="Development Blog"
          >
            <p className="blog_category_links">Development</p>
          </NavLink>
          <NavLink
            to="#"
            style={{ textDecoration: "none", color: "black" }}
            title="Technology Blog"
          >
            <p className="blog_category_links">Technology</p>
          </NavLink>
        </div>
        <div className="blog_details">
          <div className="blog_details_heading">
            Artwork Personalization at Netflix
          </div>
          <div className="blog_user_details">
            <img className="blog_user_img" src={Abdul} alt="Abdul" />
            <div className="blog_user_name">
              <p>
                <strong>Abdul Latif</strong>
              </p>
              <p>Dec 7, 2017 </p>
            </div>
            <div className="social_icons">
              <IconButton>
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  title="Go to facebook "
                  style={{color:"#4267B2"}}
                >
                  <FacebookIcon />
                </a>
              </IconButton>
              <IconButton>
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  title="Go to Linkedien "
                  style={{color:"#0077b5"}}
                >
                  <LinkedInIcon />
                </a>
              </IconButton>
              <IconButton>
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  title="Go to Instagram "
                  style={{ color: "#fb3958" }}
                >
                  <InstagramIcon color="#fb3958" />
                </a>
              </IconButton>
              <IconButton>
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  title="Go to Twiter "
                  style={{color:"#00acee"}}
                >
                  <TwitterIcon />
                </a>
              </IconButton>
            </div>
          </div>
          <div className="main_blog_content">
            <img
              className="blog_deatils_img"
              src={blogImage}
              alt="Blog Image"
            />
            <p className="blog_details_content">
              For many years, the main goal of the Netflix personalized
              recommendation system has been to get the right titles in front
              each of our members at the right time. With a catalog spanning
              thousands of titles and a diverse member base spanning over a
              hundred million accounts, recommending the titles that are just
              right for each member is crucial. But the job of recommendation
              does not end there. Why should you care about any particular title
              we recommend? What can we say about a new and unfamiliar title
              that will pique your interest? How do we convince you that a title
              is worth watching? Answering these questions is critical in
              helping our members discover great content, especially for
              unfamiliar titles. One avenue to address this challenge is to
              consider the artwork or imagery we use to portray the titles. If
              the artwork representing a title captures something compelling to
              you, then it acts as a gateway into that title and gives you some
              visual “evidence” for why the title might be good for you. The
              artwork may highlight an actor that you recognize, capture an
              exciting moment like a car chase, or contain a dramatic scene that
              conveys the essence of a movie or TV show. If we present that
              perfect image on your homepage (and as they say: an image is worth
              a thousand words), then maybe, just maybe, you will give it a try.
              This is yet another way Netflix differs from traditional media
              offerings: we don’t have one product but over a 100 million
              different products with one for each of our members with
              personalized recommendations and personalized visuals.
            </p>
            <Tooltip title="Back to Top" aria-label="Discover">
              <KeyboardArrowUpIcon
                id="back_to_top"
                onClick={scrollToTop}
                style={{ display: visible ? "inline" : "none" }}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogDetails;
