import React, {Component} from 'react';
import PropTypes from 'prop-types';

class EmailOTPInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
        v0: "",
        v1: "",
        v2: "",
        v3: "",
        v4: "",
        v5: ""
    };
  }
  
  render() {

    const handleOnChange = async (e) => {
      await this.setState({ [e.target.name]: e.target.value.trim() });
      let { onChange } = this.props;
      let { v0, v1, v2, v3, v4, v5 } = this.state;
      let value = `${v0}${v1}${v2}${v3}${v4}${v5}`;
      onChange(value);
    };

    const nextInput = e => {
      let key = e.keyCode;
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      if (key !== 9 && key !== 16 && (key >=48 || key <= 57)) {  
        if(form.elements[index + 1]){
          form.elements[index + 1].focus();
        } else {
          this.props.verify(e);
        }
      }
      if(key === 8){
        e.target.value = "";
        if(index === 0){
          form.elements[form.elements.length - 1].focus();  
        } else {
          form.elements[index-1].focus();  
        }
      }
    }

    const focus = e => {
      e.target.select();
    }

    return (
      <div className={'otp_container'}>
        <p className={'text-center mt-5'}>Enter the verification code</p>
        <form className="text-center mx-auto">
          <input type="text" className={'otp-input ml-2'} name={"v0"} maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onKeyUp={e => nextInput(e)} onFocus={e => focus(e)} onChange={(e) => handleOnChange(e)} placeholder={0}/>
          <input type="text" className={'otp-input ml-2'} name={"v1"} maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onKeyUp={e => nextInput(e)} onFocus={e => focus(e)} onChange={(e) => handleOnChange(e)} placeholder={0}/>
          <input type="text" className={'otp-input ml-2'} name={"v2"} maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onKeyUp={e => nextInput(e)} onFocus={e => focus(e)} onChange={(e) => handleOnChange(e)} placeholder={0}/>
          <input type="text" className={'otp-input ml-2'} name={"v3"} maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onKeyUp={e => nextInput(e)} onFocus={e => focus(e)} onChange={(e) => handleOnChange(e)} placeholder={0}/>
          <input type="text" className={'otp-input ml-2'} name={"v4"} maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onKeyUp={e => nextInput(e)} onFocus={e => focus(e)} onChange={(e) => handleOnChange(e)} placeholder={0}/>
          <input type="text" className={'otp-input ml-2'} name={"v5"} maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" onKeyUp={e => nextInput(e)} onFocus={e => focus(e)} onChange={(e) => handleOnChange(e)} placeholder={0}/>
        </form>
      </div>
    );
  }
}

EmailOTPInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default EmailOTPInput;