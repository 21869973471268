import React, { Component } from "react";
import { CATEGORIES } from "../constants/categoryConstants";

class Feedback extends Component {
	constructor(props) {
		super(props);

		this.state = {
			feature_name: "",
			feature_txt: "",
			featureCategory: ""
		};
	}

	onHandleChange = e => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	onSubmit = e => {
		e.preventDefault();
		this.setState({
			loading: true
		});

		const { feature_name, feature_txt, featureCategory } = this.state;

		if (!feature_name || !feature_txt || !featureCategory) {
			console.log("Empty Field !!!");
			return;
		}

		const data = {
			Feature_Category: featureCategory,
			Feature_Name: feature_name,
			Feature_Message: feature_txt
		};

		console.log(data);
	};

	render() {
		const { feature_name, feature_txt, featureCategory } = this.state;

		let renderCategories = [];
		CATEGORIES.forEach(item => {
			renderCategories.push(<option value={item}>{item}</option>);
		});

		return (
			<div className="feature_section">
				<div className="feature_txt">
					Thank You !! For the Support{" "}
					<i
						className="far fa-smile-beam"
						style={{ backgroundColor: "yellow", borderRadius: "300px" }}
					/>
				</div>

				<div className="feature_form">
					<h2>Feedback / Request Feature</h2>
					<div
						className={"form-group featureCategory"}
						style={{ display: "flex", flexDirection: "row" }}
					>
						<label style={{ paddingTop: "0.5vw" }}>Category</label>
						<select
							name={"featureCategory"}
							className={"form-control"}
							onChange={this.onHandleChange}
							value={featureCategory}
						>
							<option>- Select a category -</option>
							{renderCategories}
						</select>
					</div>
					<div className="feature_name">
						<p>Title : </p>
						<input
							type="text"
							name={"feature_name"}
							value={feature_name}
							onChange={this.onHandleChange}
						/>
					</div>
					<div className="feature_content">
						<p>Description : </p>
						<textarea
							placeholder="Pleae explain in detail."
							name={"feature_txt"}
							value={feature_txt}
							onChange={this.onHandleChange}
						/>
					</div>

					<div
						className="feature_btn"
						onClick={this.onSubmit}
						style={{ cursor: "pointer" }}
					>
						Submit
					</div>
				</div>
			</div>
		);
	}
}

export default Feedback;
