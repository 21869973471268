import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ErrorAlert from "../Nested/ErrorAlert";
import Button from "@material-ui/core/Button";
import { getProfilePath } from "../../constants/pathConstants";


const styles = theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200
    },
    dense: {
        marginTop: 19
    },
    menu: {
        width: 200
    }
});

class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aid: this.props.aid,
            name: "",
            bio: "",
            company: "",
            url: "",
            fb_url: `facebook.com/`,
            linkedIn_url: "linkedin.com/",
            other_url: "",
            avatar: "",
            showAlert: false,
            alertCode: "",
            message: "",
            instagramClicked: false,
            redditClicked: false,
            telegramClicked: false,
            tumblrClicked: false,
            twitterClicked: false,
            pinterestClicked: false,
            devClicked: false,
            instagram_url: "instagram.com/",
            reddit_url: "reddit.com/user/",
            telegram_url: "t.me/",
            tumblr_url: "tumblr.com/",
            twitter_url: "twitter.com/",
            pinterest_url: "pinterest.com/",
	        dev_url: "dev.to/",
        };
    }

    showSnackBar = (code, msg) => {
        this.setState({
            showAlert: true,
            alertCode: code,
            message: msg
        });
    };

    AutoHideSnackbar = () => {
        this.setState({
            showAlert: false
        });
    };

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        console.log(nextProps, this.state);
        if (Object.keys(nextProps.profile).length != 0) {
            this.setState({
                name: nextProps.profile.name || "",
                bio: nextProps.profile.bio || "",
                company: nextProps.profile.company || "",
                url: nextProps.profile.url || "",
                fb_url: nextProps.profile.fb_url || "",
                linkedIn_url: nextProps.profile.linkedIn_url || "",
                instagram_url: nextProps.profile.instagram_url || "instagram.com/",
                reddit_url: nextProps.profile.reddit_url || "reddit.com/user/",
                telegram_url: nextProps.profile.telegram_url || "t.me/",
                pinterest_url: nextProps.profile.pinterest_url || "pinterest.com/",
                dev_url: nextProps.profile.dev_url || "dev.to/",
                tumblr_url: nextProps.profile.tumblr_url || "tumblr.com/",
                twitter_url: nextProps.profile.twitter_url || "twitter.com/",
            });
        }
        if (this.state.instagramClicked || this.state.redditClicked || this.state.telegramClicked || this.state.tumblrClicked || this.state.twitterClicked || this.state.pinterestClicked || this.state.devClicked ) {
            if (this.props.anySocialLinkAdded) {
                this.props.anySocialLinkAdded(true);
            }
        }
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleAvatarChange = (e) => {
        if (e.target.files && !e.target.files[0].type.includes("image")) {
            this.showSnackBar("error", "Only images are allowed");
            return;
        }
        if (e.target.files && e.target.files[0].size > 5 * 1024 * 1024) {
            this.showSnackBar("error", "Image size should be less than 5mb.");
            return;
        }

        this.setState({
            avatar: e.target.files[0]
        });
    };

    handleSubmit = async () => {
        let form_data = new FormData();
        if (this.state.avatar !== "") {
            form_data.append(
                "avatar",
                this.state.avatar,
                this.state.avatar.name
            );
        }



        form_data.append("aid", this.props.aid);
        form_data.append("name", this.state.name);
        form_data.append("bio", this.state.bio);
        form_data.append("company", this.state.company);
        form_data.append("url", this.state.url);
        form_data.append("linkedIn_url", this.state.linkedIn_url);
        form_data.append("fb_url", this.state.fb_url);
        form_data.append("instagram_url", this.state.instagram_url);
        form_data.append("reddit_url", this.state.reddit_url);
        form_data.append("telegram_url", this.state.telegram_url);
        form_data.append("tumblr_url", this.state.tumblr_url);
        form_data.append("twitter_url", this.state.twitter_url);
        form_data.append("pinterest_url", this.state.pinterest_url);
        form_data.append("dev_url", this.state.dev_url);

        await this.props.editProfile(form_data, this.props.token);
        await this.props.fetchProfile(
            { aid: this.props.aid },
            this.props.token
        );
        if (this.props.editProfileMessage === "success") {
            this.showSnackBar("success", "Profile updated successfully.");
        } else {
            this.showSnackBar("error", this.props.editProfileMessage);
        }

        // if(this.props.anySocialLinkAdded) {


        //     if(this.state.fb_url != "facebook.com/" || !this.state.linkedIn_url !== "linkedin.com/" || !this.state.instagram_url !== "instagram.com/" || !this.state.reddit_url !== "reddit.com/user/" || !this.state.telegram_url !== "t.me/" || !this.state.tumblr_url !== "tumblr.com/" ||  !this.state.instagram_url !== "instagram.com/" || !this.state.pinterest_url !== "pinterest.com/" || !this.state.dev_url !== "dev.to/" ) {

        //         this.props.anySocialLinkAdded(false);
        //         this.showSnackBar("error", "You can also add links to your other social media profiles");

        //     }
        // }
    };

    handleGoBack = () => {
        if(this.props.history.action === "PUSH"){
            this.props.history.goBack();
        } else {  
            this.props.history.push(getProfilePath(this.props.user_name));
        }
    };

    render() {
        return (
            <div className="profile-setting-container container-fluid">
                <div className="profile-setting-box">
                    <div className="row">
                        <ErrorAlert
                            AutoHideSnackbar={this.AutoHideSnackbar}
                            showAlert={this.state.showAlert}
                            code={this.state.alertCode}
                            message={this.state.message}
                        />
                            <div className="profile-pic">
                                    <input
                                        accept="image/*"
                                        id="avatar"
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={e =>
                                            this.handleAvatarChange(e)
                                        }
                                    />
                              <label htmlFor="avatar">
                                <img
                                    src={
                                        !this.state.avatar
                                            ? this.props.profile.avatar
                                                ? this.props.profile.avatar
                                                : "assets/nfound.png"
                                            : URL.createObjectURL(
                                                this.state.avatar
                                            )
                                    }
                                    alt="Avatar"
                                    className={"profile-avatar"}
                                />

                                    </label>
                                <div className="upload-btn-container">
                                    <input
                                        accept="image/*"
                                        id="avatar"
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={e =>
                                            this.handleAvatarChange(e)
                                        }
                                    />
                                    <label htmlFor="avatar">
                                        <Button
                                            className="btn-save change-btn "
                                            
                                            component="span"
                                        >
                                            <i className="fas fa-camera"></i>{" "}
                                            Change
                                        </Button>
                                    </label>
                                    {/* <input type="file" className="btn btn-light" id="avatar" name="avatar" onChange={e => this.handleAvatarChange(e)}/> */}
                                    {/* <button className="btn btn-light"><i className="fas fa-camera"></i> Change</button> */}
                                </div>
                            </div>

                        <div
                            className="edit-input-text-box col col-12 col-sm-8"
                            style={{ marginTop: "-30px" }}
                            >
                                
                                    <TextField
                                        className="edit-text-input"
                                        id="name"
                                        label="Name"
                                        value={this.state.name}
                                        onChange={this.handleChange("name")}
                                        margin="normal"
                                    />
                                    <TextField
                                        className="edit-text-input"
                                        id="bio"
                                        label="Bio"
                                        value={this.state.bio}
                                        onChange={this.handleChange("bio")}
                                        margin="normal"
                                    />
                                {/* I have to remove this component */}

				    {/* <div className="row">
					<div className="col-sm-12 col-md-6">
					<TextField
                                        className="edit-text-input"
                                        id="company"
                                        label="company Name"
                                        value={this.state.company}
                                        onChange={this.handleChange("company")}
                                        margin="normal"
					/>
					</div> 
					<div className="col-sm-12 col-md-6"> 
					<TextField
                                        className="edit-text-input"
                                        id="company_url"
                                        label="company Url"
                                        value={this.state.url}
                                        onChange={this.handleChange("url")}
                                        margin="normal"
					/>
					</div>
					</div>

				      */}
                                    <TextField
                                        id="fb_url"
                                        className="edit-text-input"
                                        label="Facebook link"
                                        // value={this.state.fb_url}
                                        value={!this.state.fb_url? ` https://facebook.com/${this.props.user_name}`:this.state.fb_url}
                                        onChange={this.handleChange("fb_url")}
                                        margin="normal"
                                    />
                            
                                    <TextField
                                        className="edit-text-input"
                                        id="linkedIn_url"
                                        label="LinkedIn url"
                                        value={!this.state.linkedIn_url? ` https://www.linkedin.com/${this.props.user_name}`:this.state.linkedIn_url}
                                        onChange={this.handleChange("linkedIn_url")}
                                        margin="normal"
                                    />

                            
                                    <TextField
                                        className="edit-text-input"
                                        id="other_url"
                                        label="Blog Url"
                                        value={this.state.other_url}
                                        onChange={this.handleChange("other_url")}
                                        margin="normal"
                                    />

				    {

					this.state.instagramClicked  &&

					<TextField
                                        className="edit-text-input"
                                        id="instagram_url"
                                        label="Instagram Url"
                                        value={this.state.instagram_url}
                                        onChange={this.handleChange("instagram_url")}
                                        margin="normal"
                                    />

				    }{

					this.state.redditClicked  &&

					<TextField
                                        className="edit-text-input"
                                        id="reddit_url"
                                        label="Reddit Url"
                                        value={this.state.reddit_url}
                                        onChange={this.handleChange("reddit_url")}
                                        margin="normal"
                                    />

				    }{

					this.state.tumblrClicked  &&

					<TextField
                                        className="edit-text-input"
                                        id="tumblr_url"
                                        label="Tumblr Url"
                                        value={this.state.tumblr_url}
                                        onChange={this.handleChange("tumblr_url")}
                                        margin="normal"
                                    />

				    }{

					this.state.twitterClicked  &&

					<TextField
                                        className="edit-text-input"
                                        id="twitter_url"
                                        label="Twitter Url"
                                        value={this.state.twitter_url}
                                        onChange={this.handleChange("twitter_url")}
                                        margin="normal"
                                    />

				    }{

					this.state.pinterestClicked  &&

					<TextField
                                        className="edit-text-input"
                                        id="pinterest_url"
                                        label="Pinterest Url"
                                        value={this.state.pinterest_url}
                                        onChange={this.handleChange("pinterest_url")}
                                        margin="normal"
                                    />

				    }	{

					this.state.devClicked &&

					<TextField
                                        className="edit-text-input"
                                        id="dev_url"
                                        label="Dev.to Url"
                                        value={this.state.dev_url}
                                        onChange={this.handleChange("dev_url")}
                                        margin="normal"
                                    />

				    }
				    {

					this.state.telegramClicked &&

					<TextField
                                            className="edit-text-input"
                                            id="telegram_url"
                                            label="telegram Url"
                                            value={this.state.telegram_url}                                       onChange={this.handleChange("telegram_url")}
                                        margin="normal"
                                    />

				    }

			    
				    <div className="social-icons">
					<i class="fab fa-instagram fa-2x" onClick={() => {this.setState({instagramClicked: true});} }></i>
					<i class="fab fa-reddit fa-2x" onClick={() => {this.setState({redditClicked: true});}}></i>
					<i class="fab fa-telegram fa-2x" onClick={() => {this.setState({telegramClicked: true});}}></i>
					<i class="fab fa-tumblr fa-2x" onClick={() => {this.setState({tumblrClicked: true});}}></i>
					<i class="fab fa-twitter fa-2x" onClick={() => {this.setState({twitterClicked: true});}}></i>
					<i class="fab fa-pinterest fa-2x" onClick={() => {this.setState({pinterestClicked: true});}}></i>
					<i class="fab fa-dev fa-2x" onClick={() => {this.setState({devClicked: true});}}></i>
				    </div>

                                
                                <div className="btn-save-box ">
                                    {/* <button
                                        className='btn-save btn btn-primary btn-return'
                                        onClick={this.handleGoBack}
                                        >
                                        Return
                                        </button> */}
					<button 
                                        className="btn-save btn btn-primary"
                                        onClick={() => {
                                            this.handleSubmit();

                                            // checking if rendered from the welcome modal
                                            // if yes then the prop function closeWelcomeModal will exist
                                            if(this.props.closeWelcomeModal)
                                                this.props.closeWelcomeModal();
                                        }
                                                }
                                    >
                                        Save
                                    </button>
                                </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EditProfile.propTypes = {
    token: PropTypes.string.isRequired,
    aid: PropTypes.string.isRequired
};

export default withStyles(styles)(EditProfile);
