import React from 'react';
import ContentLoader from "react-content-loader"

const ListLoader = () => (
  <ContentLoader 
    height={170}
    width={400}
    speed={0.5}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="15" y="41" rx="0" ry="0" width="123" height="13" /> 
    <rect x="36" y="60" rx="0" ry="0" width="123" height="13" /> 
    <rect x="15" y="81" rx="0" ry="0" width="123" height="13" /> 
    <rect x="26" y="100" rx="0" ry="0" width="123" height="13" /> 
    <rect x="15" y="121" rx="0" ry="0" width="123" height="13" /> 
    <circle cx="195" cy="22" r="16" /> 
    <circle cx="346" cy="45" r="1" /> 
    <rect x="185" y="46" rx="0" ry="0" width="192" height="35" /> 
    <rect x="228" y="12" rx="0" ry="0" width="114" height="20" /> 
    <circle cx="259" cy="98" r="8" /> 
    <circle cx="291" cy="98" r="8" /> 
    <circle cx="322" cy="98" r="8" /> 
    <rect x="184" y="112" rx="0" ry="0" width="189" height="21" /> 
    <rect x="16" y="11" rx="0" ry="0" width="21" height="20" /> 
    <rect x="48" y="14" rx="0" ry="0" width="82" height="13" />
  </ContentLoader>
)

export const ListLoaderForDiscover = () => (
<ContentLoader 
    height={350}
    width={320}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <circle cx="36" cy="33" r="21" /> 
    <rect x="65" y="16" rx="4" ry="4" width="233" height="13" /> 
    <rect x="77" y="36" rx="4" ry="4" width="218" height="10" /> 
    <rect x="15" y="61" rx="5" ry="5" width="288" height="248" />
  </ContentLoader>
)

export default ListLoader;
