import React, { Component } from 'react';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,

    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    PinterestIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon
} from 'react-share';
import ClickOutside from "react-click-outside"
import { getListPath } from "../../constants/pathConstants"

//TODO: More icons can be added later- 
class ProfileShareIcons extends Component {
    render() {
        const shareBaseUrl = this.props.shareBaseUrl;
        const title = "Hey, Checkout this list";

        return (
            <ClickOutside onClickOutside={this.props.hideShareMenu}>
                <div className={this.props.mode === "mylist" ? "text-center shareMenuIcons_mylist" : "text-center shareMenuIcons"}
                    style={this.props.isShareMenuOpen ? { display: 'flex' } : { display: 'none' }}
                >
                    <div className='iconWrapper' >
                        <FacebookShareButton
                            url={`${shareBaseUrl}fb`}
                            quote={title}
                            className="ShareIcons__some-network__share-button">
                            <FacebookIcon
                                size={25}
                                round />
                        </FacebookShareButton>
                    </div>

                    <div className='iconWrapper'>
                        <TwitterShareButton
                            url={`${shareBaseUrl}tw`}
                            title={title}
                            className="ShareIcons__some-network__share-button">
                            <TwitterIcon
                                size={25}
                                round />
                        </TwitterShareButton>
                    </div>

                    <div className='iconWrapper'>
                        <LinkedinShareButton
                            url={`${shareBaseUrl}ln`}
                            windowWidth={750}
                            windowHeight={600}
                            className="ShareIcons__some-network__share-button">
                            <LinkedinIcon
                                size={25}
                                round />
                        </LinkedinShareButton>
                    </div>

                    <div className='iconWrapper' >
                        <RedditShareButton
                            url={`${shareBaseUrl}ot`}
                            title={title}
                            windowWidth={660}
                            windowHeight={460}
                            className="ShareIcons__some-network__share-button">
                            <RedditIcon
                                size={25}
                                round />
                        </RedditShareButton>
                    </div>

                    <div className='iconWrapper' >
                        <WhatsappShareButton
                            url={`${shareBaseUrl}wa`}
                            title={title}
                            separator=":: "
                            className="ShareIcons__some-network__share-button">
                            <WhatsappIcon size={25} round />
                        </WhatsappShareButton>
                    </div>

                    <div className='iconWrapper'>
                        <TelegramShareButton
                            url={`${shareBaseUrl}tl`}
                            title={title}
                            className="ShareIcons__some-network__share-button">
                            <TelegramIcon size={25} round />
                        </TelegramShareButton>
                    </div>

                    <div className='iconWrapper'>
                        <PinterestShareButton
                            url={`${shareBaseUrl}ot`}
                            windowWidth={1000}
                            windowHeight={730}
                            className="ShareIcons__some-network__share-button">
                            <PinterestIcon size={25} round />
                        </PinterestShareButton>
                    </div>
                </div>
            </ClickOutside>
        );
    }
};


export default ProfileShareIcons;
