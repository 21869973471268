import React, { Component } from "react";
import { DISCOVER_PATH, getProfilePath } from "../constants/pathConstants";
import ErrorAlert from "./Nested/ErrorAlert";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reason: "",
      message: "",
      showAlert: false,
      alertMessage: "",
      alertCode: null,
      image: "",
    };
  }

  //Snackbar auto-hide method
  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false,
    });
  };

  showSnackBar = (message, code) => {
    this.setState({
      showAlert: true,
      alertMessage: message,
      alertCode: code,
    });
  };

  componentDidMount() {
    if (!this.props.state) {
      this.handleGoBack();
      return;
    } else {
      let { lsId, lsItId, listOwner } = this.props.state;
      if (!lsId) {
        this.handleGoBack();
        return;
      }
    }
  }

  onHandleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleAvatarChange = (e) => {
    if (e.target.files && !e.target.files[0].type.includes("image")) {
      this.showSnackBar("Only images are allowed", "error");
      return;
    }
    if (e.target.files && e.target.files[0].size > 5 * 1024 * 1024) {
      this.showSnackBar("Image size should be less than 5mb.", "error");
      return;
    }

    this.setState({
      image: e.target.files[0],
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const { reason, message } = this.state;
    const { lsId, lsItId, listOwner, type } = this.props.state;

    if (
      !lsId ||
      !reason ||
      reason === "- Select a category -" ||
      !listOwner ||
      !message
    ) {
      this.showSnackBar("Some fields are missing!!", "error");
      return;
    }

    let form_data = new FormData();
    if (this.state.image !== "") {
      form_data.append("image", this.state.image, this.state.image.name);
    }
    form_data.append("lsId", lsId);
    form_data.append("user", this.props.aid);
    form_data.append("userId", listOwner);
    form_data.append("reason", reason);
    form_data.append("message", message);
    form_data.append("type", type);

    if (lsItId && lsItId !== "") {
      form_data.append("lsItId", lsItId);
    }

    console.log(form_data);
    await this.props.reportList(form_data, this.props.token);
    if (this.props.reportedListMessage === "success") {
      this.showSnackBar("Item reported successfully. Thank You.", "success");
    } else {
      this.showSnackBar(this.props.reportedListMessage, "error");
    }
    setTimeout(() => {
      this.props.handleReportClose();
    }, 3000);
  };

  handleGoBack = () => {
    this.props.handleReportClose();
  };

  render() {
    let renderCategories = [
      <option label="Copyright content" value="Copyright content" />,
      <option label="Incorrect info" value="Incorrect info" />,
      <option label="Inappropriate content" value="Inappropriate content" />,
      <option label="Sensitive information" value="Sensitive information" />,
      <option label="Abusive content" value="Abusive content" />,
      <option label="Terrorism/Propaganda" value="Terrorism/Propaganda" />,
      <option label="Adult content" value="Adult content" />,
      <option label="Spam" value="Spam" />,
    ];

    if (!this.props.state) {
      return (
        <Modal
          style={{ margin: "0 10px" }}
          open={this.props.isReportModalOpen}
          onClose={this.props.handleReportClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div></div>
        </Modal>
      );
    }

    let { lsId, lsItId, type } = this.props.state;

    return (
      <Modal
        style={{ margin: "0 10px" }}
        open={this.props.isReportModalOpen}
        onClose={this.props.handleReportClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.props.isReportModalOpen}>
          <div className="report_section" style={{ borderRadius: "10px" }}>
            <ErrorAlert
              AutoHideSnackbar={this.AutoHideSnackbar}
              showAlert={this.state.showAlert}
              message={this.state.alertMessage}
              status={this.state.alertCode}
            />
            <div className="report-header">
              <i
                className="fa fa-times"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.handleGoBack();
                }}
              ></i>
            </div>
            <div className="report_txt">
            <h5>Report Content</h5>
            <p className="report-sub"><span className="report_txt_span1">"</span>We apologize for the inconvenience.<span className="report_txt_span2">"</span></p>
              {/* <i
                className="far fa-frown"
                style={{ backgroundColor: "yellow", borderRadius: "300px" }}
              /> */}
            </div>

            <div className="report_form">
              
              {/* <div className="report_id" style={{ fontSize: "14px" }}>
                <p>{type === "list" ? "List" : "Ask"} ID </p>
                <input type="text" name={"lsId"} value={lsId} disabled={true} />
              </div> */}
              {/* {lsItId ? (
                <div className="report_id" style={{ fontSize: "14px" }}>
                  <p>ListItem </p>
                  <input
                    type="text"
                    name={"lsItId"}
                    value={lsItId}
                    disabled={true}
                  />
                </div>
              ) : null} */}
              <div
                className={"form-group reportCategory"}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <label style={{ paddingTop: "0.5vw", width: "80px" }}>
                  Reason{" "}
                </label>
                <select
                  name={"reason"}
                  className={"form-control"}
                  id={"report_category_select"}
                  onChange={this.onHandleChange}
                  value={this.state.reason}
                >
                  <option>- Select a category -</option>
                  {renderCategories}
                </select>
              </div>
              <div className="report_content">
                <p>Message </p>
                <textarea
                  placeholder="Please explain in detail."
                  name={"message"}
                  value={this.state.message}
                  onChange={this.onHandleChange}
                />
              </div>
              <div className="report_content">
                {/* <p style={{ paddingTop: "2vh" }}>Reference: </p> */}
                <input
                  accept="image/*"
                  id="avatar"
                  type="file"
                  onChange={(e) => this.handleAvatarChange(e)}
                />
              </div>

              <span style={{ maxWidth: "25vh" }}>
                <button
                  className="report_btn"
                  onClick={this.onSubmit}
                  style={{ cursor: "pointer" }}
                >
                  Submit
                </button>
              </span>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default Report;
