import React, { Fragment } from "react";
import PropTypes from "prop-types";

function EditTags(props) {
  const { tags, handleDelete, handleTagInput } = props;
  return (
    <Fragment>
      {console.log(tags)}
      <div className={"form-group tag-container"}>
        <div className="tag-box">
          {tags
            ? tags.map((tag, i) => {
                return (
                  <div className="tag" key={i}>
                    <span>
                      {"#"}
                      {tag}
                    </span>
                    <i
                      className="fas fa-times"
                      onClick={(e) => handleDelete(tag)}
                    />
                  </div>
                );
              })
            : null}
        </div>
        <input
          type="text"
          name="tags"
          id="tag-input"
          className="form-control"
          placeholder="Enter comma separated tags"
          onKeyUp={handleTagInput}
          onFocus={(e) => (e.target.placeholder = "  add few reasonable tags.")}
          onBlur={(e) => (e.target.placeholder = "Add few reasonable tags.")}
        />
      </div>
    </Fragment>
  );
}

EditTags.propTypes = {
  tags: PropTypes.array,
  handleDelete: PropTypes.func.isRequired,
  handleTagInput: PropTypes.func.isRequired,
};

export default EditTags;
