import React, { Component,Fragment } from "react";
import ListAuthor from "./Nested/ListAuthor";
import {
  LOGIN_PATH,
} from "../constants/pathConstants";
import Comments from "./Comments";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export class ListSettingMob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showListNotes: false,
    };
  }
  toggleDrawer = () => {
    this.setState({ showListNotes: !this.state.showListNotes });
  };

  handleShowListNotes = async () => {
    if (!this.props.token) {
      this.props.history.push(LOGIN_PATH, {
        redirect_to: this.props.location.pathname,
      });
      return;
    }
    let listId = this.state.lsid;
    let lsItemId = null;
    if (
      this.props.match.params.itemId &&
      this.props.match.params.itemId !== "item"
    ) {
      lsItemId = this.props.match.params.itemId;
    }

    this.props.fetchAllComments(listId);

    this.toggleDrawer();
  };

  render() {
    return (
      <Fragment>
        {this.props.token ? (
          <Drawer
            variant="persistent"
            anchor="right"
            open={this.state.showListNotes}
            className="right-drawers"
          >
            <div className={"drawerHeader"}>
              <IconButton onClick={this.toggleDrawer}>
                <ChevronRightIcon />
              </IconButton>
              <span style={{padding:"0.5rem",fontSize:"large"}}>Comments</span>
            </div>
            <Divider />
            <Comments {...this.props} />
          </Drawer>
        ) : null}
        <div className="metrics-data-mobile">
          <span className="metrics-data-mobile-item">
            <ListAuthor
              {...this.props}
              profile={this.props.currentList.profile[0]}
            />
          </span>
          <span className="metrics-data-mobile-item">
            {this.props.promoters.length === 0 ||
            this.props.promoters.indexOf(this.props.user_name) === -1 ? (
              <i
                className="fa fa-arrow-circle-up"
                title="Promote"
                style={{ paddingBottom: "0" }}
                onClick={(e) => {
                  this.props.handlePromoteList(e, 1);
                }}
              />
            ) : (
              <i
                className="fa fa-arrow-circle-up"
                title="Promote"
                style={{ color: "#F27531", paddingBottom: "0" }}
                onClick={(e) => {
                  this.props.handlePromoteList(e, -1);
                }}
              />
            )}{" "}
            <span>{this.props.promotes ? this.props.promotes : "0"}</span>
          </span>
          <span className="metrics-data-mobile-item noOfViews-holder">
            <i
              className="fa fa-eye"
              title="views"
              style={{ paddingBottom: "0" }}
            ></i>{" "}
            <span className="noOfViews">
              {this.props.currentList.views
                ? this.props.currentList.views
                : "0"}
            </span>
          </span>
          <span
            className="metrics-data-mobile-item noOfViews-holder"
            data-toggle="modal"
            data-target="#collabModal"
          >
            <i
              className="fa fa-users"
              title="contributors"
              style={{ paddingBottom: "0", cursor: "pointer" }}
            ></i>
            <span className="metrics-data-mobile-item noOfViews">
              {this.props.currentList.collaborators
                ? this.props.currentList.collaborators.filter(
                    (collab) => collab.status === "accepted"
                  ).length
                : 0}
            </span>
          </span>
          {this.props.isEditable ? (
            <span className="metrics-data-mobile-item">
              <i
                className="fas fa-pen"
                title="Edit List"
                onClick={() => {
                  this.props.handleEditlist();
                }}
              ></i>
            </span>
          ) : null}
          <span className="metrics-data-mobile-item">
            <i
              className="fa fa-comment"
              title="List comments"
              style={{ paddingBottom: "0" }}
              onClick={this.handleShowListNotes}
            />
          </span>
          {/* TODO: add the drawer button to open the drawer for additional options */}
          <span className="metrics-data-mobile-item metrics-data-mobile-menu">
            <i
              className="fas fa-ellipsis-v"
              onClick={() => this.props.openOptionsDrawer()}
            ></i>
          </span>
          
        </div>
      </Fragment>
    );
  }
}

export default ListSettingMob;
