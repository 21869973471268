import React, { Component, Fragment } from "react";
import ListEditor from "./ListEditor";
// import CollabModal from "./collabModal";
import BookmarkMenu from "./BookmarkMenu";

class ShareList extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      bookmarks: [],
    };

    this.totalList = {};
  }

  componentDidMount() {
    const localBookMarks = localStorage.getItem(this.props.aid) || null;
    if (localBookMarks) {
      let parsedBookmarks = JSON.parse(localBookMarks);
      this.setState({
        bookmarks: parsedBookmarks,
      });
    }
  }

  setBookmarks = bookmarks => {
    this.setState({
      bookmarks: bookmarks,
    });
  };

  render() {
    return (
      <Fragment>
        <BookmarkMenu bookmarks={this.state.bookmarks} />
        <ListEditor
          setBookmarks={this.setBookmarks}
          totalList={this.totalList}
          mode={"share"}
          {...this.props}
          toggleCollabModal={this.toggleCollabModal}
        />
      </Fragment>
    );
  }
}

export default ShareList;
 