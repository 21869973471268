import {
  ASK_QUESTION_ERR,
  CLEAR_ASK_ERR, CLEAR_AUTH_ERR, LOGIN_USER_ERR,
  REGISTER_USER_ERR
} from "../constants/actionTypes";

const initialState = {
  authError: '',
  askError: '',
};

const api = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_ERR:
      return {
        ...state,
        authError: action.payload.error
      };
    case REGISTER_USER_ERR:
      return {
        ...state,
        authError: action.payload.error
      };
    case ASK_QUESTION_ERR:
      return {
        ...state,
        askError: action.payload,
      };
    case CLEAR_ASK_ERR:
      return {
        ...state,
        askError: "",
      };
    case CLEAR_AUTH_ERR:
      return {
        ...state,
        authError: ''
      };
    default:
      return state;
  }
};

export default api;