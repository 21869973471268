import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CATEGORIES } from "../../constants/categoryConstants";

class SideBar extends Component {
    constructor(props) {
	super(props);
    }

    render() {
	let renderCategories = [];
	CATEGORIES.forEach(item => {
	    renderCategories.push(
		<option
		    value={item}
		    style={{
			cursor: "pointer"
		    }}
		    onClick={this.props.handleCategoryChange}
		>
		    {item}
		</option>
	    );
	});

	return (
	    <div
		className={"col-12 col-md-3 col-lg-2 sideBar"}
		style={{ marginTop: "-1vw" }}
	    >
		<div className="row">
		    <div className="col-12">
			<div
			    className={"form-test"}
			    // onClick={this.props.handleCategoryChange}
			    style={{
				width: "81vw",
				margin: "1vw",
				marginLeft: "7.5vw",
				marginBottom: "5vw",
				borderRadius: "300px",
				justifyContent: "space-between",
				width: "80vw",
				backgroundColor: "rgba(150,0,0,0.1)",
				overflowX: "auto",
				whiteSpace: "nowrap",
				fontSize: "0.9em",
				//from mavi
				borderRadius: "10px",
				backgroundColor: "rgba(150,0,0,0.1)"
			    }}
			>
			    <div
				style={{
				    display: "flex",
				    flexDirection: "row",
				    padding: "0.5vw",
				    justifyContent: "space-between",
				    overflowX: "auto",
				    whiteSpace: "nowrap",
				    fontSize: "0.9em"
				}}
			    >
				{renderCategories}
			    </div>
			    <span> </span>
			</div>
		    </div>
		    {/* <div className="col-8" style={{ marginLeft: "2vw" }}>
			<div className="discover-nav-btn-container">
			<Link style={{ margin: "0.5vw 0vw" }} to={"/discover"}>
			<div className={"nav-btn first-nav-btn"}>List</div>
			</Link>
			<Link style={{ margin: "0.5vw 0vw" }} to={"/people"}>
			<div className={"nav-btn"}>People</div>
			</Link>
			<Link style={{ margin: "0.5vw 0vw" }} to={"/discover?live=true"}>
			<div className={"nav-btn"}>The Live List</div>
			</Link>
			<Link
			style={{ margin: "0.5vw 0vw" }}
			to={"/discover?aid=" + this.props.aid}
			>
			<div className={"nav-btn"}>Your List</div>
			</Link>
			<Link style={{ margin: "0.5vw 0vw" }} to={"#"}>
			<div className={"nav-btn"}>Subjects</div>
			</Link>
			<Link style={{ margin: "0.5vw 0vw" }} to={"/tags"}>
			<div className={"nav-btn"}>New Tags</div>
			</Link>
			<Link style={{ margin: "0.5vw 0vw" }} to={"#"}>
			<div className={"nav-btn"}>Products</div>
			</Link>
			<Link style={{ margin: "0.5vw 0vw" }} to={"#"}>
			<div className={"nav-btn"}>Live Release</div>
			</Link>
			<Link style={{ margin: "0.5vw 0vw" }} to={"#"}>
			<div className={"nav-btn"}>Checkbox Lists</div>
			</Link>
			<Link style={{ margin: "0.5vw 0vw" }} to={"#"}>
			<div className={"nav-btn"}>Innovation</div>
			</Link>
			</div>
			</div> */}
		</div>
	    </div>
	);
    }
}

export default SideBar;
