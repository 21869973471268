import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ErrorAlert from '../Nested/ErrorAlert';
import { getProfilePath } from '../../constants/pathConstants';


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  }
});

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aid: this.props.aid,
      oldpassword: '',
      password: '',
      rePassword: '',
      wrongPassword: false,
      showPassword: false,
      errorMessage: null,
      showAlert: false,
      alertCode: '',
      message: '',
      disabled: true
    };
  }

  showSnackBar = (code, msg) => {
    this.setState({
      showAlert: true,
      alertCode: code,
      message: msg
    });
  };

  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false
    });
  };

  showPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handleChange = name => async event => {
    await this.setState({
      [name]: event.target.value,
      errorMessage: null
    });
    this.checkInput(name);
  };

  reHandleChange = name => async event => {
    //hide red color when the input is empty.
    if (event.target.value == '') {
      this.setState({
        wrongPassword: false
      });
    }
    await this.setState({
      [name]: event.target.value,
      errorMessage: null
    });
    this.checkInput(name);
  };

  handleSubmit = async () => {
    let data = {
      aid: this.props.aid,
      oldpassword: this.state.oldpassword,
      newpassword: this.state.password
    };
    await this.props.changePassword(data, this.props.token);
    if (this.props.changePasswordMessage === 'success') {
      this.showSnackBar('success', 'Password changed successfully.');
    } else {
      // these errors are given by api: maybe
      // - server error
      // - network error
      // - account not found
      // - invalid parameters
      // - old password not matched (high chances)
      // use some div to displayy as they occur in this.state.errorMessage
      this.setState({
        wrongPassword: true,
        errorMessage: this.props.changePasswordMessage
      });
    }
  };

  checkInput = name => {
    this.setState({
      disabled: true
    });
    let pwdRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if (name === 'oldpassword') {
      if (this.state.oldpassword == '') {
        // make the old password box red
      } else {
        // make box normal
      }
    } else if (name === 'password') {
      if (this.state.oldpassword == '') {
        // make the new password box red
      } else if (!pwdRegex.test(String(this.state.password))) {
        // Password must contain atleast one capital letter, Special charactor and numerical value
      } else {
        // make box normal
      }
    } else {
      if (this.state.repassword == '') {
        // make the new password box red
      } else if (this.state.password !== this.state.rePassword) {
        // passwords do not match
        this.setState({
          wrongPassword: true,
          message: 'Passwords do not match'
        });
      } else {
        // make box normal
      }
    }
    if (
      this.state.oldpassword !== '' &&
      this.state.password &&
      this.state.password === this.state.rePassword &&
      pwdRegex.test(String(this.state.password))
    ) {
      this.setState({
        disabled: false
      });
    }
  };

  handleGoBack = () => {
    if (this.props.history.action === 'PUSH') {
      this.props.history.goBack();
    } else {
      this.props.history.push(getProfilePath(this.props.user_name));
    }
  };

  render() {
    return (
	<div className='profile-setting-container container-fluid'>


        <div className='profile-setting-box'>
          <div className='row'>
            <ErrorAlert
              AutoHideSnackbar={this.AutoHideSnackbar}
              showAlert={this.state.showAlert}
              code={this.state.alertCode}
              message={this.state.message}
            />
            <div className='col col-12 col-sm-12'>
              <FormControl style={{ width: '100%' }}>
                <InputLabel
                  error={this.state.errorMessage}
                  htmlFor='re-password'
                >
                  Old password
                </InputLabel>
                <Input
                  id='oldpassword'
                  label='Old password'
                  value={this.state.oldpassword}
                  type={this.state.showPassword ? 'text' : 'password'}
                  onChange={this.handleChange('oldpassword')}
                  margin='normal'
                  className='password-form'
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={this.showPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl style={{ width: '100%' }}>
                <InputLabel
                  error={this.state.errorMessage}
                  htmlFor='re-password'
                >
                  New password
                </InputLabel>
                <Input
                  id='password'
                  label='New password'
                  value={this.state.password}
                  type={this.state.showPassword ? 'text' : 'password'}
                  onChange={this.handleChange('password')}
                  margin='normal'
                  className='password-form'
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={this.showPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {/* <div className="forgot-password"><a href="#">Forgot password?</a></div> */}
              </FormControl>
              <FormControl style={{ width: '100%' }}>
                <InputLabel
                  error={this.state.errorMessage}
                  htmlFor='re-password'
                >
                  Confirm password
                </InputLabel>
                <Input
                  id='password'
                  label='Confirm password'
                  value={this.state.rePassword}
                  type={this.state.showPassword ? 'text' : 'password'}
                  onChange={this.reHandleChange('rePassword')}
                  margin='normal'
                  className='password-form'
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={this.showPassword}
                      >
                        {this.state.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {/* <div className="wrong-password" style={this.state.wrongPassword ? { display: 'block' } : { display: 'none' }}>Passwords do not match.</div> */}
                <div
                  className='wrong-password'
                  style={
                    this.state.wrongPassword
                      ? { display: 'block' }
                      : { display: 'none' }
                  }
                >
                  {this.state.errorMessage}
                </div>
              </FormControl>
              <div style={{display: "flex", justifyContent: "center", marginTop: "50px" }} >
                <button
                  className='btn-username btn-return'
                  onClick={this.handleGoBack}
                >
                  Return
                </button>
                <button
                  className='btn-username'
                  onClick={this.handleSubmit}
                  disabled={this.state.disabled}
                >
                  Save
                </button>
              </div>  
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  token: PropTypes.string.isRequired,
  aid: PropTypes.string.isRequired
};

export default withStyles(styles)(ChangePassword);
