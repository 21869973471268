import React, { Component } from 'react';
import { Line } from "react-chartjs-2";

class ActiveUsersChart extends Component {
  state = {
    data: {
      labels: ['-6m', '-5m', '-4m', '-3m', '-2m', '-1m', 'present'],
      datasets: [
        {
          lineTension: 0.05,
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderJoinStyle: 'round',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 20,
          data: []
        }
      ]
    }
  }

  componentWillReceiveProps(props) {
    var temp = this.state.data;
    temp.datasets[0].data = props.data;
    this.setState({data: temp});
  }

  render() {
    return (
      <React.Fragment>
        <span className = "chart-title">{this.props.chartName}</span>
        <Line 
        data={this.state.data} 
        options={{
          maintainAspectRatio: false, 
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                precision: 0,
                beginAtZero: true
              }
            }],
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 20,
              bottom: 0
            }
          }
        }}>
        </Line>
      </React.Fragment>
    )
  }

}
 
export default ActiveUsersChart;
