import React, { Component } from "react";

class Disclaimer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="footer-doc-container">
        <h1 className="footer-doc-head text-center">DISCLAIMER</h1>
        <div className="footer-doc-content">
          <p className="text-center">
            <strong>Listiee is a trademark of VIZACK Creations LLP.</strong>
          </p>

          <p>
            ALL THE INFORMATION ON THIS WEBSITE - WWW.LISTIEE.COM - IS PUBLISHED
            IN GOOD FAITH BY USERS AND MAKERS, WHICH IS FOR GENERAL INFORMATION
            PURPOSE ONLY.{" "}
          </p>
          <p>
            LISTIEE DOES NOT MAKE ANY WARRANTIES ABOUT THE COMPLETENESS,
            RELIABILITY AND ACCURACY OF THIS INFORMATION. ANY ACTION YOU TAKE
            UPON THE INFORMATION YOU FIND ON THIS WEBSITE (LISTIEE), IS STRICTLY
            AT YOUR OWN RISK.{" "}
          </p>
          <p>
            LISTIEE WILL NOT BE LIABLE FOR ANY DIRECT OR INDIRECT LOSSES AND/OR
            DAMAGES IN CONNECTION WITH THE USE OF OUR WEBSITE.{" "}
          </p>
          <p>
            INFORMATION PROVIDED ON THE SITE IS SUBJECT TO CHANGE BASED ON OUR
            TERMS OF USE AND PRIVACY POLICY.{" "}
          </p>
          <p>
            CONCERNED USER(S) MAY OR MAY NOT BE NOTIFIED DEPENDING ON THE
            SEVERITY OF THE ISSUE.
          </p>
          <p>
            FROM OUR WEBSITE, YOU MIGHT VISIT OTHER WEBSITES BY FOLLOWING
            HYPERLINKS TO THE EXTERNAL WEBSITES.{" "}
          </p>
          <p>
            WHILE WE STRIVE TO PROVIDE ONLY QUALITY LINKS TO USEFUL AND ETHICAL
            WEBSITES, WE HAVE NO CONTROL OVER THE CONTENT AND NATURE OF THESE
            SITES.{" "}
          </p>
          <p>
            THESE LINKS TO OTHER WEBSITES DO NOT IMPLY A RECOMMENDATION, BUT
            ONLY A REFERENCE TO HELP YOU WITH YOUR SEARCH.{" "}
          </p>
          <p>
            SITE OWNERS AND CONTENT MAY CHANGE WITHOUT NOTICE AND MAY OCCUR
            BEFORE WE HAVE THE OPPORTUNITY TO REMOVE A LINK WHICH MAY HAVE GONE
            'BAD'.{" "}
          </p>
          <p>
            WE APPRECIATE YOUR HELP TO NOTIFY US ON ANY BAD INFO OR BROKEN LINKS
            THAT YOU MAY DISCOVER.
          </p>
          <p>
            PLEASE BE ALSO AWARE THAT WHEN YOU LEAVE OUR WEBSITE, OTHER SITES
            MAY HAVE DIFFERENT PRIVACY POLICIES AND TERMS WHICH ARE BEYOND OUR
            CONTROL.{" "}
          </p>
          <p>
            IF YOU ARE CONCERNED, READ RESPECTIVE PRIVACY POLICIES AND "TERMS OF
            SERVICE" BEFORE ENGAGING IN ANY BUSINESS OR UPLOADING ANY
            INFORMATION ON THESE WEBSITES.
          </p>
          <p>
            The above disclaimer applies to any damages, liability or injuries
            caused by any failure of performance, error, omission, interruption,
            deletion, defect, delay in operation or transmission, computer
            virus, communication line failure, theft or destruction of or
            unauthorized access to, alteration of, or use, whether for breach of
            contract, tort, negligence or any other cause of action.
          </p>

          <p>
            <b>
              We reserve the right to do any of the following, at any time,
              without notice:
            </b>{" "}
          </p>

          <ol>
            <li>
              To modify, suspend or terminate operation of or access to the
              Site, or any portion of the Site, for any reason;
            </li>
            <li>
              To modify or change the Site, or any portion of the Site, and any
              applicable policies or terms; and
            </li>
            <li>
              To interrupt the operation of the Site, or any portion of the
              Site, as necessary to perform routine or non-routine maintenance,
              error correction, or other changes.
            </li>
          </ol>
          <br />
          <br />
          <p>
            <b>
              If you require any more information or have any questions about
              our site's disclaimer, please feel free to contact us by email at:
              <br />
              <a href="mailto:hello@listiee.com">hello @ listiee.com </a>
            </b>
          </p>
        </div>
      </div>
    );
  }
}

export default Disclaimer;
