import React from "react";
import { NavLink } from "react-router-dom";
import ListeeLogo from "../assets/logo2.png";
import "./styles/blognav.css";
import Tooltip from "@material-ui/core/Tooltip";

function BlogNav() {
  return (
    <div className="blog_nav">
      <div className="blog_nav_title">
        <Tooltip title="Go to blog" aria-label="Discover">
          <NavLink to="/blog">
            <img className="blog_nav_logo" src={ListeeLogo} alt="Logo" />
          </NavLink>
        </Tooltip>
        <Tooltip title="Go to blog" aria-label="Discover">
          <NavLink to="/blog">
            <p className="blog_title">BLOG</p>
          </NavLink>
        </Tooltip>
      </div>

      <div className="nav_link">
        <Tooltip title="Want to publish a blog ?" aria-label="Discover">
          <NavLink to="/publishBlog">
            <p className="blog_nav_link">Publish Blog</p>
          </NavLink>
        </Tooltip>
        <Tooltip title="Go to discover" aria-label="Discover">
          <NavLink to="/discover">
            <p className="blog_nav_link">Discover</p>
          </NavLink>
        </Tooltip>
        <div className="dropdown">
          <p className="dropbtn">Categories</p>
          <div className="dropdown-content">
            <Tooltip title="View Travel blogs" aria-label="Discover">
              <NavLink to="#">Travel</NavLink>
            </Tooltip>
            <Tooltip title="View Travel blogs" aria-label="Discover">
              <NavLink to="#">Education</NavLink>
            </Tooltip>
            <Tooltip title="View Travel blogs" aria-label="Discover">
              <NavLink to="#">Personal</NavLink>
            </Tooltip>
            <Tooltip title="View Travel blogs" aria-label="Discover">
              <NavLink to="#">Development</NavLink>
            </Tooltip>
            <Tooltip title="View Travel blogs" aria-label="Discover">
              <NavLink to="#">Technology</NavLink>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogNav;
