import React, { Component } from 'react';
import CollabModal from './collabModal';
import ErrorAlert from './ErrorAlert';

class CollabBtnComponent extends Component {
  state = {
    showAlert: false,
    alertMessage: '',
    alertCode: null
  };

  //Snackbar auto-hide method
  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false
    });
  };

  showSnackBar = (message, code) => {
    this.setState({
      showAlert: true,
      alertMessage: message,
      alertCode: code
    });
  };

  render() {
    return (
      <div 
      // style={{ alignSelf: 'flex-end' }}
      >
        <ErrorAlert
          AutoHideSnackbar={this.AutoHideSnackbar}
          showAlert={this.state.showAlert}
          message={this.state.alertMessage}
          status={this.state.alertCode}
        />
        <button
          className='collab_btn_share'
          onClick={this.props.toggleCollabModal}
          onFocus={(event)=>{event.stopPropagation()}}
          title='Click to add collaborators'
        >
          <i className='fas fa-users' />
        </button>
      </div>
    );
  }
}

export default CollabBtnComponent;
