import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import axios from "axios";
import {
  SAVE_LIST_VERSION_THREE,
  UPLOAD_BOOKMARK_API,
} from "../../constants/apiConstants";
import { getEditPath } from "../../constants/pathConstants";
import ErrorAlert from "./ErrorAlert";
import BookmarksIcon from '@material-ui/icons/Bookmarks';

import swal from "sweetalert";
export default class ImportBookmarkModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isImportBookmarkModal: false,
      files: [
        {
          source: "index.html",
          options: {
            type: "local",
          },
        },
      ],

      selectedFile: null,
      showAlert: false,
      alertMessage: "",
      alertCode: null,
    };
  }

  //Snackbar auto-hide method
  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false,
    });
  };

  showSnackBar = (message, code) => {
    this.setState({
      showAlert: true,
      alertMessage: message,
      alertCode: code,
    });
  };

  toggleImportBookmarkModal = () => {
    this.setState({ isImportBookmarkModal: !this.state.isImportBookmarkModal });
  };

  handleInit = () => {
    console.log("FilePond instance has initialised", this.pond);
  };

  //TODO: This is a temporary solution of uploading bookmark. Later we will modify API to accept only bookmark file and not lsid

  handleFileInputChange = e => {
    this.setState({ selectedFile: e.target.files[0] });
  };

  creatBlankList = async () => {
    let lsid = null;
    let slug = null;
    const data = {};
    data["aid"] = this.props.aid;
    data["category"] = "Other";
    data["name"] = "Imported browser bookmark";
    data["slug"] = "imported-browser-bookmark";
    data["tags"] = [];
    data["live"] = false;
    data["public"] = true;
    data["close"] = true;
    data["conscious"] = false;
    data["sensitive"] = false;
    data["listItemDetails"] = [
      {
        list_name: "All bookmarks",
        description: "",
        highlights: [],
        about: [],
        sections: [],
        links: "",
      },
    ];

    const reqOptions = {
      method: "POST",
      url: SAVE_LIST_VERSION_THREE,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "x-access-token": this.props.token,
      },
    };

    await axios(reqOptions)
      .then(res => {
        lsid = res.data.data._id;
        slug = res.data.data.slug;
      })
      .catch(error => {
        console.error(error);
      });

    return { lsid, slug };
  };

  uploadBookmarkFile = async (lsid, slug) => {
    let formData = new FormData();
    formData.append("browserBookmarkFile", this.state.selectedFile);
    formData.append("aid", this.props.aid);
    formData.append("lsid", lsid);

    const reqOptions = {
      method: "POST",
      url: UPLOAD_BOOKMARK_API,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": this.props.token,
        unique_file_name: this.props.aid,
      },
    };

    console.log("upload start... with config=", reqOptions);
    let response = null;
    await axios(reqOptions)
      .then(function (resp) {
        // console.log(resp);
        response = resp;
      })
      .catch(function (error) {
        console.log(error);
      });

    await this.handleAfterUpload(response, lsid, slug);
    // this.props.history.push("/");
  };

  handleFileUpload = async e => {
    if (this.state.selectedFile !== null) {
      this.setState({ isLoading: false });
      this.toggleImportBookmarkModal();
      // const {lsid, slug} = await this.creatBlankList();
      await this.uploadBookmarkFile();
    } else {
      this.showSnackBar(
        "Please select your exported bookmark file before uploading...",
        "error"
      );
    }
  };

  handleFileUploadInterrupt = e => {
    this.showSnackBar(
      "Please wait till your file is uploaded successfully...",
      "info"
    );
  };

  storeUserBookmarkFile = data => {
    localStorage.setItem(this.props.aid, JSON.stringify(data));
  };

  handleAfterUpload = async (response, lsid, slug) => {
    if (response && response.status === 200) {
      this.showSnackBar("Upload successfull...", "success");
      this.storeUserBookmarkFile(response.data.data);
      this.props.setBookmarks(response.data.data);
    } else {
      this.showSnackBar("Something went wrong", "error");
      setTimeout(() => {
        this.props.history.push("/");
      }, 3000);
    }
  };

  render() {
    const { isImportBookmarkModal } = this.state;
    // console.log(this.state);

    return (
      <div>
        <ErrorAlert
          AutoHideSnackbar={this.AutoHideSnackbar}
          showAlert={this.state.showAlert}
          message={this.state.alertMessage}
          status={this.state.alertCode}
        />
        <button
          className="btn bookmarks"
          onClick={this.toggleImportBookmarkModal}
          title="Import your browser bookmark"
        >
          <BookmarksIcon fontSize="small"/>
          <span className="btn-text">Import Bookmarks</span>
        </button>
        <Modal
          isOpen={isImportBookmarkModal}
          toggle={this.toggleImportBookmarkModal}>
          <ModalHeader toggle={this.toggleImportBookmarkModal}>
            Upload your bookmark file
          </ModalHeader>
          <ModalBody>
            <div>
              <ol>
                <li>Go to your browser's bookmark manager</li>
                <li>Export your browser bookmark into a file</li>
                <li>Upload the exported file here</li>
              </ol>
            </div>
            <br />

            {/* TODO: implement filepond after bookmark upload API is modified as per the need */}

            {/* <FilePond
              ref={ref => (this.pond = ref)}
              files={this.state.files}
              allowMultiple={false}
              instantUpload={false}
              oninit={() => this.handleInit()}
              name="browserBookmarkFile"
              server={
                {
                  url: UPLOAD_BOOKMARK_API,
                  process: {
                    headers: {
                      'x-access-token': this.props.token
                    },
                  }
                }
              }
              onupdatefiles={fileItems => {
                // Set currently active file objects to this.state
                this.setState({
                  files: fileItems.map(fileItem => fileItem.file)
                });
              }}
            /> */}

            <input
              type="file"
              accept=".html"
              onChange={this.handleFileInputChange}
            />
          </ModalBody>
          <ModalFooter>
            {this.state.isLoading ? (
              <button
                className="btn btn-success"
                onClick={this.handleFileUploadInterrupt}>
                <i className={"fas fa-spinner"} /> Uploading...
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={this.handleFileUpload}>
                Upload
              </button>
            )}
            <button
              className="btn btn-primary"
              onClick={this.toggleImportBookmarkModal}>
              Cancel
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
