import React, { Component, Fragment } from "react";
import Tilt from "react-parallax-tilt";
import DiscoverDropdown from "./Nested/DiscoverDropdown";
import ListShareIcons from "./Nested/ListShareIcons";
import { confirmDeletion } from "./Utils/helperFunctions";
import CircularProgress from "@material-ui/core/CircularProgress";
import UndoRoundedIcon from "@material-ui/icons/UndoRounded";
import * as timeago from "timeago.js";
import {
  GET_SHORT_URL_CODE_API,
  FOLLOW_UNFOLLOW_BOOKMARK_API,
} from "../constants/apiConstants";
import { LOGIN_PATH } from "../constants/pathConstants";
import axios from "axios";
import { Typography, Box, IconButton } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { CardBody } from "reactstrap";

class ListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShareMenuOpen: false,
      newListItem: "",
      noOfQuickAddedItems: 0,
      noTotalItems: 0,
      time: 0,
      items: [],
      quickItemInput: "",
      shortenedCode: "",
      plusOneVisible: true,
      bookmarked: false,
      followerPanel: false,
    };
  }

  componentDidMount() {
    this.setState({
      items: this.props.listItem.item,
      noTotalItems: this.props.listItem.totalLsItems,
    });
    if (this.props.allUserBookmarks.includes(this.props.listItem._id)) {
      this.setState({
        bookmarked: true,
      });
    }
  }

  toggleShareMenu = e => {
    if (e) e.preventDefault();
    this.setState({
      isShareMenuOpen: !this.state.isShareMenuOpen,
    });
  };

  hideShareMenu = () => {
    this.setState({
      isShareMenuOpen: false,
    });
  };

  // showShareMenu = () => {
  //   this.setState({
  //     isShareMenuOpen: true,
  //   });
  // };

  // showTags = () => {
  //   this.setState({
  //     showTags: true,
  //   });
  // };

  //Handle note button drop action
  noteBtnDragOver = e => {
    e.preventDefault();
  };

  noteBtnDragEnter = e => {
    // e.preventDefault();
    e.stopPropagation();
    e.target.classList.add("note-btn-hovered");
  };

  noteBtnDragLeave = e => {
    e.target.classList.remove("note-btn-hovered");
  };

  noteBtnDrop = (e, data) => {
    e.target.classList.remove("note-btn-hovered");
    this.props.handleNoteButtonDrop(data);
  };

  handleUndoClick = async () => {
    let data = {
      aid: this.props.aid,
      lsid: this.props.listItem._id,
      lsitid: this.state.items[this.state.items.length - 1]._id,
    };

    await this.props.deleteListItem(data, this.props.token);
    if (this.props.removedListItem) {
      this.props.snackBar("success", `Listitem removed successfully!!`);
      let presentItems = this.state.items;
      presentItems.splice(this.state.items.length - 1, 1);
      this.setState({
        newListItem: "",
        noOfQuickAddedItems: this.state.noOfQuickAddedItems - 1,
        items: presentItems,
        time: 0,
      });
    }
  };

  handleClickOnFolder = e => {
    e.stopPropagation();
    this.props.handleClickOnFolder();
  };
  quickItemInputChange = e => {
    this.setState({
      quickItemInput: e.target.value,
    });
  };
  handleQuickItemAdd = async e => {
    e.preventDefault();
    if (e.keyCode === 13 && this.state.quickItemInput !== "") {
      // console.log(
      //   "Item to be added",
      //   this.state.quickItemInput,
      //   this.state.noTotalItems
      // );
      this.setState({
        noTotalItems: this.state.noTotalItems + 1,
        items: [
          ...this.state.items,
          {
            list_name: this.state.quickItemInput,
          },
        ],
      });
      let data = {
        aid: this.props.aid,
        lsid: this.props.listItem._id,
        listItem: {
          list_name: this.state.quickItemInput,
          description: "",
          highlights: [],
          about: [],
          sections: [],
          links: "",
        },
      };
      await this.props.addListItem(data, this.props.token).then(async () => {
        if (this.props.addListItemMessage !== "success")
          this.props.snackBar(
            "error",
            `This List is private and can't be edited`
          );
        else this.props.snackBar("success", `Listitem added successfully!!`);
      });
      this.setState({
        quickItemInput: "",
      });
    } else if (this.state.quickItemInput === "" && e.keyCode === 13) {
      this.props.snackBar("error", `List Item Empty!!`);
    }
  };

  handleQuickUndo = async () => {
    let data = {
      aid: this.props.aid,
      lsid: this.props.listItem._id,
      lsitid: this.props.listItems[this.props.listItems.length - 1]._id,
    };

    await this.props.deleteListItem(data, this.props.token);
    if (this.props.removedListItem) {
      this.props.snackBar("success", `Listitem removed successfully!!`);
      let presentItems = this.state.items;
      presentItems.splice(this.state.items.length - 1, 1);
      this.setState({
        noTotalItems: this.state.noTotalItems - 1,
        quickItemInput: "",
        noOfQuickAddedItems: this.state.noOfQuickAddedItems - 1,
        items: presentItems,
        time: 0,
      });
    }
  };

  formatLastUpdateTime = date => {
    return timeago.format(date);
  };

  setShortenedCode = code => {
    if (!this.state.shortenedCode) {
      this.setState({ shortenedCode: code });
    }
  };
  //add First List Item To Bookmark For Plus One
  followByBookmarking = async event => {
    this.setState({
      bookmarked: !this.state.bookmarked,
    });
    if (!this.props.token) {
      this.props.history.push(LOGIN_PATH, {
        redirect_to: this.props.location.pathname,
      });
      return;
    }
    event.stopPropagation();
    const { token, aid } = this.props;
    // const lsitid = this.props.listItem.li
    const lsid = this.props.listItem._id;
    const data = {
      aid,
      lsid,
    };
    let options = {
      headers: {
        "x-access-token": this.props.token,
      },
    };
    try {
      let response = await axios.post(
        FOLLOW_UNFOLLOW_BOOKMARK_API,
        data,
        options
      );
      console.log(response);
      if (response.status !== 200) {
        this.setState({
          bookmarked: !this.state.bookmarked,
        });
      }
      await this.props.fetchListv4(
        {
          page: this.props.page,
          query: this.props.query,
        },
        token
      );
    } catch (e) {
      this.setState({
        bookmarked: !this.state.bookmarked,
      });
      console.log(e);
    } 
  };
  plusOneButton = () => {
    if (!!this.props.token) {
      return (
        <button
          className="plusOne scale-on-hover"
          title="Follow the list! It will be added to your profile"
          style={{
            border: this.state.bookmarked ? "3px solid" : "1px solid",
            backgroundColor: this.state.bookmarked ? "#0096FF" : "#fff",
            color: this.state.bookmarked ? "#fff" : "#0096FF",
          }}
          onClick={this.followByBookmarking}>
          {this.state.bookmarked ? (
            // <CircularProgress size={19} />
            <span
            className="beforeClick"
              style={{ height: "19px", fontWeight: "bolder", width: "19px"
              
              }}>
              +1
            </span>
          ) : (
            // <LibraryAddIcon size={19}/>
            <span style={{ height: "19px", width: "19px"  }} className="afterClick">+1</span>
          )}
        </button>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <Fragment>
        <Modal
          open={this.state.followerPanel}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClose={() => {
            this.setState({
              followerPanel: !this.state.followerPanel,
            });
          }}>
          <div
            style={{
              maxHeight: "20rem",
              width: "28vw",
              minWidth: "300px",
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "2rem",
            }}>
            <div style={{ overflowY: "scroll", height: "16rem" }}>
              <h4>Contributors</h4>
              {this.props.listItem.contributors.map(contrib => {
                return (
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "0.5rem",
                      justifyContent: "space-between",
                    }}>
                    <div style={{ display: "flex" }}>
                      <img
                        style={{ height: "1em", marginRight: "1rem" }}
                        src={contrib.avatar}
                        
                      />
                      <div>{contrib.name}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "0.8rem",
                      }}>{`@${contrib.user_name}`}</div>
                  </div>
                );
              })}
              <h4>Followers</h4>
              {this.props.listItem.followers.map(follower => {
                return (
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "0.5rem",
                      justifyContent: "space-between",
                    }}>
                    <div style={{ display: "flex" }}>
                      <img
                        style={{ height: "1em", marginRight: "1rem" }}
                        src={follower.avatar}
                      />
                      <div>{follower.name}</div>
                    </div>
                    <div
                      style={{
                        fontSize: "0.8rem",
                      }}>{`@${follower.user_name}`}</div>
                  </div>
                );
              })}
            </div>
            <div></div>
          </div>
        </Modal>
        <Tilt tiltMaxAngleX={3} tiltMaxAngleY={3} scale={1.03}>
          {this.props.listItem.listType !== "ask" ? (
            <Fragment>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "1.5em",
                  backgroundColor: "whitesmoke",
                  padding: "1rem",
                  borderRadius: "6px",
                  margin: "0 7px",
                }}>
                <div className={"author category"}>
                  {this.props.listItem.category}
                </div>
                <div
                  style={{ display: "flex", gap: "1em" }}
                  className={"author"}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    {this.props.listItem.profile.length === 0 ? (
                      ""
                    ) : (
                      <img
                        style={{
                          height: "1.3em",
                          borderRadius: "50%",
                          marginRight: "0.5em",
                        }}
                        src={this.props.listItem.profile[0].avatar}></img>
                    )}
                    <span
                      className={"author"}
                      title={`${
                        this.props.listItem.profile.length !== 0
                          ? this.props.listItem.profile[0].user_name
                          : "deleted"
                      }`}>
                      {this.props.listItem.profile.length === 0
                        ? "deleted"
                        : this.props.listItem.profile.map(profile =>
                            profile.user_name.slice(0, 12)
                          )}
                    </span>
                  </div>
                  <div
                    className={"author"}
                    onClick={() => {
                      this.setState({
                        followerPanel: !this.state.followerPanel,
                      });
                    }}>
                    {this.props.listItem.bookmarksCount &&
                    this.props.listItem.bookmarksCount > 10
                      ? "+10"
                      : `+${this.props.listItem.bookmarksCount}`}
                  </div>
                </div>
              </div>
              <CardBody
                onClick={() => {
                  console.log("NOT AN ASKED LIST");
                  this.props.setSelectedList(false);
                }}
                id={this.props.id}
                onMouseLeave={this.props.toggleDetailVisibility}
                className={"list_card"}>
                {/* {!this.props.allUserBookmarks.includes(
                  this.props.listItem._id
                ) && (
                  <button
                    className="plusOne"
                    style={{
                      display:
                        this.props.token === "" ||
                        !this.props.token ||
                        !this.state.plusOneVisible
                          ? "none"
                          : "block",
                    }}
                    onClick={this.followByBookmarking}
                  >
                    {this.state.bookmarking ? (
                      <CircularProgress size={19} />
                    ) : (
                      <span style={{ height: "19px", width: "19px" }}>+1</span>
                    )}
                  </button>
                )} */}
                {this.plusOneButton()}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    float: "left",
                    flexWrap: "wrap-reverse",
                  }}
                  onClick={this.props.activeList}>
                  <img src={"/assets/lists.png"} alt="" className="list-icon" />
                  {/* totalLsItems gives the total no of items present in this list.
            The fetch_list_v3 API only returs first 5 items for efficienty so we can't count them using item.length  */}
                  <small
                    className="total-num"
                    style={{ color: "gray" }}
                    title="Number of list items in this list">
                    {this.state.noTotalItems}
                  </small>
                </div>
                <div className="inner-wrapper" style={{ marginLeft: "1.5vw" }}>
                  <Box mx={1}>
                    {" "}
                    <p
                      className="list-card-title"
                      onDragEnter={this.noteBtnDragEnter}
                      onDragLeave={this.noteBtnDragLeave}
                      onDragOver={this.noteBtnDragOver}
                      onDrop={e =>
                        this.noteBtnDrop(e, {
                          type: "list",
                          data: {
                            url: window.location.pathname,
                            listName: "Discover Page",
                            text: this.props.listItem.name,
                          },
                        })
                      }>
                      {this.props.listItem.name}
                    </p>
                  </Box>
                  {this.props.listItem.listType !== "ask" ? (
                    <Box ml={1}>
                      <ol className={"list"}  >
                        {this.props.listItem.item
                          .slice(0, 5)
                          .map((item, inner_index) => {
                            if (inner_index <= 4)
                              if (
                                item.list_name &&
                                item.list_name.length >= 80
                              ) {
                                return (
                                  <li
                                    style={{ lineHeight: "1.5" }}
                                    key={this.props.index + inner_index}
                                    onDragEnter={this.noteBtnDragEnter}
                                    onDragLeave={this.noteBtnDragLeave}
                                    onDragOver={this.noteBtnDragOver}
                                    onDrop={e =>
                                      this.noteBtnDrop(e, {
                                        type: "listItem",
                                        data: {
                                          listName: this.props.listItem.name,
                                          text: item.list_name,
                                          url: window.location.pathname,
                                        },
                                      })
                                    }>
                                    {item.list_name.slice(0, 45)}
                                    <span
                                      className="dot"
                                      style={{
                                        margin: "0vw 0.1vw 0vw 0.2vw",
                                        width: "3px",
                                        height: "3px",
                                        backgroundColor: "#03A9FD",
                                        display: "inline-block",
                                        borderRadius: "50%",
                                      }}
                                    />
                                    <span
                                      className="dot"
                                      style={{
                                        margin: "0vw 0.1vw",
                                        width: "3px",
                                        height: "3px",
                                        backgroundColor: "#FE2320",
                                        display: "inline-block",
                                        borderRadius: "50%",
                                      }}
                                    />
                                    <span
                                      className="dot"
                                      style={{
                                        margin: "0vw 0.1vw",
                                        width: "3px",
                                        height: "3px",
                                        backgroundColor: "#28C538",
                                        display: "inline-block",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  </li>
                                );
                              } else {
                                //console.log('happier');
                                return (
                                  <Typography
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                    key={this.props.index + inner_index}
                                    onDragEnter={this.noteBtnDragEnter}
                                    onDragLeave={this.noteBtnDragLeave}
                                    onDragOver={this.noteBtnDragOver}
                                    onDrop={e =>
                                      this.noteBtnDrop(e, {
                                        type: "listItem",
                                        data: {
                                          listName: this.props.listItem.name,
                                          text: item.list_name,
                                          url: window.location.pathname,
                                        },
                                      })
                                    }
                                    noWrap
                                    component="li"
                                    variant="body2"
                                    style={{ textTransform: "capitalize" }}>
                                    {item.list_name}
                                  </Typography>
                                );
                              }
                          })}
                        {this.props.listItem.item.length > 4 ? (
                          <li
                            style={{
                              listStyleType: "none",
                              color: "#01397d",
                              fontSize: "13px",
                              fontWeight: "normal",
                            }}>
                            More...
                          </li>
                        ) : null}
                      </ol>
                    </Box>
                  ) : null}
                  <div className="LastUpdate">
                    <span>
                      <i className="fa fa-eye" title="Views">
                        {this.props.listItem.stats.length
                          ? this.props.listItem.stats[0].views
                          : "0"}
                      </i>
                    </span>
                    <span title="Last updated">
                      <img src="assets/LastUpdated.svg"></img>
                      <span>
                        {this.formatLastUpdateTime(
                          this.props.listItem.lastUpdated &&
                            this.props.listItem.lastUpdated.at
                            ? this.props.listItem.lastUpdated.at
                            : this.props.listItem.updatedAt
                        )}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="add-folder">
                  <IconButton
                    size="small"
                    onClick={this.handleClickOnFolder}
                    style={{ boxShadow: "none" }}>
                    <DiscoverDropdown
                      specificID={this.props.index}
                      listID={this.props.listItem._id}
                      toggleShareMenu={this.toggleShareMenu}
                      hideShareMenu={this.hideShareMenu}
                      handleClickOnFolder={this.props.handleClickOnFolder}
                      listTitle={this.props.listItem.slug}
                      addListToFolder={this.props.addListToFolder}
                      folders={this.props.fetchedFolders}
                      aid={this.props.aid}
                      token={this.props.token}
                      listOwner={
                        this.props.listItem.profile.map(
                          profile => profile.aid
                        )[0]
                      }
                      listType={this.props.listItem.listType}
                      history={this.props.history}
                      showLoginModal={this.props.showLoginModal}
                      fetchFolders={this.props.fetchFolders}
                      setShortenedCode={this.setShortenedCode}
                      {...this.props}
                    />
                  </IconButton>
                </div>
                <ListShareIcons
                  isShareMenuOpen={this.state.isShareMenuOpen}
                  handleClickOnFolder={this.props.handleClickOnFolder}
                  hideShareMenu={this.hideShareMenu}
                  shareBaseUrl={`${GET_SHORT_URL_CODE_API}${this.state.shortenedCode}`}
                  listName={this.props.listItem.name}
                />
                {!this.state.isShareMenuOpen &&
                (typeof this.props.listItem.close === "undefined" ||
                  this.props.listItem.close !== true) ? (
                  <Box
                    className="addnewitem"
                    onClick={e => e.stopPropagation()}>
                    <Box>
                      <input
                        disabled={!this.props.token ? true : false}
                        type="text"
                        value={this.state.quickItemInput}
                        placeholder={`${
                          !this.props.token ? "Login To Add" : "Add a new item"
                        }`}
                        onFocus={e => (e.target.placeholder = "")}
                        onBlur={e => (e.target.placeholder = "Nothing added")}
                        onKeyUp={e => {
                          this.handleQuickItemAdd(e);
                        }}
                        onChange={e => {
                          this.quickItemInputChange(e);
                        }}
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                      />
                      {this.state.noOfQuickAddedItems > 0 ? (
                        <div
                          className="undo-btn-wrapper"
                          onClick={() => this.handleQuickUndo()}>
                          <UndoRoundedIcon />
                        </div>
                      ) : null}
                    </Box>
                  </Box>
                ) : null}
              </CardBody>
            </Fragment>
          ) : null}
          {/* ask card */}
          {this.props.listItem.listType === "ask" ? (
            <Fragment>
              <div style={{ marginTop: "-1rem" }}>
                <div className="Asked-Or-Not">Asked</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "1.5em",
                    backgroundColor: "#FA8072",
                    padding: "1rem",
                    borderRadius: "6px",
                    margin: "0 7px",
                  }}>
                  <div className={"author category-ask"}>
                    {this.props.listItem.category}
                  </div>
                  <div
                    style={{ display: "flex", gap: "1em" }}
                    className={"author"}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {" "}
                      {this.props.listItem.profile.length === 0 ? (
                        ""
                      ) : (
                        <img
                          style={{
                            height: "1.3em",
                            borderRadius: "50%",
                            marginRight: "0.5em",
                          }}
                          src={this.props.listItem.profile[0].avatar}></img>
                      )}
                      <span
                        className={"author"}
                        title={`${
                          this.props.listItem.profile.length !== 0
                            ? this.props.listItem.profile[0].user_name
                            : "deleted"
                        }`}>
                        {this.props.listItem.profile.length === 0
                          ? "deleted"
                          : this.props.listItem.profile.map(profile =>
                              profile.user_name.slice(0, 12)
                            )}
                      </span>
                    </div>
                    <div className={"author"}>
                      {this.props.listItem.bookmarksCount &&
                      this.props.listItem.bookmarksCount > 10
                        ? "10+"
                        : `+${this.props.listItem.bookmarksCount}`}
                    </div>
                  </div>
                </div>
                <CardBody
                  onClick={() => {
                    console.log("clicked on an ask list");
                    this.props.setSelectedList(true);
                  }}
                  id={this.props.id}
                  onMouseLeave={this.props.toggleDetailVisibility}
                  className={"ask_card"}>
                  {this.plusOneButton()}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      float: "left",
                      flexWrap: "wrap-reverse",
                    }}
                    onClick={this.props.activeList}>
                    <img
                      src={"/assets/lists.png"}
                      alt=""
                      className="list-icon"
                    />
                    {/* totalLsItems gives the total no of items present in this list.
                                The fetch_list_v3 API only returs first 5 items for efficienty so we can't count them using item.length  */}
                    <small
                      className="total-num"
                      style={{ color: "gray" }}
                      title="Number of list items in this list">
                      {this.state.noTotalItems}
                    </small>
                  </div>
                  <div
                    className="inner-wrapper"
                    style={{ marginLeft: "1.5vw" }}>
                    <p
                      className="list-card-title"
                      onDragEnter={this.noteBtnDragEnter}
                      onDragLeave={this.noteBtnDragLeave}
                      onDragOver={this.noteBtnDragOver}
                      onDrop={e =>
                        this.noteBtnDrop(e, {
                          type: "list",
                          data: {
                            url: window.location.pathname,
                            listName: "Discover Page",
                            text: this.props.listItem.name,
                          },
                        })
                      }>
                      {this.props.listItem.name}
                    </p>
                  </div>
                  <div
                    className="add-folder"
                    onClick={this.props.handleClickOnFolder}>
                    <DiscoverDropdown
                      specificID={this.props.index}
                      listID={this.props.listItem._id}
                      toggleShareMenu={this.toggleShareMenu}
                      hideShareMenu={this.hideShareMenu}
                      handleClickOnFolder={this.props.handleClickOnFolder}
                      listTitle={this.props.listItem.slug}
                      addListToFolder={this.props.addListToFolder}
                      folders={this.props.fetchedFolders}
                      aid={this.props.aid}
                      token={this.props.token}
                      listOwner={
                        this.props.listItem.profile.map(
                          profile => profile.aid
                        )[0]
                      }
                      listType={this.props.listItem.listType}
                      history={this.props.history}
                      showLoginModal={this.props.showLoginModal}
                      fetchFolders={this.props.fetchFolders}
                      setShortenedCode={this.setShortenedCode}
                      {...this.props}
                    />
                  </div>
                  <ListShareIcons
                    isShareMenuOpen={this.state.isShareMenuOpen}
                    handleClickOnFolder={this.props.handleClickOnFolder}
                    hideShareMenu={this.hideShareMenu}
                    shareBaseUrl={`${GET_SHORT_URL_CODE_API}${this.state.shortenedCode}`}
                  />
                  <ListShareIcons
                    isShareMenuOpen={this.state.isShareMenuOpen}
                    handleClickOnFolder={this.props.handleClickOnFolder}
                    hideShareMenu={this.hideShareMenu}
                    shareBaseUrl={`${GET_SHORT_URL_CODE_API}${this.state.shortenedCode}`}
                    listName={this.props.listItem.name}
                  />
                  {!this.state.isShareMenuOpen ? (
                    <Fragment>
                      <div
                        className="addnewitem"
                        onClick={e => e.stopPropagation()}>
                        <img
                          src={"/assets/lists.png"}
                          alt=""
                          className="list-icon"
                        />
                        {/* totalLsItems gives the total no of items present in this list.
                                The fetch_list_v3 API only returs first 5 items for efficienty so we can't count them using item.length  */}
                        <small
                          className="total-num"
                          style={{ color: "gray" }}
                          title="Number of list items in this list">
                          {this.state.noTotalItems}
                        </small>
                      </div>
                      <div
                        className="inner-wrapper"
                        style={{ marginLeft: "1.5vw" }}>
                        {this.props.listItem.listType === "ask" ? (
                          <ol className={"list"}>
                            {this.props.listItem.item
                              .slice(0, 5)
                              .map((item, inner_index) => {
                                if (inner_index <= 4)
                                  if (
                                    item.list_name &&
                                    item.list_name.length >= 80
                                  ) {
                                    return (
                                      <li
                                        style={{ lineHeight: "1.5" }}
                                        key={this.props.index + inner_index}
                                        onDragEnter={this.noteBtnDragEnter}
                                        onDragLeave={this.noteBtnDragLeave}
                                        onDragOver={this.noteBtnDragOver}
                                        onDrop={e =>
                                          this.noteBtnDrop(e, {
                                            type: "listItem",
                                            data: {
                                              listName: this.props.listItem
                                                .name,
                                              text: item.list_name,
                                              url: window.location.pathname,
                                            },
                                          })
                                        }>
                                        {item.list_name.slice(0, 75)}
                                        <span
                                          className="dot"
                                          style={{
                                            margin: "0vw 0.1vw 0vw 0.2vw",
                                            width: "3px",
                                            height: "3px",
                                            backgroundColor: "#03A9FD",
                                            display: "inline-block",
                                            borderRadius: "50%",
                                          }}
                                        />
                                        <span
                                          className="dot"
                                          style={{
                                            margin: "0vw 0.1vw",
                                            width: "3px",
                                            height: "3px",
                                            backgroundColor: "#FE2320",
                                            display: "inline-block",
                                            borderRadius: "50%",
                                          }}
                                        />
                                        <span
                                          className="dot"
                                          style={{
                                            margin: "0vw 0.1vw",
                                            width: "3px",
                                            height: "3px",
                                            backgroundColor: "#28C538",
                                            display: "inline-block",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      </li>
                                    );
                                  } else {
                                    //console.log('happier');
                                    return (
                                      <li
                                        key={this.props.index + inner_index}
                                        onDragEnter={this.noteBtnDragEnter}
                                        onDragLeave={this.noteBtnDragLeave}
                                        onDragOver={this.noteBtnDragOver}
                                        onDrop={e =>
                                          this.noteBtnDrop(e, {
                                            type: "listItem",
                                            data: {
                                              listName: this.props.listItem
                                                .name,
                                              text: item.list_name,
                                              url: window.location.pathname,
                                            },
                                          })
                                        }>
                                        {item.list_name}
                                      </li>
                                    );
                                  }
                              })}
                            {this.props.listItem.item > 4 ? (
                              <li
                                style={{
                                  listStyleType: "none",
                                  color: "gray",
                                  fontSize: "13px",
                                  fontWeight: "normal",
                                }}>
                                More...
                              </li>
                            ) : null}
                          </ol>
                        ) : null}
                        <div className="LastUpdate">
                          <span>
                            <i className="fa fa-eye" title="Views">
                              {this.props.listItem.stats.length
                                ? this.props.listItem.stats[0].views
                                : "0"}
                            </i>
                          </span>
                          <span title="Last updated">
                            <img src="assets/LastUpdated.svg"></img>
                            <span>
                              {this.formatLastUpdateTime(
                                this.props.listItem.lastUpdated &&
                                  this.props.listItem.lastUpdated.at
                                  ? this.props.listItem.lastUpdated.at
                                  : this.props.listItem.updatedAt
                              )}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="card-control">
                        <p className={"author"} title="Author">
                          @
                          {this.props.listItem.profile.length === 0
                            ? "deleted"
                            : this.props.listItem.profile.map(
                                profile => profile.user_name
                              )}
                        </p>
                        <i style={{ flexGrow: "5" }} />
                        <i className="fa fa-eye" title="Views">
                          {" "}
                          {this.props.listItem.stats.length
                            ? this.props.listItem.stats[0].views
                            : "0"}
                        </i>
                      </div>
                      <ListShareIcons
                        isShareMenuOpen={this.state.isShareMenuOpen}
                        handleClickOnFolder={this.props.handleClickOnFolder}
                        hideShareMenu={this.hideShareMenu}
                        shareBaseUrl={`${GET_SHORT_URL_CODE_API}${this.state.shortenedCode}`}
                      />
                      {this.props.listItem.listType === "ask" &&
                      this.props.token &&
                      !this.state.isShareMenuOpen ? (
                        <div
                          className="addnewitem"
                          onClick={e => e.stopPropagation()}>
                          <Box>
                            <input
                              disabled={!this.props.token ? true : false}
                              style={
                                this.state.noOfQuickAddedItems > 0
                                  ? { borderRadius: "0 0 0 10px" }
                                  : null
                              }
                              type="text"
                              name="quickItemInput"
                              value={this.state.quickItemInput}
                              placeholder="Add a new item."
                              onFocus={e => (e.target.placeholder = "")}
                              onBlur={e => {
                                if (this.state.quickItemInput) {
                                  this.setState({
                                    quickItemInput: "",
                                  });
                                }
                                e.target.placeholder = "Add a new item.";
                              }}
                              onKeyUp={e => {
                                this.handleQuickItemAdd(e);
                              }}
                              onChange={e => {
                                this.quickItemInputChange(e);
                              }}
                              variant="standard"
                              InputProps={{ disableUnderline: true }}
                            />
                            {this.state.noOfQuickAddedItems > 0 ? (
                              <div
                                className="undo-btn-wrapper"
                                onClick={() => this.handleQuickUndo()}>
                                <UndoRoundedIcon />
                              </div>
                            ) : null}
                          </Box>
                        </div>
                      ) : null}
                    </Fragment>
                  ) : null}
                </CardBody>
              </div>
            </Fragment>
          ) : null}
        </Tilt>
      </Fragment>
    );
  }
}

export default ListCard;
