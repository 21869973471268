import { getApiUrl } from '../constants/baseConstants';

export const LOGIN_API = getApiUrl('accounts/login');
export const REGISTER_API = getApiUrl('accounts/cr_acc');
export const RESEND_OTP_API = getApiUrl('accounts/resend_otp');
export const VERIFY_API = getApiUrl('accounts/verify_email');
export const FORGET_PASSWORD_API = getApiUrl('accounts/fgt_resend_token');
export const VERIFY_FORGET_API = getApiUrl('accounts/verify_email_forget');
export const RESET_PASSWORD_API = getApiUrl('accounts/reset_pass');
export const AUTH_LINKEDIN_API = getApiUrl('auth/linkedin');
export const AUTH_TWITTER_API = getApiUrl('auth/twitter');
export const AUTH_TWITTER_API_REVERSE = getApiUrl('auth/twitter/reverse');
export const AUTH_GOOGLE_API = getApiUrl('auth/google');
/* ------------------------urls for closed launch------------------------------*/
export const REGISTER_API_CLOSED = getApiUrl('accounts/signup_closed')
