import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TAG_PATH, getListPath, HOME_PATH } from "../constants/pathConstants";
import SideBar from './Utils/SideBar';


class Tags extends Component {
    constructor(props) {
        super(props);

        this.setState = {
            tags: []
        }
    }

    componentWillMount() {
        if (!this.props.token) this.props.history.push(HOME_PATH, { login_required: true, redirect_to: TAG_PATH });
        else {
            this.props.fetchTags(this.props.token)
        };
    }
    render() {
        console.log(this.props)
        const { tags } = this.props;
        return (
            <div className="container discover_container">
                <div className="row">
                    <SideBar />
                    <div className="col-12 col-md-9 col-lg-10">
                        <div className="row">
                            {tags.map((data) => {
                                return (
                                    <div className="col-12 col-md-6 col-lg-3" key={data._id}>
                                        <div className={'list_card'}>
                                            <p className="list-card-title">{data.tagName}</p>
                                            <hr />
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet laboriosam doloribus sequi, exercitationem esse ad sapiente omnis incidunt ipsa, dolor ab voluptatum molestias ex inventore, et dolorum quo numquam eligendi?</p>
                                            <div className="card-control">
                                                <i style={{ flexGrow: "5" }}></i>
                                                <i className="fa fa-eye"> 0</i>
                                                <i className="fa fa-heart"> 430</i>
                                                <i className="fa fa-tags"> 23</i>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Tags;