import React, { Component } from "react";
import ReactMapGL, { Marker } from "react-map-gl";

import Geocoder from "react-map-gl-geocoder";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import Pin from "./Pin";
import axios from "axios";
import CountrySelect from "./CountrySelect";

import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import RoomRoundedIcon from "@material-ui/icons/RoomRounded";
import { countryList } from "./CountryList";

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewport: {
                latitude: 28.6139,
                longitude: 77.209,
                zoom: 5,
                bearing: 0,
                pitch: 0,
            },
            marker: {
                latitude: null,
                longitude: null,
            },
            currentLocation: null,
            showMyPlace: false,
            pinLocation: false,
            pinnedPlace: "",
            currentCountry: "IN",
            newCountry: null,
            events: {},
        };
    }

    mapRef = React.createRef();

    componentDidMount() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    this.getCountryCode(lat, lng).then((res) => {
                        this.setState({
                            currentCountry: res.country_code,
                            viewport: {
                                latitude: lat,
                                longitude: lng,
                                zoom: 5,
                                bearing: 0,
                                pitch: 0,
                            },
                        });
                    });
                },
                () => {
                    console.log("cant access your location");
                }
            );
        } else {
            alert("Sorry, your browser does not support geolocation services.");
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps.coordinates[0] !== this.props.coordinates[0])
           if(this.props.showHotspotIcon){
            this.setState({
                viewport: {
                    latitude: this.props.coordinates[0],
                    longitude: this.props.coordinates[1],
                    zoom: 5,
                    bearing: 0,
                    pitch: 0,
                    transitionDuration: 500,
                },
            });
           }
        
    }

    _updateViewport = (viewport) => {
        this.setState({ viewport });
    };

    // if you are happy with Geocoder default settings, you can just use handleViewportChange directly
    handleGeocoderViewportChange = (viewport) => {
        const geocoderDefaultOverrides = { transitionDuration: 1000 };

        return this._updateViewport({
            ...viewport,
            ...geocoderDefaultOverrides,
        });
    };

    //Marker event

    _logDragEvent(name, event) {
        this.setState({
            events: {
                ...this.state.events,
                [name]: event.lngLat,
            },
        });
    }

    _onMarkerDragStart = (event) => {
        this._logDragEvent("onDragStart", event);
    };

    _onMarkerDrag = (event) => {
        this._logDragEvent("onDrag", event);
    };

    _onMarkerDragEnd = (event) => {
        this._logDragEvent("onDragEnd", event);
        this.setState({
            marker: {
                longitude: event.lngLat[0],
                latitude: event.lngLat[1],
            },
        });
        this.props.updatePlaceInput(
            this.state.marker.latitude,
            this.state.marker.longitude
        );
    };

    //locate my area

    locateMyPlace = () => {
        const success = (position) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            this.setState({
                viewport: {
                    latitude: lat,
                    longitude: lng,
                    zoom: 5,
                    bearing: 0,
                    pitch: 0,
                    transitionDuration: 1000,
                },
                showMyPlace: true,
                currentLocation: [lat, lng],
            });
        };

        const fail = () => {
            alert("can't access your location");
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, fail);
        } else {
            alert("Sorry, your browser does not support geolocation services.");
        }
    };
    pinLocation = () => {
        this.setState({
            pinLocation: true,
            marker: {
                longitude: this.state.viewport.longitude,
                latitude: this.state.viewport.latitude,
            },
        });
        this.props.updatePlaceInput(
            this.state.viewport.latitude,
            this.state.viewport.longitude
        );
    };

    getCountryCode = async (lat, lng) => {
        const url = `https://open.mapquestapi.com/nominatim/v1/reverse.php?key=CJv78tAkodj9Qu6eWykXZDMGeYJ3lWZi&format=json&lat=${lat}&lon=${lng}`;
        const {
            data: { address },
        } = await axios.get(url);
        return address;
    };

    fetch_coordinates = async (place) => {
        const url = `https://www.mapquestapi.com/geocoding/v1/address?key=CJv78tAkodj9Qu6eWykXZDMGeYJ3lWZi&location=${place}&thumbMaps=false`;
        const {
            data: { results },
        } = await axios.get(url);
        const coordinates = results[0].locations[0].latLng;
        this.setState({
            viewport: {
                latitude: coordinates.lat,
                longitude: coordinates.lng,
                zoom: 5,
                bearing: 0,
                pitch: 0,
                transitionDuration: 1000,
            },
        });
    };

    ChangeMyCountry = (code) => {
        const newCountry = countryList.filter(
            (i) => i.code.toUpperCase() === code
        )[0].name;
        this.fetch_coordinates(newCountry);
        this.props.handleChangeCountry(newCountry);
    };

    render() {
        return (
            <>
                {this.state.currentCountry !== "" ? (
                    <CountrySelect
                        countryCode={this.state.currentCountry.toUpperCase()}
                        ChangeMyCountry={this.ChangeMyCountry}
                        country={this.props.country}
                    />
                ) : null}

                <ReactMapGL
                    ref={this.mapRef}
                    {...this.state.viewport}
                    width="100%"
                    height="100%"
                    // mapStyle="mapbox://styles/mapbox/dark-v9"
                    onViewportChange={this._updateViewport}
                    mapboxApiAccessToken={MAPBOX_TOKEN}
                >
                    {this.state.showMyPlace ? (
                        <Marker
                            longitude={this.state.currentLocation[1]}
                            latitude={this.state.currentLocation[0]}
                            offsetTop={-20}
                            offsetLeft={-10}
                        >
                            <HomeRoundedIcon style={{ color: "#d454c4" }} />
                        </Marker>
                    ) : null}

                    {this.props.showHotspotIcon ? (
                        <Marker
                            longitude={this.props.coordinates[1]}
                            latitude={this.props.coordinates[0]}
                            offsetTop={-20}
                            offsetLeft={-10}
                            draggable
                            onDragStart={this._onMarkerDragStart}
                            onDrag={this._onMarkerDrag}
                            onDragEnd={this._onMarkerDragEnd}
                        >
                            <Pin size={20} />
                        </Marker>
                    ) : null}

                    {/* <Geocoder
                        mapRef={this.mapRef}
                        onResult={this.handleOnResult}
                        onViewportChange={this.handleGeocoderViewportChange}
                        mapboxApiAccessToken={MAPBOX_TOKEN}
                        position="top-left"
                    /> */}
                    {/* <div className="nav" style={navStyle}>
                    <NavigationControl onViewportChange={this._updateViewport} />
                </div> */}
                </ReactMapGL>
                <div className="map-icons">
                    <div onClick={this.locateMyPlace}>
                        <HomeRoundedIcon style={{ color: "#d454c4" }} />
                        Your Location
                    </div>
                    <div onClick={this.pinLocation}>
                        <RoomRoundedIcon style={{ color: "#5543ef" }} />
                        Point Area or Location
                    </div>
                </div>
            </>
        );
    }
}

const MAPBOX_TOKEN =
    "pk.eyJ1Ijoicm9ja3RpbXNhaWtpYSIsImEiOiJjazk2MmlwbGQwNGI2M2ZxaHBxbDdqcnJ5In0.eAm8OSlFEAs7GM_hE3Ernw";

export default Map;
