import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import {
	LIVE_USERS_COUNT,
	LIVE_USERS_ARRAY,
	USERS_BY_COUNTRY,
	REQUEST,
	LIST_COUNT,
	LIST_COUNT_BY_CATEGORY,
	TOP_LISTS,
	LIST_TRAFFIC_STATS,
	SHORT_URL_STATS,
} from '../constants/socketEvents';

import ActiveUsersChart from './AnalyticCharts/ActiveUsersChart';
import UsersByRegion from './AnalyticCharts/UsersByRegion';
import ListCategories from './AnalyticCharts/ListCategories';
import ListSourcesChart from './AnalyticCharts/ListSources';

class Admin extends Component {
	state = {
		liveUserCount: 0,
		liveUserArr: [],
		userCountry: {
			views: [],
			countries: [],
		},
		topXLists: [],
		totalLists: 0,
		totalCateg: 0,
		categArr: {
			categ: [],
			listCnt: [],
		},
		listTrafficStats: {
			sources: [],
			clicks: [],
		},
		urlShortenerStats: {
      sources: [],
      urlsShortened: []
    },
	};

	componentDidMount() {
		this.props.socket.on(LIVE_USERS_COUNT, (data) => {
			this.setState({ liveUserCount: data.data });
		});

		this.props.socket.on(LIST_COUNT, (data) => {
			this.setState({ totalLists: data.data });
		});

		this.props.socket.io.emit(REQUEST, { sort: -1 });

		this.props.socket.on(LIST_COUNT_BY_CATEGORY, (data) => {
			var categ = [],
				cnts = [],
				totalCateg = 0;
			if (data.data) {
				data.data.forEach((item) => {
					categ.push(item._id);
					cnts.push(item.count);
					totalCateg += item.count;
				});
			}
			var obj = {
				categ: categ,
				listCnt: cnts,
			};
			console.log(totalCateg);
			this.setState({ categArr: obj, totalCateg: totalCateg });
		});

		this.props.socket.on(LIVE_USERS_ARRAY, (data) => {
			var liveCountArray = [];
			if (data.data) {
				for (let i = 0; i < data.data.length; i++) {
					liveCountArray.push(parseInt(data.data[i]));
				}
			}
			this.setState({ liveUserArr: liveCountArray });
		});

		this.props.socket.on(USERS_BY_COUNTRY, (data) => {
			var countries = [],
				views = [];
			if (data.data) {
				data.data.forEach((item) => {
					countries.push(item.country);
					views.push(item.views);
				});
			}
			var obj = {
				views: views,
				countries: countries,
			};
			this.setState({ userCountry: obj });
		});

		this.props.socket.on(LIST_TRAFFIC_STATS, (data) => {
			let sources = [],
				clicks = [];
			if (data.data) {
				data.data.forEach((item) => {
					sources.push(`${item.name} (${item.clicks})`);
					clicks.push(item.clicks);
				});
			}
			let listTrafficStats = {
				sources,
				clicks,
			};
			this.setState({ listTrafficStats });
		});

		this.props.socket.on(SHORT_URL_STATS, (data) => {
			let urlShortenerSourcesStats = [];
			if (data.data) {
				Object.keys(data.data.sources).map((source) => {
					let sources = [],
						clicks = [];
					if (data.data.sources[source].length) {
						data.data.sources[source].forEach((item) => {
							sources.push(`${item.name} (${item.clicks})`);
							clicks.push(item.clicks);
						});
					}
					urlShortenerSourcesStats.push({
						source: source.toUpperCase(),
						sources,
						clicks,
					});
				});
			}
			let urlShortenerStats = {
				sources: urlShortenerSourcesStats,
				urlsShortened: data.data ? data.data.urlsShortened : [],
			};

			this.setState({ urlShortenerStats });
		});

		// this.props.socket.emit(REQUEST, {eventType: TOP})
	}

	precentageChange = (arr) => {
		let inc = 0,
			dec = 0;
		for (let i = 0; i < arr.length - 1; i++) {
			if (arr[i] > arr[i + 1]) {
				dec += 1;
			} else if (arr[i] < arr[i + 1]) {
				inc += 1;
			}
		}
		console.log(inc, dec);
		return inc === dec ? 0 : ((inc - dec) / inc) * 100;
	};

	isIncreasing = (arr) => {
		if (arr[arr.length - 1] >= arr[arr.length - 2]) {
			return <i className="fas fa-arrow-up"></i>;
		} else {
			return <i className="fas fa-arrow-down"></i>;
		}
	};

	getTotalClicks = () => {
		let total = 0;
		this.state.listTrafficStats.clicks.map((clicks) => {
			total += clicks;
		});
		return total;
	};

	render() {
		// console.clear()
		// console.log(this.props.lists.total)
		return (
			<div className={'admin_container'}>
				<br />
				<h4>User statistics</h4>
				<br /> <br />
				<Grid container spacing={16}>
					<Grid item xs={12} sm={4}>
						<Card style={{ height: '41vh' }}>
							<CardHeader>
								<Row>
									<Col sm="6">
										<div className="card-category-holder">
											<div className="card-category">
												<i className="fas fa-user"></i>
											</div>
										</div>
										<CardTitle tag="h3">Active Users</CardTitle>
									</Col>
									<Col sm="6">
										<div className="stat-number">{this.state.liveUserCount}</div>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<div className={'admin-chart-area'}>
									<ActiveUsersChart
										data={this.state.liveUserArr}
										chartName="Page Views per minute"
									></ActiveUsersChart>
								</div>
							</CardBody>
						</Card>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Card style={{ height: '41vh' }}>
							<CardHeader>
								<Row>
									<Col sm="12">
										<div className="card-category-holder">
											<div className="card-category">
												<i className="fas fa-map-marker-alt"></i>
											</div>
										</div>
										<CardTitle tag="h3">Users by Country</CardTitle>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<div className="admin-chart-area">
									<UsersByRegion
										views={this.state.userCountry.views}
										countries={this.state.userCountry.countries}
										chartName="Chart updates every 3 minutes"
									></UsersByRegion>
								</div>
							</CardBody>
						</Card>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Card style={{ height: '41vh' }}>
							<CardHeader>
								<Row>
									<Col sm="4">
										<div className="card-category-holder">
											<div className="card-category">
												<i className="fas fa-list"></i>
											</div>
										</div>
										<CardTitle tag="h3">Total Lists</CardTitle>
									</Col>
									<Col sm="8">
										<div className="stat-number">{this.state.totalLists.total}</div>
										<div className="stat-desc">
											Out of which, <span class="stat-hlgt">{this.state.totalLists.drafts}</span>{' '}
											are drafts, <span class="stat-hlgt">{this.state.totalLists.private}</span>{' '}
											are private, <span class="stat-hlgt">{this.state.totalLists.suspected}</span>{' '}
											are suspected and{' '}
											<span class="stat-hlgt">{this.state.totalLists.private}</span> are private
										</div>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<div className={'admin-chart-area'}>
									<ListCategories
										categArr={this.state.categArr}
										total={this.state.totalCateg}
										chartName="Lists by Category"
									></ListCategories>
								</div>
							</CardBody>
						</Card>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Card style={{ height: '41vh', marginTop: '200px' }}>
							<CardHeader>
								<Row>
									<Col sm="4">
										<div className="card-category-holder">
											<div className="card-category">
												<i className="fas fa-list"></i>
											</div>
										</div>
										<CardTitle tag="h4">List Traffic</CardTitle>
									</Col>
									<Col sm="8">
										<div className="stat-number">{this.getTotalClicks()}</div>
										<div className="stat-desc">
											{/* <table>
												<th>
													<td>Source</td>
													<td>Clicks</td>
												</th>
												{this.state.listTrafficStats.map((source, i) => (
													<tr key={i}>
														<td>{source.name}</td>
														<td>{source.clicks}</td>
													</tr>
												))}
											</table> */}
										</div>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<div className={'admin-chart-area'}>
									<ListSourcesChart
										clicks={this.state.listTrafficStats.clicks}
										sources={this.state.listTrafficStats.sources}
										chartName="Chart updates every minute"
									></ListSourcesChart>
								</div>
							</CardBody>
						</Card>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Card style={{ height: '41vh', marginTop: '200px' }}>
							<CardHeader>
								<Row>
									<Col sm="4">
										<div className="card-category-holder">
											<div className="card-category">
												<i className="fas fa-list"></i>
											</div>
										</div>
										<CardTitle tag="h4">Short URL Traffic</CardTitle>
									</Col>
									<Col sm="8">
										<div className="stat-number">{/* {this.getTotalClicks()} */}</div>
										<div className="stat-desc">
											<table>
												<th>
													<td>Type</td>
													<td>Total</td>
												</th>
												{this.state.urlShortenerStats
													? Object.keys(this.state.urlShortenerStats.urlsShortened).map(
															(key, i) => (
																<tr key={i}>
																	<td>{key}</td>
																	<td>
																		{
																			this.state.urlShortenerStats.urlsShortened[
																				key
																			]
																		}
																	</td>
																</tr>
															)
													  )
													: null}
											</table>
										</div>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<div className={'admin-chart-area'}>
									{this.state.urlShortenerStats
										? this.state.urlShortenerStats.sources.map((source, i) => (
												<>
													{source.sources.length ? (
														<ListSourcesChart
															clicks={source.clicks}
															sources={source.sources}
															chartName={source.source}
														></ListSourcesChart>
													) : null}
												</>

												// <div key={i} className="col">
												// 	<br></br>
												// 	<p>{key}</p>
												// 	<table>
												// 		<th>
												// 			<td>Type</td>
												// 			<td>Total</td>
												// 		</th>
												// 		{this.state.urlShortenerStats.sources[key].map((sources, i) => (
												// 			<tr key={i}>
												// 				<td>{sources.name}</td>
												// 				<td>{sources.clicks}</td>
												// 			</tr>
												// 		))}
												// 	</table>
												// </div>
										  ))
										: null}
								</div>
							</CardBody>
						</Card>
					</Grid>
				</Grid>
				{/* <Grid container spacing = {16}>
          <Grid item xs = {12} sm = {4}>
            <Card style={{ height: "41vh" }}>
              <CardHeader>
                <Row>
                  <Col sm="12">
                    <div className="card-category-holder">
                      <div className="card-category"><i className="fa fa-star"></i></div>
                    </div>
                    <CardTitle tag="h3">Top Lists</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className='admin-chart-area' >

                </div>
              </CardBody>
            </Card>
          </Grid>
        </Grid> */}
			</div>
		);
	}
}

export default Admin;
