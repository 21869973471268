//api
export const SAVE_LIST = "SAVE_LIST";
export const SAVE_LIST_ITEM = "SAVE_LIST_ITEM";
export const EDIT_LIST = "EDIT_LIST";
export const FETCH_LIST = "FETCH_LIST";
export const FETCH_DRAFT_LIST = "FETCH_DRAFT_LIST";
export const FETCH_LIST_BY_ID = "FETCH_LIST_BY_ID";
export const SEARCH_LIST = "SEARCH_LIST";
export const CLEAR_CURRENT_LIST_DATA_FROM_STORE =
  "CLEAR_CURRENT_LIST_DATA_FROM_STORE";
export const FETCH_ALL_USER_BOOKMARKS = "FETCH_ALL_USER_BOOKMARKS";
export const ADD_LIST_COLLAB = "ADD_LIST_COLLAB";
export const DELETE_LIST_COLLAB = "DELETE_LIST_COLLAB";
export const RESPOND_LIST_COLLAB = "RESPOND_LIST_COLLAB";
export const ASK_QUESTION = "ASK_QUESTION";
export const ASK_QUESTION_ERR = "ASK_QUESTION_ERR";
export const FETCH_REQUESTS = "FETCH_REQUESTS";
export const FETCH_REQUEST_REPLIES = "FETCH_REQUEST_REPLIES";
export const REPLY_TO_ASK = "REPLY_TO_ASK";
export const DELETE_LIST = "DELETE_LIST";
export const DELETE_LIST_ITEM = "DELETE_LIST_ITEM";
export const UPVOTE_LIST_ITEM = "UPVOTE_LIST_ITEM";
export const BOOKMARK_LIST_ITEM = "BOOKMARK_LIST_ITEM";
export const FETCH_BOOKMARKS = "FETCH_BOOKMARKS";
export const FETCH_USERFOLLOWING = "FETCH_USERFOLLOWING";
export const FOLLOW_USER = "FOLLOW_USER";
export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_PEOPLE = "FETCH_PEOPLE";
export const FETCH_TAG = "FETCH_TAG";
export const FETCH_BOOKMARKS_LIST = "FETCH_BOOKMARKS_LIST";
export const FETCH_BOARDS = "FETCH_BOARDS";
export const ADD_FOLDER = "ADD_FOLDER";
export const RENAME_FOLDER = "RENAME_FOLDER";
export const PRIVATE_FOLDER = "PRIVATE_FOLDER";
export const EDIT_FOLDER = "EDIT_FOLDER";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const DELETE_BOARD = "DELETE_BOARD";
export const ADD_BOARD = "ADD_BOARD";
export const CHANGE_BOARD_VISIBILITY = "CHANGE_BOARD_VISIBILITY";
export const ADD_LISTS_TO_FOLDER = "ADD_LISTS_TO_FOLDER";
export const FETCH_FOLDER_ITEMS = "FETCH_FOLDER_ITEMS";
export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const FETCH_FOLDER_ITEM_DETAILS = "FETCH_FOLDER_ITEM_DETAILS";
export const GET_PAGE_STATE = "GET_PAGE_STATE";
export const GET_PUBLIC_PROFILE = "GET_PUBLIC_PROFILE";
export const GET_NOTIFICATIONS = "GET NOTIFICATIONS";
export const MARK_READ_NOTIF = "MARK_READ_NOTIF";
export const MARK_ALLREAD_NOTIF = "MARK_ALLREAD_NOTIF";
export const REPORT_LIST = "REPORT_LIST";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHECK_PASSWORD = "CHECK_PASSWORD";
export const SEND_VERIFY_EMAIL = "SEND_VERIFY_EMAIL";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const PROMOTE_LIST = "PROMOTE_LIST";
export const BOOKMARK_USER = "BOOKMARK_USER";
export const UNBOOKMARK_USER = "UNBOOKMARK_USER";
export const FETCH_USERS_BOOKMARKED = "FETCH_USERS_BOOKMARKED";
export const ADD_LIST_ITEM = "ADD_LIST_ITEM";
export const REMOVE_LIST_ITEM = "REMOVE_LIST_ITEM";
export const FETCH_STACK = "FETCH_STACK";
export const RENAME_BOARD = "RENAME_BOARD";
export const PIN_STACK_ITEM = "PIN_STACK_ITEM";
export const DELETE_STACK_ITEM = "DELETE_STACK_ITEM";
export const DELETE_ASKED = "DELETE_ASKED";
export const UPDATE_RECOMMENDATION = "UPDATE_RECOMMENDATION";

//auth
export const LOGIN_USER = "LOGIN_USER";
export const REGISTER_USER = "REGISTER_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const TOGGLE_EMAIL_VERIFIED = "TOGGLE_EMAIL_VERIFIED";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const RETRY_SIGNUP = "RETRY_SIGNUP";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const UPDATE_USER = "UPDATE_USER";

//error
export const LOGIN_USER_ERR = "LOGIN_USER_ERR";
export const REGISTER_USER_ERR = "REGISTER_USER_ERR";
export const CLEAR_AUTH_ERR = "CLEAR_AUTH_ERR";
export const CLEAR_ASK_ERR = "CLEAR_ASK_ERR";

//base
export const TOGGLE_LOGIN_MODAL = "TOGGLE_LOGIN_MODAL";
export const SHOW_LOGIN_MODAL = "SHOW_LOGIN_MODAL";
export const HIDE_LOGIN_MODAL = "HIDE_LOGIN_MODAL";
export const REFRESH_DISCOVER_TRUE = "REFRESH_DISCOVER_TRUE";
export const REFRESH_DISCOVER_FALSE = "REFRESH_DISCOVER_FALSE";

//notes
export const CREATE_NOTE = "CREATE_NOTE";
export const FETCH_MY_NOTES = "FETCH_MY_NOTES";
export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_NOTE_LIST = "DELETE_NOTE_LIST";
export const EDIT_NOTE = "EDIT_NOTE";
export const EDIT_NOTE_LIST = "EDIT_NOTE_LIST";
export const FETCH_ALL_LIST_NOTES = "FETCH_ALL_LIST_NOTES";
export const CLOSE_NOTE_SIDE_BAR = "CLOSE_NOTE_SIDE_BAR";
export const OPEN_NOTE_SIDE_BAR = "OPEN_NOTE_SIDE_BAR";
export const DROP_NOTE_BUTTON = "DROP_NOTE_BUTTON";

// prospectives
export const CREATE_PROSPECTIVE = "CREATE_PROSPECTIVE";
export const CREATE_PROSPECTIVE_ITEM = "CREATE_PROSPECTIVE_ITEM";
export const EDIT_PROSPECTIVE = "EDIT_PROSPECTIVE";
export const FETCH_PROSPECTIVE = "FETCH_PROSPECTIVE";
export const DELETE_PROSPECTIVE = "DELETE_PROSPECTIVE";
export const PROSPECTIVE_ERROR = "PROSPECTIVE_ERROR";
export const CLEAR_PROSPECTIVE_ERROR = "CLEAR_PROSPECTIVE_ERROR";

export const FETCH_USER_LIST = "FETCH_USER_LIST";
export const FETCH_USER_COLLAB_LISTS = "FETCH_USER_COLLAB_LISTS";

export const FETCH_USER_BOOKMARKS = "FETCH_USER_BOOKMARKS";

// suspect list
export const MAKE_LIST_SUSPECTED = "MAKE_LIST_SUSPECTED";
export const RESPOND_SUSPECT_LIST = "RESPOND_SUSPECT_LIST";
export const UPDATE_SUSPECT_STATUS = "UPDATE_SUSPECT_STATUS";

// comments
export const FETCH_ALL_COMMENTS = "FETCH_ALL_COMMENTS";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const FETCH_REPLAY_COMMENTS = "FETCH_REPLAY_COMMENTS";
export const CREATE_COMMENT_REPLAY = "CREATE_COMMENT_REPLAY";
export const EDIT_COMMENT = "EDIT_COMMENT";
export const EDIT_COMMENT_REPLY = "EDIT_COMMENT_REPLY";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_REPLY = "DELETE_COMMENT_REPLY";
