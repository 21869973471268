import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers/index';
import {AUTHENTICATE_USER} from "../constants/actionTypes";
import Cookies from "js-cookie";
import {COOKIE_AUTH} from "../constants/baseConstants";

const createStoreWithMiddleware = applyMiddleware(thunkMiddleware)(createStore);

export default function configureStore(initialState) {
  const store = createStoreWithMiddleware(
    rootReducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  );

  if(Cookies.get(COOKIE_AUTH))
    store.dispatch({ type: AUTHENTICATE_USER });

  return store;
}
