import React, { Component } from "react";

class TermsOfUse extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="footer-doc-container">
        <h1 className="text-center">Website Terms Of Use</h1>
        <p className="text-center">
          <strong>Welcome to our website.</strong>
        </p>
        <div className="footer-doc-content">
          <p>
            If you continue to browse and use this website, you are agreeing to
            comply with and be bound by the following terms and conditions of
            use, which together with our privacy policy govern Listiee's
            relationship with you in relation to this website. These Terms and
            Conditions of Use (the “Terms of Use”) apply to the Listiee website
            located at www.listiee.com. The Site is a copyrighted work belonging
            to VIZACK CREATIONS LLP.
          </p>
          <p>
            Certain features of the Site may be subject to additional
            guidelines, terms, or rules, which will be posted on the Site in
            connection with such features. All such additional terms,
            guidelines, and rules are incorporated by reference into these
            Terms. We reserve the right, at our sole discretion, to change,
            modify, add or remove portions of these Terms of Use, at any time.
          </p>
          <p>
            The company shall notify you in case of any minor changes whenever
            you login and in case of any significant changes, you shall be
            notified via email.
          </p>
          <p>
            Your continued use of the Site following the posting of changes will
            mean that you accept and agree to the changes.{" "}
          </p>
          <b>2. Access To The Site</b>
          <p>
            You should be at least 16 years old or of the age your local law
            permits you to be a part of social media website. if you disagree
            with any part of these terms and conditions, please do not log into
            and/or use the site. If you are accepting these Terms on behalf of
            another legal entity, including a business or a government, you
            represent that you have full legal authority to bind such entity to
            these terms.
          </p>
          <b>3. Use of the Services</b>
          <p>
            As long as you comply with these Terms of Use, we grant you a
            personal, non-exclusive, non-transferable, non-commercial use,
            limited privilege to enter and use the Site.
          </p>
          <b>Certain Restrictions:</b>
          <ul>
            <li>
              The rights approved to you in these Terms are subject to the
              following restrictions:{" "}
            </li>
            <li>
              you shall not sell, rent, lease, transfer, assign, distribute,
              host, or otherwise commercially exploit the Site;{" "}
            </li>
            <li>
              you shall not change, make derivative works of, disassemble,
              reverse compile or reverse engineer any part of the Site;{" "}
            </li>
            <li>
              you shall not access the Site in order to build a similar or
              competitive website; and{" "}
            </li>
            <li>
              except as expressly stated herein, no part of the Site may be
              copied, reproduced, distributed, republished, downloaded,
              displayed, posted or transmitted in any form or by any means
              unless otherwise indicated, any future release, update, or other
              addition to functionality of the Site shall be subject to these
              Terms.{" "}
            </li>
            <li>
              All copyright and other proprietary notices on the Site must be
              retained on all copies thereof.
            </li>
            <li>
              You may not use any algorithm or any methods, or any similar or
              equivalent manual process, to access, acquire, copy or monitor any
              portion of the Site or any Content, or in any way reproduce or
              circumvent the navigational structure or presentation of the Site
              or any Content, to obtain or attempt to obtain any materials,
              documents or information through any means not purposely made
              available through the Site.
            </li>
            <li>
              You agree not to use any device, software or routine, to interfere
              or attempt to interfere with the proper working of the Site or any
              transaction being conducted on the Site, or with any other
              person’s use of the Site.
            </li>
            <li>
              You may not use the Site or any Content for any purpose that is
              unlawful or prohibited by these Terms of Use, or to solicit the
              performance of any illegal activity or other activity which
              infringes the rights of Listee or others.
            </li>
          </ul>
          <p>
            Company reserves the right to change, suspend, or cease the Site,
            with a prior notice to you. The Company will not be held liable to
            you or any third-party for any change, interruption, or termination
            of the Site or any part.
          </p>
          <b>4. Support or Maintenance</b>
          <p>
            You agree that we will have no obligation to provide you with any
            support in connection with the Site until notified through any
            official sources unless there is any serious problem in which case
            you can contact us through Email. Excluding any User Content that
            you may provide, all the intellectual property rights, including
            copyrights, patents, trademarks, and trade secrets, in the Site and
            its content are owned by us or our suppliers. Note that these Terms
            and access to the Site do not give you any rights, title or interest
            in or to any intellectual property rights, except for the limited
            access rights. We and our suppliers reserve all rights not granted
            in these Terms.
          </p>
          <b>5. User Content</b>
          <p>
            "User Content" means any and all information and content that a user
            submits to or shares on the Site. You are exclusively responsible
            for your content you add to the website. You bear all
            responsibilities associated with use of your User Content. You
            hereby certify that your User Content does not violate our
            Acceptable Use Policy. You may not represent or imply to others that
            your User Content is in any way provided, sponsored or endorsed by
            Company. Because you alone are responsible for your User Content,
            you may expose yourself to liability. Company is not obliged to
            backup any User Content that you post; also, your User Content may
            be deleted at any time without prior notice to you if it is found to
            be violating any ‘terms of use’ and other declared policies. You are
            solely responsible for making your own backup copies of your User
            Content if you desire. You hereby grant to Company an irreversible,
            nonexclusive, royalty-free and fully paid, worldwide license to
            reproduce, distribute, publicly display and perform, prepare
            derivative works of, incorporate into other works, and otherwise use
            and exploit your User Content, and to grant sublicenses of the
            foregoing rights, solely for the purposes of including your User
            Content in the Site. You hereby irreversibly waive any claims and
            assertions of moral rights or attribution with respect to your User
            Content.
          </p>
          <b>6. Things you cannot do</b>
          <p>
            <i>
              The following terms constitute our <b>"Acceptable Use Policy":</b>{" "}
            </i>
            <br />
            You agree not to use the Site to collect, upload, transmit, display,
            or distribute any User Content directly or indireclyt for any
            commercial purposes:
          </p>
          <p>
            that violates any third-party right or any intellectual
            property(i.e. Copyright, trademark etc.) or proprietary right;{" "}
          </p>
          <p>
            that is unlawful, harassing, abusive, threatening, harmful, invasive
            of another’s privacy, defamatory, false, intentionally misleading,
            trade libelous(i.e. Defamatory statement with respect to trade),
            pornographic, obscene/ vulgar, promotes racism, hurts religious
            sentiments, hatred, or physical harm of any kind against any group
            or individual;{" "}
          </p>
          <p>that is harmful to minors in any way; or </p>
          <p>
            that is in violation of any law, regulation, or obligations or
            restrictions imposed by your country and any third party.
          </p>
          <b>In addition, you agree not to:</b>
          <p>
            upload, transmit, or distribute to or through the Site any software
            intended to damage or alter a computer system or data;{" "}
          </p>
          <p>
            send through the Site unsolicited or unauthorized advertising,
            promotional materials, junk mail, spam, chain letters, pyramid
            schemes, or any other form of duplicative or unsolicited messages;
          </p>
          <p>
            use the Site to harvest, collect, gather or assemble information or
            data regarding other users without their consent;{" "}
          </p>
          <p>
            interfere with, disrupt, or create an undue burden on servers or
            networks connected to the Site, or violate the regulations, policies
            or procedures of such networks;
          </p>
          <p>
            attempt to gain unauthorized access to the Site, whether through
            password mining or any other means;
          </p>
          <p>
            harass or interfere with any other user’s use and enjoyment of the
            Site; or
          </p>
          <p>
            use software or automated agents or scripts to produce multiple
            accounts on the Site, or to generate automated searches, requests,
            or queries to the Site.
          </p>
          <p>
            We reserve the right to review any User Content, and to investigate
            and/or take appropriate action against you in our sole discretion if
            you violate the Acceptable Use Policy or any other provision of
            these Terms or otherwise create liability for us or any other
            person. Such action may include removing or modifying your User
            Content, terminating your Account with a prior notice.
          </p>
          <p>
            If you provide Company with any feedback or suggestions regarding
            anything that is relevant to the Site, you hereby assign to Company
            all rights in such Feedback and agree that Company shall have the
            right to use such Feedback and related information in any manner it
            believes appropriate. Company will treat any Feedback you provide to
            Company as non-confidential and non-proprietary.
          </p>
          <p>
            You agree to indemnify and hold Company and its officers, employees,
            and agents harmless, including costs and lawyers’ fees, from any
            claim or demand made by any third-party due to or arising out of
            <br />
            <i>(a) your use of the Site, </i>
            <br />
            <i>(b) your violation of these Terms, </i>
            <br />
            <i>(c) your violation of applicable laws or regulations or </i>
            <br />
            <i>(d) your User Content. </i>
            <br />
          </p>
          <p>
            Company reserves the right to assume the exclusive defense and
            control of any matter for which you are required to indemnify us,
            and you agree to cooperate with our defense of these claims. You
            agree not to settle any matter without the prior written consent of
            Company. Company will use reasonable efforts to notify you of any
            such claim, action or proceeding upon becoming aware of it.
          </p>
          <b>7. Third-Party Links & Ads; Other Users</b>
          <b>
            <i>Third-Party Links & Ads</i>
          </b>
          <p>
            This Site may contain links to other autonomous third party Web
            sites ("Linked Sites"). These Linked Sites are given exclusively as
            a convenience to our guests. Such Linked Sites are not under Listiee
            and VIZACK CREATIONS LLP isn't incharge of and does not underwrite
            the substance of such Linked Sites, including any data or materials
            contained on such Linked Sites. You should make your own judgment in
            regards to your connection with these Linked Sites. Other Users
          </p>
          <p>
            Each Site user is solely responsible for any and all of its own User
            Content. Because we do not control User Content, you acknowledge and
            agree that we are not responsible for any User Content, whether
            provided by you or by others. You agree that Company will not be
            responsible for any loss or damage incurred as the result of any
            such interactions. If there is a dispute between you and any Site
            user, we are not responsible or to be involved in it. You hereby
            discharge the Company and our officers, employees, agents,
            successors, and assigns from, and hereby waive any claim, right,
            obligation, liability of every kind and nature, that has arisen or
            arises directly or indirectly out of, or that relates directly or
            indirectly to, the Site. Cookies and Web Beacons
          </p>
          <p>
            Like any other website, We use ‘cookies’. These cookies are used to
            store information including visitors’ preferences, and the pages on
            the website that the visitor accessed or visited. The information is
            used to make the users’ experience more efficient by customizing our
            web page content based on visitors’ browser type and/or other
            information.
          </p>
          <b>9. Copyright Policy</b>
          <p>
            We respect the intellectual property of others and requires that
            users of our Services do the same. We have a policy that includes
            the removal of any infringing materials from the Services and for
            the termination, in appropriate circumstances, of users of our
            Services who are repeat infringers. If you believe that anything on
            our Services infringes a copyright that you own or control, you may
            notify Listiee by sending an Email to hello@listiee.com.
          </p>
          <b>10. Indemnity</b>
          <p>
            Except to the extent prohibited by law, you agree to defend,
            indemnify, and hold us, our licensors, our third party service
            providers and our officers, employees, licensors, and agents
            harmless, including costs and attorneys’ fees, from any claim or
            demand made by any third party due to or arising out of <br />
            <i>(a) your use of the Services, </i>
            <br />
            <i>(b) your violation of these Terms, </i>
            <br />
            <i>(c) your violation of applicable laws or regulations, or </i>
            <br />
            <i>
              (d) Your Content. We reserve the right to control the defense of
              any matter for which you are required to indemnify us, and you
              agree to cooperate with our defense of these claims.
            </i>
            <br />
          </p>
          <b>11. Limitation on Liability</b>
          <br />
          <p>
            To the maximum extent permitted by law, in no event shall company or
            our suppliers be liable to you or any third-party for any lost
            profits, lost data, costs of procurement of substitute products, or
            any indirect, consequential, exemplary, incidental, special or
            punitive damages arising from or relating to these terms or your use
            of, or incapability to use the site even if company has been advised
            of the possibility of such damages. Access to and use of the site is
            at your own discretion and risk, and you will be solely responsible
            for any damage to your device or computer system, or loss of data
            resulting therefrom. Some jurisdictions do not allow the limitation
            or exclusion of liability for incidental or consequential damages,
            so the above limitation or exclusion may not apply to you.
          </p>
          <b>12. Changes to the terms</b>
          <p>
            These Terms are subject to occasional revision, and if we make any
            substantial changes, we may notify you by sending you an e-mail to
            the last e-mail address you provided to us and/or by prominently
            posting notice of the changes on our Site. You are responsible for
            providing us with your most current e-mail address. In the event
            that the last e-mail address that you have provided us is not valid
            our dispatch of the e-mail containing such notice will nonetheless
            constitute effective notice of the changes described in the notice.
            Any changes to these Terms will be effective upon the earliest of
            thirty (30) calendar days following our dispatch of an e-mail notice
            to you or thirty (30) calendar days following our posting of notice
            of the changes on our Site. These changes will be effective
            immediately for new users of our Site. Continued use of our Site
            following notice of such changes shall indicate your agreement to
            such changes and agreement to be bound by the terms and conditions
            of such changes.
          </p>
          <b>13. Dispute Resolution</b>
          <p>
            We want you to enjoy Listiee, so if you have an issue or dispute,
            you agree to raise it and try to resolve it with us informally. You
            can contact us with feedback and concerns here or by emailing us at
            hello @listiee.com. Choice of law and jurisdiction: These Terms are
            governed by Indian law, without reference to its conflict of laws
            provisions. You agree that any suit arising from the Services must
            take place in a court located in Chandigarh, India.
          </p>
          <b>14. Term and Termination</b>
          <p>
            Subject to this Section, these Terms will remain in full force and
            effect while you use the Site. We may suspend or terminate your
            rights to use the Site at any time for any reason at our sole
            discretion, including for any use of the Site in violation of these
            Terms. Upon termination of your rights under these Terms, your
            Account and right to access and use the Site will terminate
            immediately. You understand that any termination of your Account may
            involve deletion of your User Content associated with your Account
            from our live databases. Company will not have any liability
            whatsoever to you for any termination of your rights under these
            Terms.
          </p>
          <b>15. Copyright/Trademark Information</b>
          <p>
            <i>Copyright ©VIZACK CREATIONS LLP. All rights reserved.</i> <br />
            All trademarks, logos and service marks displayed on the Site are
            our property or the property of other third-parties. You are not
            permitted to use these Marks without our prior written consent or
            the consent of such third party which may own the Marks.
          </p>
          <b className="h4">Contact Information</b>
          <br />
          <b>Mailing Address:</b> <br />
          Bhojwani Consultancy, <br />
          New Market, Raigarh - 496001,
          <br />
          Chhattisgarh, India
          <br />
          Email: <a href="maitlto:hello@listiee.com">hello @ listiee.com</a>
        </div>
      </div>
    );
  }
}

export default TermsOfUse;
