import React, { Component } from "react";
import PropTypes from "prop-types";
// import { Link } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import swal from "sweetalert";
import Swal from "sweetalert2";
import icon from "./assets/logo.svg";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import AddIcon from '@material-ui/icons/Add';
// import Fab from '@material-ui/core/Fab';
import ErrorAlert from "./Nested/ErrorAlert";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import ProfileHeader from "./Nested/ProfileHeader";
import ProfileTabs from "./ProfileTabs/ProfileTabs";
import StackModal from "./Nested/StackModal";
import { getStackPath } from "../constants/pathConstants";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookmarkModalOpen: false,
      showListModal: false,
      modal: 0,
      anchorEl: null,
      menuOpen: false,
      bookmarks: [],
      userFollow: [],
      folders: [],
      boards: [],
      searchedFolders: [],
      searchedBoards: [],
      deletedItem: [],
      folderItem: [{ lsids: [], lsitids: [] }],
      selectedFolder: "",
      folderItems: [],
      contextFolder: [],
      contextFolderName: "",
      contextFolderPrivate: false,
      isSelfProfile: false,
      publicProfile: null,
      folderItemsLoading: false,
      showAlert: false,
      alertMessage: "",
      alertCode: null,
      query: null,
    };
  }

  //Snackbar auto-hide method
  AutoHideSnackbar = () => {
    this.setState({
      showAlert: false,
    });
  };

  snackBar = (code, message) => {
    this.setState({
      showAlert: true,
      alertMessage: message,
      alertCode: code,
    });
  };

  /*=================================================updated folder handlers start=================================================================*/
  handleAddFolder = async (data) => {
    let { name, privacy, purpose, boardId } = data;

    let folderAlreadyPresent = false;
    this.state.folders.forEach((folder) => {
      if (folder.name === name) {
        this.snackBar("error", "You already have this folder!");
        folderAlreadyPresent = true;
      }
    });

    //to check if user already has folder in the boards
    this.state.boards.forEach((board) => {
      board.boardItems.forEach((boardItem) => {
        if (boardItem.name === name) {
          this.snackBar("error", "You already have this folder!");
          folderAlreadyPresent = true;
        }
      });
    });

    if (folderAlreadyPresent) return;

    const { aid, token } = this.props;

    data = {
      aid,
      name,
      purpose,
      private: privacy,
    };

    if (boardId) {
      data.container = 2;
      data.boardId = boardId;
    } else {
      data.container = 1;
    }

    this.props.addFolder(data, token).then(() => {
      if (this.props.createdFolder.length === 0) {
        this.snackBar("error", "Something went wrong!");
        return;
      }

      data = {
        aid,
        requester: this.state.username,
      };

      this.fetchBoardsFunction(data, token);
      this.snackBar("success", "Folder Created✨");
    });
  };

  handleEditFolder = async (data) => {
    let { id, name, purpose, privacy } = data;

    //to check if user already has folder that he want to create
    let folderAlreadyPresent = false;
    this.state.folders.forEach((folder) => {
      if (folder.name === name && folder._id !== id) {
        this.snackBar("error", "You already have this folder!");
        folderAlreadyPresent = true;
      }
    });

    //to check if user already has folder in the boards
    this.state.boards.forEach((board) => {
      board.boardItems.forEach((boardItem) => {
        if (boardItem.name === name && boardItem._id !== id) {
          this.snackBar("error", "You already have this folder!");
          folderAlreadyPresent = true;
        }
      });
    });

    if (folderAlreadyPresent) return;

    const { aid, token } = this.props;

    data = {
      aid,
      fldrId: id,
      name,
      purpose,
      private: privacy,
    };

    this.props.editFolder(data, token).then(() => {
      if (this.props.editedFolderDetails.length === 0) {
        this.snackBar("error", "Something went wrong!");
        return;
      }
      this.snackBar("success", "We've updated your folder✨");

      data = {
        aid,
        requester: this.state.username,
      };

      this.fetchBoardsFunction(data, token);
    });
  };

  //This method delete a folder
  handleRemoveFolder = async (e, name, _id, brdId) => {
    const { value: cta } = await Swal.fire({
      title: "Are you sure?",
      text: "This action can't be undone",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (cta) {
      const { aid, token } = this.props;
      let data = {
        aid: aid,
        folderId: _id,
      };
      await this.props.deleteFolder(data, token);
      data = {
        aid,
        requester: this.state.username,
      };
      this.fetchBoardsFunction(data, token);
      this.snackBar("success", "We've deleted your folder✨");
    }
  };

  //This method adds a new board
  handleAddBoard = async (e) => {
    swal({
      content: {
        element: "input",
        attributes: {
          placeholder: "Give your board a name",
          type: "text",
        },
      },
    }).then((_boardName) => {
      // early return if user has not entered the name yet
      if (!_boardName) return;

      //to check if user already has folder that he want to create
      let boardAlreadyPresent = false;
      this.state.boards.forEach((board) => {
        if (board.boardName === _boardName) {
          this.snackBar("error", "You already have this board!");
          boardAlreadyPresent = true;
        }
      });
      if (boardAlreadyPresent) return;

      const { aid, token } = this.props;
      let data = {
        aid: aid,
        boardName: _boardName,
      };

      this.props.addBoard(data, token).then(() => {
        if (this.props.createdBoard.length === 0) {
          this.snackBar("error", "Something went wrong!");
          return;
        }
        this.setState((prevState, props) => {
          this.props.createdBoard["boardItems"] = [];
          const newBoardsList = [...prevState.boards, this.props.createdBoard];
          return {
            boards: newBoardsList,
          };
        });
        this.snackBar("success", "We've created your Board✨");
      });
    });
  };

  handleRenameBoard = async (e, name, boardId) => {
    e.preventDefault();
    swal({
      content: {
        element: "input",
        attributes: {
          placeholder: "Give your board a new name...",
          type: "text",
          value: name,
        },
      },
    }).then((boardName) => {
      // early return if user has not entered the name yet
      if (!boardName) return;
      if (boardName === name) {
        return;
      }

      //to check if user already has folder that he want to create
      let boardAlreadyPresent = false;
      this.state.boards.forEach((board) => {
        if (board.boardName === boardName) {
          this.snackBar("error", "You already have this board!");
          boardAlreadyPresent = true;
        }
      });
      if (boardAlreadyPresent) return;

      const { aid, token } = this.props;
      let data = {
        aid,
        boardName,
        boardId,
      };

      this.props.renameBoard(data, token);
      this.snackBar("success", "Board Renamed.");
      let boards = this.state.boards;
      boards.map((board) => {
        if (board.boardName === name) {
          board.boardName = boardName;
        }
      });
      this.setState({ boards });
    });
  };

  handleRemoveBoard = async (e, name, _id) => {
    const { value: cta } = await Swal.fire({
      title: "Are you sure?",
      text: "This action can't be undone",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });
    if (cta) {
      const { aid, token } = this.props;
      let data = {
        aid: aid,
        brdId: _id,
      };
      await this.props.deleteBoard(data, token).then(() => {
        this.setState((prevState, props) => {
          const newFoldersList = prevState.boards.filter(
            (thisFolder) =>
              thisFolder.boardName !== name && thisFolder._id !== _id
          );
          return { boards: newFoldersList };
        });
        this.snackBar("success", `Board ${name} is deleted!`);
      });
    }
  };

  /*=================================================updated folder handlers end=================================================================*/

  changeVisibility = async (e, _id, _private) => {
    var data = {
      aid: this.props.aid,
      brdId: _id,
      private: !_private,
    };
    await this.props.changeBoardVisibility(data, this.props.token);
  };

  //This method expand the folder container height
  handleChangeHeight = (e) => {
    // setTimeout(function(){
    //   const container=document.querySelector(".folder_container");
    //   container.style.height = "auto";
    //   container.style.transition = "height 0.25s ease-in";
    // },1000);
  };
  //This method reset the folder container height
  handleResetHeight = (e) => {
    // document.querySelector(".folder_container").style.height = "180px";
  };

  //This method search lists in board and display the result: TODO
  // currently searching name of boards/stacks
  handleSearch = (e) => {
    e.preventDefault();
    let query = e.target.value;
    this.setState({ query });
    let { boards, folders } = this.state;
    if (query) {
      let filteredBoards = boards.filter((board) => {
        return board.boardName.toLowerCase().includes(query);
      });
      let filteredFolders = folders.filter((folder) => {
        return folder.name.toLowerCase().includes(query);
      });
      this.setState({
        searchedBoards: filteredBoards,
        searchedFolders: filteredFolders,
      });
    } else {
      return;
    }
  };
  /**********************************These are the codes for handling drag events************************************/

  handleDragStart = (e, folder, source, sourceBoard, id, category, bId) => {
    // console.log(folder," Drag start");
    // following stores the data of folders being dragged for future use
    e.dataTransfer.setData("id", folder);
    e.dataTransfer.setData("source", source);
    e.dataTransfer.setData("sourceBoard", sourceBoard);
    e.dataTransfer.setData("_id", id);
    e.dataTransfer.setData("bId", bId);
    e.dataTransfer.setData("category", category);
  };

  handleOnDragOver = (e) => {
    //This makes item droppable
    e.preventDefault();
    // console.log("Drag over");
  };

  handleOnDragOver = (e) => {
    //This makes item droppable
    e.preventDefault();
    // console.log("Drag over");
  };

  handleOnDrop = async (e, category, destination, board_id) => {
    e.preventDefault();

    /* Here is the list of variable used in this function for reference:
     *
     * category= name of board(or just folder) on which dragged folder is dropped
     * destination= type of container where folder is dropper i.e boards or folders
     * source= this tells whether the item was dragged from folders or boards
     * folderBeingDragged= this tells the name of item being draged
     * sourceBoard= this tells from which board the folder was dragged
     */

    // The following will be used to update the correct state
    let folderBeingDragged = e.dataTransfer.getData("id");
    let source = e.dataTransfer.getData("source");
    let sourceBoard = e.dataTransfer.getData("sourceBoard");
    let cate = e.dataTransfer.getData("category");
    let _id = e.dataTransfer.getData("_id");
    let bId = e.dataTransfer.getData("bId");
    // check if item is dropped in the same container from where it was dragged
    if (sourceBoard === category) return;

    // console.log(folderBeingDragged," of ",source," draged from ", sourceBoard ," and Dropped on ", category ," of ", destination); //To check if everything is working correctly

    // let newBoards = this.state.boards;
    // let newFolders = this.state.folders;

    // //Remove the folder from destination container
    // if (source === "folders") {
    //   const { aid, token } = this.props;

    //   newFolders = newFolders.filter(
    //     folder => folder.name !== folderBeingDragged
    //   );

    // } else if (source === "boards") {

    //   newBoards.forEach(board => {
    //     if (board.boardName === sourceBoard) {
    //       board.boardItems = board.boardItems.filter(
    //         boardItem => boardItem.name !== folderBeingDragged
    //       );
    //       return;
    //     }
    //   });
    // }

    // Add the folder to the source container
    if (destination === "folders") {
      const { aid, token } = this.props;

      let data = {
        aid: aid,
        container: 1,
        folderId: _id,
      };

      await this.props.updateFolder(data, token).then(() => {
        if (this.props.updatedFolder.length === 0) {
          this.snackBar("error", "Something went wrong!");
          return;
        }
        // newFolders.push(this.props.updatedFolder);
        data = {
          aid,
          requester: this.state.username,
        };
        this.fetchBoardsFunction(data, token);
      });
    } else if (destination === "boards") {
      for (const board of this.state.boards) {
        if (board.boardName === category) {
          const { aid, token } = this.props;

          let data = {
            aid: aid,
            container: 2,
            folderId: _id,
            boardId: board._id || bId || board_id,
          };

          await this.props.updateFolder(data, token).then(() => {
            if (this.props.updatedFolder.length === 0) {
              this.snackBar("error", "Something went wrong!");
              return;
            }
            data = {
              aid,
              requester: this.state.username,
            };
            this.fetchBoardsFunction(data, token);
            // board.boardItems.push(this.props.updatedFolder);
          });
        }
      }
    }

    // Update the state now
    // this.setState((prevState, props) => {
    //   return {
    //     folders: newFolders,
    //     boards: newBoards
    //   };
    // });
    this.snackBar("success", "We've updated your folder✨");
  };

  /******************************************************************************************************************/

  toggleBookMarkModal = (step) => {
    this.setState({
      bookmarkModalOpen: !this.state.bookmarkModalOpen,
      modal: step,
    });
  };

  handleShowItems = async (e, id, name, purpose) => {
    this.props.history.push(getStackPath(name, id));
  };

  handleRequestClose = () => {
    this.setState({ anchorEl: null });
  };

  async componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.user_name !== this.props.match.params.user_name
    ) {
      const { token, aid, user_name } = this.props;

      let username = this.props.match.params.user_name;
      let data = {
        aid,
        user_name: username,
      };

      if (user_name !== username) {
        await this.props.getPublicProfile(data, token);
        if (Object.keys(this.props.publicProfile).length === 0) {
          this.props.history.push("/notfound");
          return;
        } else {
          await this.setState({ publicProfile: this.props.publicProfile });
        }
      }
      if (this.props.publicProfile || username === user_name) {
        if (user_name === username) {
          await this.setState({ isSelfProfile: true });
        }
        await this.setState({ username });

        data = {
          aid,
          requester: username,
        };

        this.setState({ boards: [], folders: [] });

        this.fetchBoardsFunction(data, token);
        data = {
          aid,
          uname: username,
        };

        await Promise.all([
          this.props.fetchUserBookmarks(data, token),
          this.props.fetchFollowingUserList(data, token),
          this.props.fetchUsersBookmarked({ aid }, token),
        ]);
      } else {
        return;
      }
    }
  }

  async componentWillMount() {
    const { token, aid, user_name } = this.props;

    let username = this.props.match.params.user_name;
    let data = {
      aid,
      user_name: username,
    };

    if (user_name !== username) {
      await this.props.getPublicProfile(data, token);
      if (Object.keys(this.props.publicProfile).length === 0) {
        this.props.history.push("/notfound");
        return;
      } else {
        await this.setState({ publicProfile: this.props.publicProfile });
      }
    }
    if (this.props.publicProfile || username === user_name) {
      if (user_name === username) {
        await this.setState({ isSelfProfile: true });
      }
      await this.setState({ username });

      data = {
        aid,
        requester: username,
      };

      this.fetchBoardsFunction(data, token);

      data = {
        aid,
        uname: username,
      };

      await Promise.all([
        this.props.fetchUserBookmarks(data, token),
        this.props.fetchFollowingUserList(data, token),
        this.props.fetchProfile(data, token),
      ]);
    } else {
      return;
    }
  }

  fetchBoardsFunction = async (data, token) => {
    await this.props.fetchBoards(data, token).then(() => {
      const { folders, boards } = this.props;
      this.setState({ folders: [], boards: [] });
      for (let folderi = 0; folderi < folders.length; folderi++) {
        const newFoldersList = [...this.state.folders, folders[folderi]];
        this.setState({ folders: newFoldersList });
      }

      for (let boardi = 0; boardi < boards.length; boardi++) {
        boards[boardi]["boardItems"] = boards[boardi].folders;
        if (
          !this.state.isSelfProfile &&
          boards[boardi]["boardItems"].length !== 0
        ) {
          boards[boardi]["boardItems"].map((bitm, i) => {
            if (bitm.private == true) {
              boards[boardi]["boardItems"].splice(i, 1);
            }
          });
        }
        const newBoardsList = [...this.state.boards, boards[boardi]];
        this.setState({ boards: newBoardsList });
      }
    });
  };

  async fetchFolderItems(item, id) {
    var lists = item.lsids;
    var lsitems = item.lsitids;
    const { aid, token } = this.props;
    await this.props
      .fetchFldrItemDetails({ aid: aid, fid: id }, token)
      .then(() => {
        var newArr1 = [];
        if (lsitems) {
          this.props.folderItemDetails.listItems.forEach((i) => {
            var key = Object.keys(i)[0];
            // TODO: Add links that point to the list item's list page
            lsitems.forEach((it) => {
              if (it === key) {
                newArr1.push(i[key]);
              }
            });
          });
        }

        var newArr2 = {};
        if (lists) {
          this.props.folderItemDetails.lists.forEach((i) => {
            var key = Object.keys(i)[0];
            lists.forEach((it) => {
              if (it === key) {
                newArr2[i["myname"]] = i[key];
                newArr2[i["myname"]]["myid"] = key;
              }
            });
          });
        }
        this.setState({ folderItems: { lists: newArr2, listItems: newArr1 } });
        this.setState({ folderItemsLoading: false });
      });
  }

  render() {
    const {
      bookmarkModalOpen,
      userFollow,
      followersModalOpen,
      followingModalOpen,
      modal,
    } = this.state;
    const { bookmarks, followingList, usersBookmarked } = this.props;
    var followsLength = 0;
    var followersLength = 0;
    if (followingList.length != 0) {
      // var userFollowers = followingList[0]['userFollowers'];
      // var userFollowing = followingList[0]['userFollowing'];
      followsLength = followingList[0].userFollowing.length;
      followersLength = followingList[0].userFollowers.length;
    }
    // console.log(userFollow);

    const renderUserData = () => {
      var data = [];
      if (modal == 0) {
        data = bookmarks;
      }
      if (modal == 1) {
        if (followingList.length == 0) {
          data = [];
        } else {
          data = followingList[0]["userFollowing"];
        }
      }
      if (modal == 2) {
        if (followingList.length == 0) {
          data = [];
        } else {
          data = followingList[0]["userFollowers"];
        }
      }

      if (!data) return <p>No data found!</p>;
      if (data.length <= 0)
        return (
          <p>
            <br />
            <div>No Data Found!</div>
          </p>
        );
      return data.map((item, index) => (
        <div key={index}>
          {modal == 0 && (
            <a href="#" className="list-group-item list-group-item-action">
              {item.list_name}
            </a>
          )}
          {modal == 1 && (
            <a href="#" className="list-group-item list-group-item-action">
              {item.user_name}
            </a>
          )}
          {modal == 2 && (
            <a href="#" className="list-group-item list-group-item-action">
              {item.user_name}
            </a>
          )}
        </div>
      ));
    };

    const renderUserFollow = () => {
      // console.log(this.state.userFollow);
      return userFollow.map((follows) => {
        return (
          <li key={follows._id} className="follow-list">
            <a href="#">
              <img src={"assets/nfound.png"} alt="" /> {follows.user_name}
            </a>
          </li>
        );
      });
    };
    // console.log(this.props.avatar, "selfwala")

    const profileData = {
      avatar: this.state.isSelfProfile
        ? this.props.avatar
          ? this.props.avatar
          : "assets/nfound.png"
        : this.state.publicProfile
           ? this.state.publicProfile.avatar
           : "assets/nfound.png",

      name: this.state.isSelfProfile
        ? this.props.name
          ? this.props.name
          : ""
        : this.state.publicProfile
        ? this.state.publicProfile.name
        : "",

      bio: this.state.isSelfProfile
        ? this.props.bio
          ? this.props.bio
          : ""
        : this.state.publicProfile
        ? this.state.publicProfile.bio
        : "",
      user_type: this.state.isSelfProfile
        ? this.props.user_type
          ? this.props.user_type
          : ""
        : this.state.publicProfile
        ? this.state.publicProfile.user_type
        : "",
      // bio: this.props.bio,
      user_name: this.state.username,
      bookmarksNo: bookmarks.length,
      followersNo: followersLength,
      followingNo: followsLength,
    };
    // console.clear()
    console.log(this.props)
    // console.log(profileData.avatar , "lessgo")

    return (
      <div
        className={"profile_container"}
        onClick={(e) => this.handleRequestClose(e)}
      >
        <ErrorAlert
          AutoHideSnackbar={this.AutoHideSnackbar}
          showAlert={this.state.showAlert}
          message={this.state.alertMessage}
          status={this.state.alertCode}
        />

        <ProfileHeader
          user_type={profileData.user_type}
          linkedIn_url={this.props.profile.linkedIn_url}
          fb_url={this.props.profile.fb_url}
          avatar={profileData.avatar}
          username={profileData.user_name}
          isSelfProfile={this.state.isSelfProfile}
          name={profileData.name}
          bio={profileData.bio}
          bookmarks={profileData.bookmarksNo}
          followers={profileData.followersNo}
          following={profileData.followingNo}
          toggleBookMarkModal={this.toggleBookMarkModal}
        />
        <ProfileTabs
          isSelfProfile={this.state.isSelfProfile}
          folders={
            this.state.query ? this.state.searchedFolders : this.props.folders
          }
          handleAddFolder={this.handleAddFolder}
          removeFolder={this.handleRemoveFolder}
          handleDragStart={this.handleDragStart}
          click={this.handleShowItems}
          context={this.handleContext}
          handleEditFolder={this.handleEditFolder}
          fetchUserLists={this.props.fetchUserLists}
          fetchUserProfileBookmarks={this.props.fetchUserProfileBookmarks}
          fetchUserListsWhereCollab={this.props.fetchUserListsWhereCollab}
          history={this.props.history}
          username={this.props.match.params.user_name}
          handleOnDragOver={this.handleOnDragOver}
          handleOnDrop={this.handleOnDrop}
          addListToFolder={this.props.addListToFolder}
          aid={this.props.aid}
          token={this.props.token}
          boards={this.props.boards}
          // {...this.props}
        />

        <Modal
          isOpen={bookmarkModalOpen}
          toggle={() => this.toggleBookMarkModal(modal)}
        >
          <ModalHeader toggle={() => this.toggleBookMarkModal(modal)}>
            {modal == 0 && "Bookmarked Lists"}
            {modal == 1 && "Following List"}
            {modal == 2 && "Followers Lists"}
          </ModalHeader>
          <ModalBody>
            <div className="list-group">{renderUserData()}</div>
          </ModalBody>
          <ModalFooter>
            <button
              onClick={() => this.toggleBookMarkModal(modal)}
              className="btn btn-primary"
            >
              Close
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={followersModalOpen} toggle={this.toggleFollowersModal}>
          <ModalHeader toggle={this.toggleFollowersModal}>
            Followers:
          </ModalHeader>
          <ModalBody>
            <ul className="follow_ul">{renderUserFollow()}</ul>
          </ModalBody>
          <ModalFooter>
            <button
              onClick={this.toggleFollowersModal}
              className="btn btn-primary"
            >
              Close
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={followingModalOpen} toggle={this.toggleFollowingModal}>
          <ModalHeader toggle={this.toggleFollowingModal}>
            Following:
          </ModalHeader>
          <ModalBody>
            <ul className="follow_ul">{renderUserFollow()}</ul>
          </ModalBody>
          <ModalFooter>
            <button
              onClick={this.toggleFollowingModal}
              className="btn btn-primary"
            >
              Close
            </button>
          </ModalFooter>
        </Modal>

        {/* Render Folder here */}
        {this.state.isSelfProfile ? (
          <React.Fragment>
            <div className="board_parent_container">
              <button className="add_board_btn" onClick={this.handleAddBoard}>
                Create Board <i class="fas fa-plus"></i>
              </button>
              <div
                className="search_container"
                style={{ display: "flex", width: "100%" }}
              >
                <input
                  type="text"
                  className="board_search board_search_pos"
                  placeholder="Search Folders/Boards"
                  onChange={(e) => this.handleSearch(e)}
                  style={{ alignSelf: "center" }}
                />
              </div>
              <Boards
                hideListModal={this.hideListModal}
                boards={
                  this.state.query
                    ? this.state.searchedBoards
                    : this.state.boards
                }
                addBoardItem={this.handleAddFolder}
                removeBoard={this.handleRemoveBoard}
                changeVisibility={this.changeVisibility}
                handleDragStart={this.handleDragStart}
                handleDragOver={this.handleOnDragOver}
                handleDrop={this.handleOnDrop}
                click={this.handleShowItems}
                context={this.handleContext}
                editFolder={this.handleEditFolder}
                removeFolder={this.handleRemoveFolder}
                isSelfProfile={this.state.isSelfProfile}
                handleRenameBoard={this.handleRenameBoard}
              />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="board_parent_container">
              <Boards
                hideListModal={this.hideListModal}
                boards={
                  this.state.query
                    ? this.state.searchedBoards
                    : this.state.boards
                }
                changeVisibility={this.changeVisibility}
                click={this.handleShowItems}
                isSelfProfile={this.state.isSelfProfile}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

Profile.propTypes = {
  // user constants
  token: PropTypes.string.isRequired,
  aid: PropTypes.string.isRequired,
  user_name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  name: PropTypes.string,
  bio: PropTypes.string,
  // profile
  getPublicProfile: PropTypes.func.isRequired,
  fetchFollowingUserList: PropTypes.func.isRequired,
  fetchUserBookmarks: PropTypes.func.isRequired,
  fetchUsersBookmarked: PropTypes.func.isRequired,
  fetchUserLists: PropTypes.func.isRequired,
  fetchUserProfileBookmarks: PropTypes.func.isRequired,
  fetchUserListsWhereCollab: PropTypes.func.isRequired,
  publicProfile: PropTypes.object,
  // folder
  addFolder: PropTypes.func.isRequired,
  createdFolder: PropTypes.object,
  editFolder: PropTypes.func.isRequired,
  editedFolderDetails: PropTypes.object,
  deleteFolder: PropTypes.func.isRequired,
  updateFolder: PropTypes.func.isRequired,
  updatedFolder: PropTypes.object,
  fetchFolderItems: PropTypes.func.isRequired,
  folderItems: PropTypes.array,
  fetchFldrItemDetails: PropTypes.func.isRequired,
  folderItemDetails: PropTypes.array,
  // board
  fetchBoards: PropTypes.func.isRequired,
  addBoard: PropTypes.func.isRequired,
  createdBoard: PropTypes.object,
  deleteBoard: PropTypes.func.isRequired,
  changeBoardVisibility: PropTypes.func.isRequired,
  renameBoard: PropTypes.func.isRequired,
  renamedBoard: PropTypes.object,
};

export default Profile;

// Following are the sub components used in the profile component-
const Boards = (props) => (
  <div className="board_container container-fluid" style={{ width: "90%" }}>
    <div className="row">
      {props.boards.map((board) => (
        <div
          className="board col col-xs-12 col-sm-6 col-lg-4 col-xl-3"
          key={board.boardName}
        >
          {props.isSelfProfile ? (
            <Board
              hideListModal={props.hideListModal}
              boardName={board.boardName}
              _id={board._id}
              boardItems={board.boardItems}
              addBoardItem={props.addBoardItem}
              handleEditFolder={props.handleEditFolder}
              removeBoard={props.removeBoard}
              changeVisibility={props.changeVisibility}
              handleDragStart={props.handleDragStart}
              handleDragOver={props.handleDragOver}
              handleDrop={props.handleDrop}
              handleClick={props.click}
              handleContext={props.context}
              private={board.private}
              editFolder={props.editFolder}
              removeFolder={props.removeFolder}
              isSelfProfile={props.isSelfProfile}
              handleRenameBoard={props.handleRenameBoard}
            />
          ) : (
            <Board
              hideListModal={props.hideListModal}
              boardName={board.boardName}
              boardItems={board.boardItems}
              changeVisibility={props.changeVisibility}
              handleClick={props.click}
              isSelfProfile={props.isSelfProfile}
            />
          )}
        </div>
      ))}
    </div>
  </div>
);

const Board = (props) => {
  const [state, setState] = React.useState({
    checkedPrivate: props.private,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [updateClicked, setUpdateClicked] = React.useState(false);

  const handleChange = (name, _id) => (event) => {
    setState({ ...state, [name]: event.target.checked });
    props.changeVisibility(event, _id, state.checkedPrivate);
  };

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function showHamMenu(event) {
    event.stopPropagation();
    setAnchorEl(null);

    setAnchorEl2(event.currentTarget);
  }

  function closeHamMenu() {
    setAnchorEl2(null);
  }

  const renameBoard = (e, name, id) => {
    setAnchorEl(false);
    props.handleRenameBoard(e, name, id);
  };

  const makePersist = () => {
    setUpdateClicked(true);
  };

  function handleDeleteBoard(e, name, _id) {
    setAnchorEl(null);
    props.removeBoard(e, name, _id);
  }

  return props.isSelfProfile ? (
    <div
      className="board_box"
      onDragOver={props.handleDragOver}
      onDrop={(e) => props.handleDrop(e, props.boardName, "boards", props._id)}
    >
      <div className="board_header">
        {/* <img src={"/assets/lists.png"} alt="" title="Upvote" /> */}
        <p className="h5">{props.boardName}</p>
        <hr />
      </div>
      <div className="board_body">
        {props.boardItems
          ? props.boardItems.map((boardItem, i) => (
              <>
                <div>
                  <div
                    key={i}
                    className="board_list_item"
                    draggable
                    onDragStart={(e) =>
                      props.handleDragStart(
                        e,
                        boardItem.name,
                        "boards",
                        props.boardName,
                        boardItem._id,
                        boardItem.category,
                        props._id
                      )
                    }
                    onContextMenu={(e) =>
                      props.handleContext(
                        e,
                        boardItem._id,
                        boardItem.name,
                        boardItem.private
                      )
                    }
                  >
                    <div className="board-folder">
                      <span
                        onClick={(e) =>
                          props.handleClick(
                            e,
                            boardItem._id,
                            boardItem.name,
                            boardItem.purpose
                          )
                        }
                        style={{
                          verticallyAlign: "middle",
                          width: "60%",
                          display: "inline",
                        }}
                      >
                        {boardItem.name}
                      </span>
                    </div>
                  </div>
                  <span
                    style={{
                      width: "5%",
                      display: "inline",
                    }}
                  >
                    <UncontrolledDropdown direction="right">
                      <DropdownToggle tag="a" className="ham-menu">
                        <span
                          className="ham-menu"
                          aria-controls="ham-menu"
                          aria-haspopup="true"
                        >
                          <MenuRoundedIcon />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu persist={updateClicked}>
                        <StackModal
                          mode="Update"
                          name={boardItem.name}
                          private={boardItem.private}
                          purpose={boardItem.purpose}
                          id={boardItem._id}
                          handleEditFolder={props.editFolder}
                          makePersist={makePersist}
                        />
                        <DropdownItem
                          onClick={(e) =>
                            props.removeFolder(
                              e,
                              boardItem.name,
                              boardItem._id,
                              props._id
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Remove
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </span>
                </div>
              </>
            ))
          : null}
      </div>
      <div className="board-addon">
        <button
          className="profile-board-setting-btn"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <i className="fas fa-cog" />
        </button>
        {!state.checkedPrivate ? (
          <div className="profile-board-private-indicator" title="public">
            <i className="fas fa-globe" />
          </div>
        ) : (
          ""
        )}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={(e) => handleDeleteBoard(e, props.boardName, props._id)}
          >
            <i className="fas fa-trash board-setting-items-icon"></i> Delete
            Board
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <i className="fas fa-copy board-setting-items-icon"></i> Copy
            Folders
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <i className="fas fa-arrows-alt board-setting-items-icon"></i> Move
            Folders
          </MenuItem>
          <MenuItem onClick={(e) => renameBoard(e, props.boardName, props._id)}>
            <i className="fas fa-edit board-setting-items-icon"></i> Rename
            Board
          </MenuItem>
          <MenuItem>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.checkedPrivate}
                    onChange={handleChange("checkedPrivate", props._id)}
                    value="checkedPrivate"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    color="primary"
                  />
                }
                label="Make Private"
                labelPlacement="start"
              />
            </FormGroup>
          </MenuItem>
        </Menu>
        <button className="add_board_Item_btn">
          <StackModal
            mode="Add"
            boardId={props._id}
            handleAddFolder={props.addBoardItem}
          />
        </button>
      </div>
    </div>
  ) : (
    <div className="board_box">
      <div className="board_header">
        <img src={"/assets/lists.png"} alt="" title="Upvote" />
        <p className="h5">{props.boardName}</p>
        <hr />
      </div>
      <div className="board_body">
        {props.boardItems.map((boardItem, i) => (
          <div
            key={i}
            className="board_list_item"
            onClick={(e) => props.handleClick(e, boardItem._id, boardItem.name)}
          >
            <p>{boardItem.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
