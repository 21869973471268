import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import new_icon from "./assets/listiee_new_icon.gif";
import { Zoom } from '@material-ui/core';
import "../styles/components/shareNewListModal.sass";
import { fetchMetaData } from "../actions/apiActions";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
} from 'react-share';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';






function ShareAskListModal(props) {

  let [metaTitle, setMetaTitle] = useState("");
  let [metaImage, setMetaImage] = useState("");
  let [metaDescription, setMetaDescription] = useState("");
  let [isCopied, setIsCopied] = useState(false);

  function handleCopy() {
    setIsCopied(true);
  }

  function onClose() {

    // closing the modal on click
    props.closeModal();


    // unblurring the background when the user clicks the close button

    document.querySelector(".MuiToolbar-regular").style.filter="none";
    let listContainer = document.querySelector(".list_container");
    listContainer.style.filter = "none";


  }


  let title = `🤗 Hey guys, I just asked for a ${props.listName} on Listiee @listiee01!

Can you please help 🥺👉🏻👉🏻👈🏻👈🏻?

#lists #listiee

`
  const listieeLogoLink = "https://media-exp1.licdn.com/dms/image/C560BAQH-K9C6CP3p6Q/company-logo_200_200/0/1586771782777?e=2159024400&v=beta&t=Kuo6mOX5akI2D36zKws4Yhsdyzd5_Pt8FuIbFv3274M";

  // "https://list.fit/rCxecLp6sl"
  let shareUrl = props.baseShareUrl + "sl";


// converting 1 to "first", 2 to "second" and 3 to "third" for description
  //
let listNumber = "";
  if (props.listsCreated == 1) {
    listNumber = "first";
  }
  else if ( props.listsCreated == 2) {
    listNumber = "second";
  }
  else if ( props.listsCreated == 3 ) {
    listNumber = "third";
  }

  useEffect( () => {

    let listContainer = document.querySelector(".list_container");
    listContainer.style.filter = "blur(6px)";

    document.querySelector(".MuiToolbar-regular").style.filter="blur(8px)";

    fetchMetaData({
      url: shareUrl,
    }).then((res) => {
      console.log(res);

      setMetaTitle(res.title);
      setMetaImage(res.image);
      setMetaDescription(res.description);


      this.setState({
        metaURL: res.url,
        metaTitle: res.title,
        metaDescription: res.description,
        metaImage: res.image,
        loadingMetadata: false,
      });
    });

  })








  let askListShareModal = (

    <Zoom in="true">

      <div className="share-new-list-modal">


        <CloseIcon className="close-icon" onClick={onClose} />


        <div className="description">
          <p>🤗 Cheers! Share this asked list with your friend circle 🧑🏻‍🤝‍🧑🏻! </p>
          <p>Maybe they can help! 🎈</p>
          <p>This is how it will look:</p>

        </div>

        <div className="responsive-section">

          <div className="meta-data">
            <h5 className="meta-title">{metaTitle}</h5>
            <p className="meta-description">{metaDescription}</p>
            <img className="meta-image" src={metaImage} alt="meta image"/>
          </div>

          <div className="social-container">
            <h5>Socials</h5>
            <div className="social-buttons">
              <div className="">
                <FacebookShareButton url={`${shareUrl}`} quote={title}>
                  {/* <a href="https://www.facebook.com/" target="_blank"> */}
                  <i class="shareIcon-social fab fa-facebook"></i>
                  {/* </a> */}
                </FacebookShareButton>

              </div>

              <div className="">
                <TwitterShareButton url={`${shareUrl}`} title={title}>
                  {/* <a href="https:twitter.com/" target="_blank"> */}
                  <i class="shareIcon-social fab fa-twitter"></i>
                  {/* </a>  */}
                </TwitterShareButton>
              </div>

              <div className="">
                <LinkedinShareButton url={`${shareUrl}`} title={title} >
                  {/* <a href="https://www.linkedin.com/" target="_blank"> */}
                  <i class="shareIcon-social fab fa-linkedin-in"></i>
                  {/* </a> */}

                </LinkedinShareButton>
              </div>

              <div className="">
                <RedditShareButton url={`${shareUrl}`} title={title}>
                  {/* <a href="https://www.reddit.com/" target="_blank"> */}
                  <i class="shareIcon-social fab fa-reddit"></i>
                  {/* </a> */}
                </RedditShareButton>
              </div>

              <div className="">
                <WhatsappShareButton url={`${shareUrl}`} title={title}>
                  {/* <a href="https://web.whatsapp.com/" target="_blank"> */}
                  <i class="shareIcon-social fab fa-whatsapp"></i>
                  {/* </a> */}

                </WhatsappShareButton>
              </div>

              <div className="">
                <TelegramShareButton url={`${shareUrl}`} title={title}>
                  {/* <a href="https://web.telegram.org/" target="_blank"> */}
                  <i class="shareIcon-social fab fa-telegram"></i>
                  {/* </a> */}
                </TelegramShareButton>
              </div>

              <div className="">
                <PinterestShareButton media={listieeLogoLink} url={`${shareUrl}`} description={title}>
                  {/* <a href="https://www.pinterest.com/" target="_blank"> */}
                  <i class="shareIcon-social fab fa-pinterest"></i>
                  {/* </a> */}
                </PinterestShareButton>
              </div>
            </div>

            <div>
            {/* <li className="tooltip" onClick={handleCopy}> */}
            <CopyToClipboard  className="copy-button" text={`${title} \n ${shareUrl}`}>
              <Button  variant="outlined" color="secondary" onClick={handleCopy}>
                {isCopied ? 'Copied!' : 'Copy link'}
              </Button>
            </CopyToClipboard>
            {/* <span className="">{isCopied ? 'Copied!' : 'Copy link'}</span> */}
            {/* </li> */}
</div>
<div>
            <Button className="ignore-button" variant="outlined" color="primary" onClick={onClose}>
              Ignore
            </Button>

            </div>


          </div>


        </div>

	    </div>

	  </Zoom>
  );

  return askListShareModal;

}


export default ShareAskListModal;
