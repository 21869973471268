import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import ListIcon from "@material-ui/icons/List";
import GroupIcon from "@material-ui/icons/Group";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Card, CardContent } from "@material-ui/core";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Fab from "@material-ui/core/Fab";
import "./profileTabs.sass";
import { connect } from "react-redux";

import {
  fetchUserLists,
  fetchUserProfileBookmarks,
  fetchUserListsWhereCollab,
} from "../../actions/apiActions";
import ProfileLists from "./ProfileLists";
import ProfileBookmarks from "./ProfileBookmarks";
import ProfileCollabLists from "./ProfileCollabLists";
import StackModal from "../Nested/StackModal";
import Tooltip from "@material-ui/core/Tooltip";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 1000,
    height: "550px",
    margin: "0 auto",
    backgroundColor: "#FAFAFA",
    position: "relative",
  },
  appBar: {
    backgroundColor: "#fafafa",
  },
  tabPanel: {
    height: "455px",
    // boxShadow: '0 2px 15px -3px rgba(0,0,0,.1), 0 4px 6px -2px rgba(0,0,0,.05)',
    paddingBottom: "2em",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
      "&:hover": {
        background: "#777",
      },
    },

    // 'webkit-scrollbar': {
    //   width: '5px'
    // },

    // 'webkit-scrollbar-track': {
    //   background: '#f1f1f1'
    // },

    // 'webkit-scrollbar-thumb': {
    //   background: '#888',
    //   borderRadius: '10px'
    // },
    // 'webkit-scrollbar-thumb' {
    //   '&:hover': {
    //     background: '#777'
    //   }
    // }
  },
}));

function ProfileTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [updateClicked, setUpdateClicked] = React.useState(false);

  const [totalLists, setTotalLists] = React.useState(0);
  const [totalFolders, setTotalFolders] = React.useState(0);
  const [totalBookmarks, setTotalBookmarks] = React.useState(0);
  const [totalistsWhereCollab, setTotalListsWhereCollab] = React.useState(0);

  const [listsCurrentPage, setListsCurrentPage] = React.useState(1);
  const [listsActivePage, setListsActivePage] = React.useState(1);

  const {
    folders,
    isSelfProfile,
    handleAddFolder,
    removeFolder,
    handleDragStart,
    click,
    context,
    handleEditFolder,
    fetchUserLists,
    fetchUserProfileBookmarks,
    fetchUserListsWhereCollab,
    handleOnDragOver,
    handleOnDrop,
  } = props;

  const makePersist = () => {
    setUpdateClicked(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const data = {
      aid: props.user.aid,
      email: props.user.email,
      username: props.username,
      page: listsCurrentPage,
      limit: 3,
    };
    fetchUserLists(data, props.user.token);
    fetchUserProfileBookmarks(data, props.user.token);
    fetchUserListsWhereCollab(data, props.user.token);
  }, []);

  useEffect(() => {
    if (props.myLists) {
      setTotalLists(props.myLists.total);
    }
  }, [props.myLists]);

  useEffect(() => {
    if (props.myListsWhereCollab) {
      setTotalListsWhereCollab(props.myListsWhereCollab.total);
    }
    // setLists(props.myBookmarks);
  }, [props.myListsWhereCollab]);

  useEffect(() => {
    if (props.myBookmarks) {
      setTotalBookmarks(props.myBookmarks.total);
    }
    // setLists(props.myBookmarks);
  }, [props.myBookmarks]);

  useEffect(() => {
    if (folders) {
      setTotalFolders(folders.length);
    }
  }, [folders]);

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          className="tabs"
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="icon label tabs"
        >
          <LinkTab
            className="link-tab"
            label={<div id="profile-appbar-texts">lists ({totalLists})</div>}
            icon={<ListIcon className="profile-appbar-icon" />}
            {...a11yProps(1)}
          />
          <LinkTab
            className="link-tab"
            label={
              <div id="profile-appbar-texts">folders ({totalFolders})</div>
            }
            icon={<FolderSpecialIcon className="profile-appbar-icon" />}
            {...a11yProps(0)}
          />
          {/* <LinkTab className="link-tab"
            label={`boards`}
            // icon={<GroupIcon />}
            {...a11yProps(2)}
          /> */}
          <LinkTab
            className="link-tab"
            label={
              <div id="profile-appbar-texts">
                contributions ({totalistsWhereCollab})
              </div>
            }
            icon={<GroupIcon className="profile-appbar-icon" />}
            {...a11yProps(2)}
          />
          {isSelfProfile && (
            <LinkTab
              className="link-tab"
              label={
                <div id="profile-appbar-texts">
                  bookmarks ({totalBookmarks})
                </div>
              }
              icon={<BookmarkIcon className="profile-appbar-icon" />}
              {...a11yProps(3)}
            />
          )}
          {/* <LinkTab
            label="collaborator"
            icon={<FavoriteIcon />}
            {...a11yProps(3)}
          />
          <LinkTab label="likes" icon={<FavoriteIcon />} {...a11yProps(4)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel
        className={classes.tabPanel}
        value={value}
        index={1}
        style={{ overflowY: "hidden" }}
        onDragOver={handleOnDragOver}
        onDrop={(e) => handleOnDrop(e, "folder", "folders")}
      >
        <Folders
          folders={folders}
          isSelfProfile={isSelfProfile}
          handleAddFolder={handleAddFolder}
          removeFolder={removeFolder}
          handleDragStart={handleDragStart}
          click={click}
          context={context}
          handleEditFolder={handleEditFolder}
          makePersist={makePersist}
          updateClicked={updateClicked}
        />
      </TabPanel>
      <TabPanel
        style={{ overflowY: "hidden" }}
        className={classes.tabPanel}
        value={value}
        index={0}
      >
        {/* <AllListsComponent lists={lists} /> */}
        <ProfileLists
	  isSelfProfile={props.isSelfProfile}
          username={props.username}
          total={totalLists}
          history={props.history}
          {...props}
        />
      </TabPanel>
      <TabPanel
        style={{ overflowY: "hidden" }}
        className={classes.tabPanel}
        value={value}
        index={2}
      >
        {/* <AllListsComponent lists={lists} /> */}
        <ProfileCollabLists
          username={props.username}
          total={totalistsWhereCollab}
          history={props.history}
          {...props}
        />
      </TabPanel>

      {isSelfProfile && (
        <TabPanel
          style={{ overflowY: "hidden" }}
          className={classes.tabPanel}
          value={value}
          index={3}
        >
          <ProfileBookmarks
            username={props.username}
            total={totalBookmarks}
            history={props.history}
          />
        </TabPanel>
      )}
    </div>
  );
}

const AllListsComponent = (props) => {
  const { lists } = props;
  return (
    lists &&
    lists.map((list, index) => {
      return <p key={index}>{list.name}</p>;
    })
  );
};

const Folders = (props) => {
  const [query, setQuery] = React.useState("");
  const [searchedFolders, setSearchedFolders] = React.useState([]);
  const handleFolderSearch = (e) => {
    e.preventDefault();
    let query = e.target.value;
    setQuery(query);
    let { folders } = props;
    if (query) {
      let filteredFolders = folders.filter((folder) => {
        return folder.name.toLowerCase().includes(query.toLowerCase());
      });

      setSearchedFolders(filteredFolders);
    } else {
      return;
    }
  };
  return (
    <React.Fragment>
      <div className="search_container">
        <input
          type="text"
          className="board_search list_search"
          placeholder="Search Folders"
          onChange={(e) => handleFolderSearch(e)}
        />
      </div>


      {query === '' ? (
        <div className="folder_container" >

          <div className="row" style={{ width: '100%', textAlign: 'center' }}>

	      {/* Only showing the add folder button when the user is on his own profile */}

	      {props.isSelfProfile ?
	       (
	  <div style={{display: "inline", filter: "grayscale(1)", width: "150px"} }>
	  <Folder mode="Add" handleClick={(event) => {event.preventDefault();} } name="Add a folder" >
	  </Folder>
	  <Tooltip title="Create a new folder" placement="top">
              <button className="add_folder_btn" style={{position: "relative", bottom: "76px", left: "43px", borderRadius: "40px", padding: "0", background: "antiquewhite"}}>
                <Fab color="primary" aria-label="add" className="pulse-btn">
                  <StackModal
                    mode="Add"
                    handleAddFolder={props.handleAddFolder}
                  />
                </Fab>
              </button>
	  </Tooltip>
	  </div>


	       )
	      
	      :
	       
	      " " 
	      
	      } 

	  
            {props.folders.map((folder, i) => (
              <div key={i} className="col-md-3 mb-3">
                <div key={folder.name}>
                  {props.isSelfProfile ? (
                    <Folder
                      _id={folder._id}
                      name={folder.name}
                      removeFolder={props.removeFolder}
                      handleDragStart={props.handleDragStart}
                      handleClick={props.click}
                      private={folder.private}
                      handleContext={props.context}
                      editFolder={props.handleEditFolder}
                      purpose={folder.purpose}
                      isSelfProfile={props.isSelfProfile}
                      makePersist={props.makePersist}
                      updateClicked={props.updateClicked}
                    />
                  ) : (
                    <Folder
                      _id={folder._id}
                      name={folder.name}
                      handleClick={props.click}
                      purpose={folder.purpose}
                      isSelfProfile={props.isSelfProfile}
                    />
                  )}
                </div>
              </div>
            ))}

          </div>
          {props.isSelfProfile ? (
            <div className="add_btn_wrapper" style={{ position: "absolute" }}>
              <button className="add_folder_btn">
                <Fab color="primary" aria-label="add" className="pulse-btn">
                  <StackModal
                    mode="Add"
                    handleAddFolder={props.handleAddFolder}
                  />
                </Fab>
              </button>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="folder_container">
          <div className="row" style={{ width: "100%", textAlign: "center" }}>
            {searchedFolders.map((folder, i) => (
              <div key={i} className="col-md-3 mb-3">
                <div key={folder.name}>
                  {props.isSelfProfile ? (
                    <Folder
                      _id={folder._id}
                      name={folder.name}
                      removeFolder={props.removeFolder}
                      handleDragStart={props.handleDragStart}
                      handleClick={props.click}
                      private={folder.private}
                      handleContext={props.context}
                      editFolder={props.handleEditFolder}
                      purpose={folder.purpose}
                      isSelfProfile={props.isSelfProfile}
                      makePersist={props.makePersist}
                      updateClicked={props.updateClicked}
                    />
                  ) : (
                    <Folder
                      _id={folder._id}
                      name={folder.name}
                      handleClick={props.click}
                      purpose={folder.purpose}
                      isSelfProfile={props.isSelfProfile}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          {props.isSelfProfile ? (
            <div className="add_btn_wrapper" style={{ position: "absolute" }}>
              <button className="add_folder_btn">
                <Fab color="primary" aria-label="add" className="pulse-btn">
                  <StackModal
                    mode="Add"
                    handleAddFolder={props.handleAddFolder}
                  />
                </Fab>
              </button>
            </div>
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

const Folder = (props) => {
  const [anchor, setAnchor] = React.useState(false);
  function toggle() {
    setAnchor(!anchor);
  }

  return props.isSelfProfile ? (
    <div
      className="folder"
      draggable
      onDragStart={(e) =>
        props.handleDragStart(
          e,
          props.name,
          "folders",
          "folder",
          props._id,
          props.category
        )
      }
    >
      <div
        className="folder-content"
        onClick={(e) =>
          props.handleClick(e, props._id, props.name, props.purpose)
        }
      >
        <img src={"/assets/folder.svg"} />
        <p>{props.name}</p>
      </div>
      <UncontrolledDropdown direction="right" isOpen={anchor} toggle={toggle}>
        <DropdownToggle tag="a">
          <MoreVertIcon />
        </DropdownToggle>
        <DropdownMenu persist={props.updateClicked}>
          <StackModal
            mode="Update"
            name={props.name}
            private={props.private}
            purpose={props.purpose}
            id={props._id}
            handleEditFolder={props.editFolder}
            makePersist={props.makePersist}
          />
          <DropdownItem
            onClick={(e) => props.removeFolder(e, props.name, props._id)}
            style={{ cursor: "pointer" }}
          >
            Remove
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  ) : (
    <div className="folder">
      <div
        className="folder-content"
        onClick={(e) =>
          props.handleClick(e, props._id, props.name, props.purpose)
        }
      >
        <img src={"/assets/folder.svg"} />
        <p>{props.name}</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    myLists: state.api.myLists,
    myBookmarks: state.api.myBookmarks,
    myListsWhereCollab: state.api.myListsWhereCollab,
    user: state.auth,
  };
};

const mapDispatchToProps = {
  fetchUserLists,
  fetchUserProfileBookmarks,
  fetchUserListsWhereCollab,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileTabs);
