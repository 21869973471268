import React, { Component } from 'react';
import { Doughnut } from "react-chartjs-2";

String.prototype.toColor = function () {
  var colors = ["#e51c23", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#5677fc", "#03a9f4", "#00bcd4", "#009688", "#259b24", "#8bc34a", "#afb42b", "#ff9800", "#ff5722", "#795548", "#607d8b"]

  var hash = 0;
  if (this.length === 0) return hash;
  for (var i = 0; i < this.length; i++) {
    hash = this.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return colors[hash];
}

class ActiveUsersChart extends Component {
  state = {
    data: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderColor: [],
        }
      ]
    }
  }

  componentWillReceiveProps(props) {
      var temp = this.state.data;
      temp.labels = this.props.countries;
      temp.datasets[0].data = this.props.views;
      for(let i = 0; i < temp.labels.length; i++) {
        temp.datasets[0].backgroundColor.push(temp.labels[i].toColor()+"66");
        temp.datasets[0].borderColor.push(temp.labels[i].toColor() + "80");
      }
      this.setState({data: temp});
  }

  render() {
    return (
      <React.Fragment>
        <span className = "chart-title">{this.props.chartName}</span>
        <Doughnut
            data = {this.state.data}
            options = {{
                circumference: Math.PI,
                rotation: -Math.PI,
                legend: {
                    display: true,
                    position: "right",
                    labels: {
                      fontColor: "#68667a",
                      fontSize: 12,
                      fontStyle: "bold"
                    }
                },
                layout: {
                  padding: {
                    left: 0,
                    right: 0,
                    top: 20,
                    bottom: 0
                  }
                },
                responsive: true,
                maintainAspectRatio: false,
            }}
        ></Doughnut>
      </React.Fragment>
    )
  }

}
 
export default ActiveUsersChart;