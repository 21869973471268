import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { addArea, fetchDetails } from "../actions/apiActions";
import Button from "@material-ui/core/Button";
import ErrorAlert from "./Nested/ErrorAlert";
import { getRegionAsync } from "../utils/utils";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import Map from "./Map/Map.jsx";
import Footer from "./Footer";
import { is_url } from "./Utils/helperFunctions";
import {NavLink} from 'react-router-dom'
import DatePicker from 'react-date-picker';


export default function Covid(props) {
    const [country, setCountry] = useState("India");
    const [primary, setPrimary] = useState("");
    const [list_name, setList_name] = useState("");
    const [states, setStates] = useState("");
    const [city, setCity] = useState("");
    const [links, setLinks] = useState([]);
    const [description, setDescription] = useState("");
    const [alert, setAlert] = useState({ show: false, msg: null, type: null });
    const [searchState, setSearchState] = useState("");
    const [coordinates, setcoordinates] = useState([12.971599, 77.594566]);
    // const [pinnedPlace, setPinnedPlace] = useState(null);
    // const [currentLocation, setCurrentLocation] = useState(null);

    const [image, setImage] = useState("");
    const [primaries, setPrimaries] = useState([]);
    const [contactName, setContactName] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [entity, setEntity] = useState("");
    const [entityType, setEntityType] = useState("Government");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [showHotspotIcon, setShowHotSpotIcon] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    const [isEntityRequired, setEntityRequired] = useState(true);
    const [isOther, setIsOther] = useState(false);
    const [primaryDesc, setPrimaryDesc] = useState("");
    const [isCity, setIsCity] = useState(false);
    const [otherPrimary, setOtherPrimary] = useState("");
    const [value, onChange] = useState(new Date());

    const AutoHideSnackbar = () => {
        setAlert({
            show: false,
        });
    };

    const snackBar = (type, msg) => {
        setAlert({
            show: true,
            msg: msg,
            type: type,
        });
    };

    useEffect(() => {
        (async () => {
            let { aid, email, token } = props;
            if (!token) {
                props.history.push("/", {
                    login_required: true,
                    redirect_to: props.location.pathname,
                });
            }

            let region = await getRegionAsync();
            if (region) {
                setCountry(region.country);
                setStates(region.regionName);
                setCity(region.city);
            }
            let res = await fetchDetails({ aid }, token);
            if (res.success) {
                setPrimaries(res.data.primaries);
                setPrimary(res.data.primaries[0].name);
                setCountry(
                    region.country ? region.country : res.data.countries[0]
                );
            }
        })();
    }, []);

    const handleGoBack = () => {
        props.history.goBack();
    };

    const handleChangeCountry = async (value) => {
        setCountry(value);
    };

    const handleChangePrimary = async (e) => {
        e.preventDefault();
        setPrimary(e.target.value);
        if (e.target.value === "other") {
            setIsOther(true);
        } else {
            if(isOther)
            setIsOther(false);
        }
    };

    const handleOtherPrimary = async (e) => {
        e.preventDefault(e.target.value);
        setOtherPrimary(e.target.value)
    };

    const handleChangelocation = async (e) => {
        e.preventDefault();
        setList_name(e.target.value);
        if (!e.target.value) {
            setIsLocked(!isLocked);
        }
        if (e.target.value.length > 9 && !isLocked) {
            const url = `https://www.mapquestapi.com/geocoding/v1/address?key=CJv78tAkodj9Qu6eWykXZDMGeYJ3lWZi&location=${e.target.value}&thumbMaps=false`;
            axios.get(url).then((res) => {
                const lat = res.data.results[0].locations[0].latLng.lat;
                const lng = res.data.results[0].locations[0].latLng.lng;
                setcoordinates([lat, lng]);
                setShowHotSpotIcon(true);
            });
        }
    };



    const handleChangeContactName = async (e) => {
        e.preventDefault();
        setContactName(e.target.value);
    };

    const handleChangeContactPhone = async (e) => {
        e.preventDefault();
        setContactPhone(e.target.value);
    };



    const isValidLink = () => {
        let status = true;
        for (let i = 0; i < links.length; i++) {
            if (!is_url(links[i])) {
                status = false;
                break;
            }
        }
        return status;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let { aid, token } = props;
        let phoneRegex = /(^[6-9]{1}[0-9]{9}$)/;

        if (!latitude || !longitude) {
            snackBar("error", "Please verify location on the map.");
            return;
        }
        if (!list_name) {
            snackBar("error", "Please provide proper area details");
        } else if (links.length === 0 || !isValidLink()) {
            snackBar("error", "Please provide correct Proof links");
        } else if (!primary || (isOther && !otherPrimary)) {
            snackBar("error", "Please enter proper primary");
        } else if (
            ((isOther && isEntityRequired) ||
                (!isOther &&
                    primaries.filter((prim) => {
                        return prim.name === primary && prim.isEntityRequired;
                    }).length)) &&
            (!contactName ||
                !contactPhone ||
                !phoneRegex.test(contactPhone) ||
                !entity)
        ) {
            snackBar(
                "error",
                "Please provide proper contact details and entity type."
            );
        } else if(isOther && !primaryDesc){
            snackBar(
                "error",
                "Please provide proper primary description."
            );
        } else {
            let form_data = new FormData();
            if (image !== "") {
                form_data.append("image", image, image.name);
            }
            form_data.append("aid", aid);
            form_data.append("country", country);
            form_data.append("primary", isOther ? otherPrimary : primary);
            form_data.append(
                "list_name",
                `${entity ? `${entity} ${list_name}` : list_name}`
            );
            form_data.append("state", states);
            form_data.append("city", city);
            form_data.append("links", links);
            form_data.append("description", description);
            if (
                (isOther && isEntityRequired) ||
                (!isOther &&
                    primaries.filter((prim) => {
                        return prim.name === primary && prim.isEntityRequired;
                    }).length)
            ) {
                form_data.append("contactName", contactName);
                form_data.append("contactPhone", contactPhone);
                form_data.append("entity", entityType);
            }
            if(isOther){
                form_data.append("primaryDesc", primaryDesc);
                form_data.append("isCity", isCity);
                form_data.append("isEntityRequired", isEntityRequired);
            }
            form_data.append("latitude", latitude);
            form_data.append("longitude", longitude);
            let res = await addArea(form_data, token);
            if (res.success) {
                snackBar("success", "Area submitted for review");
                props.history.goBack();
            } else {
                snackBar("error", "something went wrong");
            }
        }
    };

    const updatePlaceInput = async (lat, lon) => {
        const url = `https://open.mapquestapi.com/nominatim/v1/reverse.php?key=CJv78tAkodj9Qu6eWykXZDMGeYJ3lWZi&format=json&lat=${lat}&lon=${lon}`;
        const {
            data: {
                display_name,
                address: {
                    country,
                    city,
                    country_code,
                    town,
                    state_district,
                    state,
                    county,
                    postcode,
                },
            },
        } = await axios.get(url);
        // if (list_name) {
        setIsLocked(true);
        // }
        setList_name(display_name);
        setCity(city ? city : county ? county : town ? town : state_district);
        setStates(state ? state : county);
        setCountry(country);
        setShowHotSpotIcon(true);
        setLatitude(lat);
        setLongitude(lon);
        setcoordinates([lat, lon]);
    };

    const handleSearch = (e) => {
        if (e.keyCode == 13) {
            const url = `https://www.mapquestapi.com/geocoding/v1/address?key=CJv78tAkodj9Qu6eWykXZDMGeYJ3lWZi&location=${searchState}&thumbMaps=false`;
            axios.get(url).then((res) => {
                const lat = res.data.results[0].locations[0].latLng.lat;
                const lng = res.data.results[0].locations[0].latLng.lng;
                setcoordinates([lat, lng]);
            });
        }
    };

    const HandleEntityRequired = (e) => {
        // e.preventDefault();
        setEntityRequired(e.target.checked);
    };

    const handleOtherPrimaryDesc = (e) => {
        e.preventDefault();
        setPrimaryDesc(e.target.value);
    };

    const handleIsCity = (e) => {
        // e.preventDefault();
        setIsCity(e.target.checked);
    };

    return (
        <>
            <div className="container-fluid covid-container">
                <ErrorAlert
                    AutoHideSnackbar={AutoHideSnackbar}
                    showAlert={alert.show}
                    message={alert.msg}
                    status={alert.type}
                />
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <Map
                                coordinates={coordinates}
                                updatePlaceInput={updatePlaceInput}
                                handleChangeCountry={handleChangeCountry}
                                country={country}
                                showHotspotIcon={showHotspotIcon}
                            />
                        </div>

                        <div className="col-md-6">
                            <div className="live-lists">
                                Covid-19 Live Lists
                            </div>
                            <form noValidate autoComplete="off">
                                <div className="form-header">
                                    <select
                                        onChange={(e) => handleChangePrimary(e)}
                                        value={primary}
                                    >
                                        {primaries.map((primary, i) => {
                                            return (
                                                <option
                                                    value={primary.name}
                                                    key={i}
                                                >
                                                    {primary.name}
                                                </option>
                                            );
                                        })}
                                        <option value={"other"} key={"other"}>
                                            Other
                                        </option>
                                    </select>
                                    {isOther ? (
                                        <>
                                            <input
                                                className="text-input-other m-2"
                                                type="text"
                                                placeholder="Name of category"
                                                value={
                                                    otherPrimary
                                                }
                                                style={{ display: "block", width: "80%" }}
                                                onChange={(e) =>
                                                    handleOtherPrimary(e)
                                                }
                                            />
                                            <input
                                                className="text-input-other m-2"
                                                type="text"
                                                placeholder="Description"
                                                value={
                                                    primaryDesc
                                                }
                                                style={{ display: "block", width: "80%" }}
                                                onChange={(e) =>
                                                    handleOtherPrimaryDesc(e)
                                                }
                                            />
                                            <input
                                                type="checkbox"
                                                className="m-2"
                                                checked={isEntityRequired}
                                                onChange={(e) =>
                                                    HandleEntityRequired(e)
                                                }
                                            />
                                            Entity Required?
                                            <input
                                                type="checkbox"
                                                className="m-2"
                                                checked={isCity}
                                                onChange={(e) =>
                                                    handleIsCity(e)
                                                }
                                            />
                                            Whole City?
                                        </>
                                    ) : null}
                                </div>

                                <input
                                    className="text-input"
                                    type="text"
                                    placeholder="Request"
                                    
                                />

                                <input
                                    className="text-input"
                                    type="text"
                                    placeholder="Address..."
                                    value={list_name}
                                    onChange={(e) => handleChangelocation(e)}
                                />

                                {/* <input
                                    className="text-input"
                                    type="text"
                                    placeholder="City"
                                    style={{ width: "40%", marginRight: "10%" }}
                                    value={city}
                                    onChange={(e) => handleChangeCity(e)}
                                />
                                <input
                                    className="text-input"
                                    type="text"
                                    placeholder="State"
                                    style={{ width: "40%", marginLeft: "10%" }}
                                    value={states}
                                    onChange={(e) => handleChangeState(e)}
                                /> */}
                                {(isOther && isEntityRequired) ||
                                (!isOther &&
                                    primaries.filter((prim) => {
                                        return (
                                            prim.name === primary &&
                                            prim.isEntityRequired
                                        );
                                    }).length) ? (
                                    <>  
                                        
                                        <input
                                            className="text-input"
                                            type="text"
                                            placeholder="Contact name"
                                            value={contactName}
                                            onChange={(e) =>
                                                handleChangeContactName(e)
                                            }
                                        />
                                        <input
                                            className="text-input"
                                            type="Number"
                                            placeholder="Contact phone"
                                            value={contactPhone}
                                            onChange={(e) =>
                                                handleChangeContactPhone(e)
                                            }
                                        />
                
                                    </>
                                ) : null}

                                <DatePicker
                                        style ={{display: "block !important", width: "400px"}}
                                        onChange={onChange}
                                        value={value}
                                    />

                                <Button
                                    className="submit-for-review-btn"
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        marginTop: "100px",
                                        fontSize: "12px",
                                        marginLeft: "5%",
                                    }}
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    Submit
                                </Button>

                            </form>
                        </div>
                       
                        <div class="sticky-note">
                            <a
                                href={"https://tinyurl.com/ls-covid19"}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Help us manage and maintain quality of data by
                                becoming a volunteer.
                            </a>
                        </div>
                        
                    </div>
                    
                </div>

                <div className="res_req_link">
                    
                    
                        <NavLink className="left-link" to="/covid19">Response</NavLink>
                    
                    
                        <NavLink className="right-link" to="#" >Request</NavLink>
                    
                </div>
            </div>

            <Footer />
        </>
    );
}

Covid.propTypes = {
    aid: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
};
