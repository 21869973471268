import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import HomeIcon from '@material-ui/icons/Home';

import AuthContainer from "./AuthContainer";
import {
    HOME_PATH,
    REGISTER_PATH,
    FORGOT_PATH,
    LANDING_PAGE_PATH
} from "../../constants/pathConstants";
import ErrorAlert from "../Nested/ErrorAlert";
import SocialLogin from "./SocialLogin";

const Login = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState({ show: false, msg: null, type: null });

  useEffect(() => {
    if (props.token) {
      if (props.location.state && props.location.state.redirect_to) {
        props.history.replace(props.location.state.redirect_to);
      } else {
        props.history.replace(HOME_PATH);
      }
    }
  }, [props.token]);

  const AutoHideSnackbar = () => {
    setAlert({
      show: false,
    });
  };

  const showSnackBar = (msg, type) => {
    setAlert({
      show: true,
      msg: msg,
      type: type,
    });
  };

  const onSubmitLogin = async (e) => {

    e.preventDefault();
    props.clearAuthError();


	  /* let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/; */


      // this regex accepts anything except whitespaces
      let emailOrUsernameRegex = /^[a-zA-Z0-9!-£]*$/;

    if (email === "") {
      showSnackBar("Please enter your email", "error");
      return;
    } else if (password === "") {
      showSnackBar("Please enter your password", "error");
      return;
    }
      else if ( !emailOrUsernameRegex.test(String(email).trim()) ) {
	
	  showSnackBar("Invalid username or email", "error");
	  return;
	  
      }
      else {
	  const data = {
	      email,
	      password,
	  };
	  await props.loginUser(data).catch((err) => {console.log(err);});

	  if (props.token) {
	      if (props.location.state && props.location.state.redirect_to) {
		  props.history.push(props.location.state.redirect_to);
	      } else {
		  props.history.push(HOME_PATH);
	      }
	  } else {
	      showSnackBar("Invalid Credentials", "error");
	  }  


      }
      

  };

  return (

    <AuthContainer {...props}>
	

	{/* Redirecting the user to the landing page */}

      <div className={"modal_form"}>
        <ErrorAlert
          AutoHideSnackbar={AutoHideSnackbar}
          showAlert={alert.show}
          message={alert.msg}
          status={alert.type}
        />
        <form>
          <div className={"modal_form__field"}>
            <FormControl fullWidth>
              <TextField
                type={"text"}
                name={"email"}
                label="Username or Email"
                className={"custom-password-input"}
                value={email}
                onChange={(e) => {
                  e.preventDefault();
                  setEmail(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className={"modal_form__field"}>
            <FormControl fullWidth>
              <InputLabel htmlFor="standard-adornment-password">
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword ? "text" : "password"}
                name={"password"}
                value={password}
                label="Password"
                labelWidth={70}
                onChange={(e) => {
                  e.preventDefault();
                  setPassword(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div
            className="forgot_password"
            onClick={(e) => {
              e.preventDefault();
              props.history.push(FORGOT_PATH);
            }}
          >
            Forgot password?
          </div>
          <div
            style={{
              position: "relative",
              height: "60px",
              width: "100%",
            }}
          >
            <Button
              className={"modal_form__button"}
              color="primary"
              variant={"contained"}
              disabled={props.loading}
              style={{
                width: "100px",
                position: "absolute",
                right: "0px",
                fontWeight: "600",
                color: "#f2f2f2",
              }}
              type={"submit"}
              onClick={(e) => onSubmitLogin(e)}
            >
              Sign In
            </Button>
          </div>
          <div className="Signup_tagline mt-6">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                props.history.push(REGISTER_PATH);
              }}
            >
              Join Waitlist
            </a>
          </div>
        </form>
        <SocialLogin {...props} />
          <div className="community">
          <p className="community-text">
            Interested in becoming a moderator at Listiee? Fill up this{" "}
            <a
              target="_blank"
              href="https://tinyurl.com/listcomune"
              title="Fill-up this form.">
              form.
            </a>
          </p>
          <p className="community-action">
            For Feedback and Queries we are live on{" "}
            <a
              target="_blank"
              href="https://discord.gg/gutd565yY7"
              title="Join our Discord Channel">
		Discord
            </a>
          </p>
          </div>
      </div>
    </AuthContainer>
  );
};

export default Login;
