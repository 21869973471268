import React, { useState, Fragment } from "react";
import ErrorAlert from "../Nested/ErrorAlert";
// social icons
import { LinkedIn } from "react-linkedin-login-oauth2";
import GoogleLogin from "react-google-login";
// import TwitterLogin from "react-twitter-login";
import TwitterLogin from "react-twitter-auth";
import swal from "sweetalert";
import Cookies from "js-cookie";
import { COOKIE_AUTH } from "../../constants/baseConstants";
import {
  AUTH_TWITTER_API,
  AUTH_TWITTER_API_REVERSE,
} from "../../constants/authConstants";
import { twitterLogin } from "../../actions/authActions";

import {
  GOOGLE_CLIENT_ID,
  LINKEDIN_CLIENT_ID,
  TWITTER_KEY,
  TWITTER_SECRET,
} from "../../constants/keyConstants";

import {
  HOME_PATH,
  PROFILE_PATH,
  getProfilePath,
} from "../../constants/pathConstants";

const SocialLogin = (props) => {
  const [alert, setAlert] = useState({ show: false, msg: null, type: null });

  const AutoHideSnackbar = () => {
    setAlert({
      show: false,
    });
  };

  const showSnackBar = (msg, type) => {
    setAlert({
      show: true,
      msg: msg,
      type: type,
    });
  };

  const onFailed = (error) => {
    console.log(error);
  };

  const onSuccess = (response) => {
    response.json().then(async (body) => {
      console.log(body);
      await props.twitterLogin(body);
      if (props.location.state && props.token) {
        if (props.location.state.redirect_to) {
          props.history.push(props.location.state.redirect_to, {});
        } else {
          props.history.push(HOME_PATH);
        }
      }
    });
  };

  // const handleTwitter = async () => {
  //   props.clearAuthError();
  //   const result = window.open("http://localhost:5000/auth/twitter", "_self");
  //   console.log(result);
  //   // axios
  //   //   .get(AUTH_TWITTER_API)
  //   //   .then((res) => console.log(res.data))
  //   //   .catch((err) => console.log(err));
  //   // if (!oauth_token || !oauth_verifier) {
  //   //     window.location.reload();
  //   //     return;
  //   // }

  //   // let newData = {
  //   //     oauth_token,
  //   //     oauth_verifier,
  //   // };
  //  // await props.twitterLogin(newData);
  //   // console.log("AWAIT PROP.TWITTERLOGIN DONE");
  //   // if (props.location.state && props.token) {
  //   //     if (props.location.state.redirect_to) {
  //   //         props.history.push(props.location.state.redirect_to, {});
  //   //     } else {
  //   //         props.history.push(HOME_PATH);
  //   //     }
  //   // }
  //   // window.open("about:blank", "_self");
  //   // window.self.close();
  // };
  const authHandler = (err, data) => {
    props.clearAuthError();
    console.log(err, data);
  };

  const responseGoogle = async (response) => {
    props.clearAuthError();
    if (!response.profileObj) {
      // showSnackBar("Something went wrong", "error");
      return;
    }
    console.log("Inside response google");
    let newData = {
      email: response.profileObj.email,
      name: response.profileObj.name,
      avatar: response.profileObj.imageUrl,
    };
    console.log(newData);
    await props.googleLogin(newData);

    if (props.beta) {
      swal(
        "Registration successful",
        "We are really excited to see you on our platform",
        "success"
      );
      Cookies.remove(COOKIE_AUTH);
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }

    if (props.location.state && props.token) {
      if (props.location.state.redirect_to) {
        props.history.push(props.location.state.redirect_to, {});
      } else {
        props.history.push(HOME_PATH);
      }
    }
  };

  const handleLinkedinSuccess = async (data) => {
    props.clearAuthError();
    if (!data.code) {
      showSnackBar("Something went wrong", "error");
      return;
    }
    let newData = {
      code: data.code,
    };
    await props.linkedinLogin(newData);
    if (props.beta) {
      swal(
        "Registration successful",
        "We are really excited to see you on our platform",
        "success"
      );
      Cookies.remove(COOKIE_AUTH);
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
    if (props.location.state && props.token) {
      if (props.location.state.redirect_to) {
        props.history.push(props.location.state.redirect_to, {});
      } else {
        props.history.push(HOME_PATH);
      }
    }
  };

  const handleLinkedinFailure = (error) => {
    props.clearAuthError();
    showSnackBar("Something went wrong", "error");
    return;
  };

  return (
    <Fragment>
      {props.beta ? (
        <div
          style={{
            //display: "flex",
            //gap: "2.5rem",
            //padding: "0",
            //marginTop: "1.5rem",
            //marginBottom:"1.5rem",
            // height: "100%",
            // alignItems: "center",
            display: "inline-flex",
            gap: "23%",
            padding: "0 1em ",
            margin: "0",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <LinkedIn
              clientId={LINKEDIN_CLIENT_ID}
              onFailure={handleLinkedinFailure}
              onSuccess={handleLinkedinSuccess}
              redirectUri={`${window.location.origin}/linkedin`}
              scope="r_liteprofile r_emailaddress"
              renderElement={({ onClick, disabled }) => (
                <div onClick={onClick} disabled={props.loading}>
                  <i className="fab fa-linkedin" style={{}} />
                </div>
              )}
            />
          </div>
          <div>
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
              render={(renderProps) => (
                <div onClick={renderProps.onClick} disabled={props.loading}>
                  <i className="fab fa-google" style={{}} />
                </div>
              )}
            ></GoogleLogin>
          </div>
          <div>
            <TwitterLogin
              loginUrl={AUTH_TWITTER_API}
              onFailure={onFailed}
              onSuccess={onSuccess}
              requestTokenUrl={AUTH_TWITTER_API_REVERSE}
              showIcon={false}
              style={{ background: "none", border: "none" }}
              // customHeaders={customHeader}
            >
              <i
                className="fab fa-twitter"
                disabled={props.loading}
                style={{ fontSize: "1.5rem" }}
              ></i>
              {/* <b>Custom</b> Twitter <i>Login</i> content */}
            </TwitterLogin>
          </div>
        </div>
      ) : (
        <ul className="social_btn_wrapper">
          <ErrorAlert
            AutoHideSnackbar={AutoHideSnackbar}
            showAlert={alert.show}
            message={alert.msg}
            status={alert.type}
          />
          <li style={{ background: "#fff", padding: "0rem" }}>
            <LinkedIn
              clientId={LINKEDIN_CLIENT_ID}
              onFailure={handleLinkedinFailure}
              onSuccess={handleLinkedinSuccess}
              redirectUri={`${window.location.origin}/linkedin`}
              scope="r_liteprofile r_emailaddress"
              renderElement={({ onClick, disabled }) => (
                <span onClick={onClick} disabled={props.loading}>
                  <i
                    class="fab fa-linkedin-in"
                    style={{
                      color: "#0082CA",
                    }}
                  />
                </span>
              )}
            />
          </li>

          <li
            style={{
              background: "#fff",
            }}
          >
            <TwitterLogin
              loginUrl={AUTH_TWITTER_API}
              onFailure={onFailed}
              onSuccess={onSuccess}
              requestTokenUrl={AUTH_TWITTER_API_REVERSE}
              showIcon={true}
              style={{ background: "none", border: "none", padding: "0" }}
              // customHeaders={customHeader}
            >
              <span disabled={props.loading}>
                <i
                  class="fab fa-twitter"
                  style={{
                    color: "#55ACEE",
                  }}
                />
              </span>
            </TwitterLogin>
          </li>
          <li
            style={{
              background: "#fff",
              padding: "0rem",
            }}
          >
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
              render={(renderProps) => (
                <span onClick={renderProps.onClick} disabled={props.loading}>
                  <i
                    class="fab fa-google"
                    style={{
                      color: "#DD4B39",
                    }}
                  />
                </span>
              )}
            ></GoogleLogin>
          </li>
        </ul>
      )}
    </Fragment>
  );
};

export default SocialLogin;
