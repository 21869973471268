import React from "react";

export default class MediaRelations extends React.Component {
    render() {
        return (
            <div className="media-container">
                <div className="container">
                    <div className="greetings mb-3 mt-4">Hello Media <br />Welcome to Listiee 🎉</div>
                    <div className="content mb-2">
                        Interested in covering our story for public? It couldn't be better.
                        Please reach us out at for any interests & public queries. <a href="mailto:mpr@listiee.com" target="_top"> mpr[at]listiee.com</a>
                    </div>
                    <div className="mt-4">
                        <div className="media-footer">Follow us on social media</div>
                        <ul>
                            <li><a href="https://twitter.com/listiee01"><i className="fab fa-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/listiee/"><i className="fab fa-linkedin-in"></i></a></li>
                            <li><a href="https://discord.gg/gutd565yY7"><i className="fab fa-discord"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
