import React, { useState, useEffect } from "react";
import DetailedAnnouncements from "../NotificationDrawer/DetailedAnnouncements";
import axios from "axios";
import { GET_ANNOUNCEMENT_API } from "../../constants/apiConstants";

const Updates = () => {
  let [announcements, setAnnouncements] = useState([]);
  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        let response = await axios.post(GET_ANNOUNCEMENT_API);
        setAnnouncements(response.data.data);
        console.log("ADMIN ANNOUNCEMENTS");
        console.log(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchAnnouncements();
  }, []);
  return (
    <div
      className="updates-wrapper"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <DetailedAnnouncements announcements={announcements} />
    </div>
  );
};

export default Updates;
