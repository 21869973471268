import React, { Component } from "react";
import { Link } from "react-router-dom";
import ListSettingMob from "./ListSettingMob";
import {
  LOGIN_PATH,
  getEditPath,
  DISCOVER_PATH,
  HOME_PATH,
  getListPath,
} from "./../constants/pathConstants";
import homeIcon from "./assets/homeIcon.svg";

import { Drawer } from "@material-ui/core";
import { confirmDeletion } from "./Utils/helperFunctions";
import Report from "./Report";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import RecommendationsMob from "./Nested/RecommendationsMob";

export class ListMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lsid: "",
      listItems: [],
      selectedItem: {},
      isSelected: false,
      list: {},
      fetched: false,
      promoters: [],
      promotes: 0,
      isEditable: false,
      openOptionsDrawer: false,
      modal: false,
      filteredData: [],
      query: "",
    };
  }

  handlePromoteList = async (e, flag) => {
    e.preventDefault();
    if (!this.props.token) {
      this.props.history.push(LOGIN_PATH, {
        redirect_to: this.props.location.pathname,
      });
      return;
    }
    let data = {
      username: this.props.user_name,
      lsid: this.state.lsid,
    };
    if (flag == 1) {
      let promoters = new Set([
        ...(this.props.fetchedList.result[0].promoters.length !== 0
          ? this.props.fetchedList.result[0].promoters[0].users
          : []),
        this.props.user_name,
      ]);
      promoters = Array.from(promoters);

      this.setState({
        promoters,
        promotes: promoters.length,
      });
    } else {
      let promoters =
        this.props.fetchedList.result[0].promoters.length !== 0
          ? this.props.fetchedList.result[0].promoters[0].users
          : [];
      promoters.splice(promoters.indexOf(this.props.user_name), 1);

      this.setState({
        promoters,
        promotes: promoters.length,
      });
    }

    await this.props.promoteList(data, this.props.token);
    this.props.fetchListById(
      {
        nameSlug: this.props.fetchedList.result[0].slug,
        lsid: this.state.lsid,
        aid: this.props.aid,
        email: this.props.email,
      },
      this.props.token
    );
  };

  async componentWillMount() {
    if (!this.props.token) {
      // this.props.history.push("/", {
      //   login_required: true,
      //   redirect_to: this.props.location.pathname
      // });
      this.setState({ fetched: false });
      await this.props
        .fetchListByIdPublic({ lsid: this.props.match.params.id })
        .then(() => {
          this.setState({
            fetched: true,
          });
          this.setState({
            promoters:
              this.props.fetchedList &&
              this.props.fetchedList.result &&
              this.props.fetchedList.result[0].promoters &&
              this.props.fetchedList.result[0].promoters.length !== 0
                ? this.props.fetchedList.result[0].promoters[0].users
                : [],
            promotes:
              this.props.fetchedList &&
              this.props.fetchedList.result &&
              this.props.fetchedList.result[0].promoters &&
              this.props.fetchedList.result[0].promoters.length !== 0
                ? this.props.fetchedList.result[0].promoters[0].users.length
                : 0,
          });
          // await this.props.getRecommendation({
          //   category: this.props.fetchedList.result[0].category,
          //   lsid: this.props.match.params.id,
          // });
        });
      this.setState({ fetched: true });
    } else {
      var { aid, followingList, following } = this.props;
      var data = { aid };
      this.props.fetchFollowingUserList(data, this.props.token);
      this.props.fetchUsersBookmarked(data, this.props.token);

      // this.props.fetchListById({ lsid: this.props.match.params.id, aid: this.props.aid, email:this.props.email}, this.props.token);
      // fetching all folders and boarditems for add to folder functionality
      this.fetchMyFolders();
    }
    document.querySelector("body").classList.add("remove-scroll-bar");
  }

  fetchMyFolders = () => {
    if (!this.props.token) return;
    let folders = [];
    this.props
      .fetchBoards(
        { aid: this.props.aid, requester: this.props.user_name },
        this.props.token
      )
      .then(() => {
        this.props.folders.forEach((folder) => {
          folders.push({ _id: folder._id, name: folder.name });
        });
        this.props.boards.forEach((board) => {
          board.folders.forEach((folder) => {
            folders.push({ _id: folder._id, name: folder.name });
          });
        });
      });
    this.setState({ folders: folders });
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.token) {
      const paramsId = this.props.match.params.id;
      const paramsName = this.props.match.params.name;
      const paramsItemId = this.props.match.params.itemId;
      await this.fetchListFromServer(paramsId, paramsName, paramsItemId).then(
        async () => {
          await this.props.getRecommendation({
            category: this.props.fetchedList.result[0].category,
            lsid: this.props.match.params.id,
          });
        }
      );
      await this.props.getRecommendation({
        category: this.props.fetchedList.result[0].category,
        lsid: this.props.match.params.id,
      });
      var isEditable = false;
      if (this.isEditable()) {
        isEditable = true;
      }
      var list = this.props.fetchedList && this.props.fetchedList.result[0];
      var promoters =
        list.promoters.length !== 0 ? list.promoters[0].users : [];
      this.setState({
        listItems: this.props.listItems,
        list: list,
        fetched: true,
        isEditable: isEditable,
        promoters: promoters,
        promotes: promoters.length,
        lsid: this.props.match.params.id,
      });
      // console.clear();
      // console.log(this.props.recommendedList);
    }
  }

  fetchListFromServer = async (paramsId, paramsName, paramsItemId) => {
    await this.props
      .fetchListById(
        {
          nameSlug: paramsName,
          lsid: paramsId,
          aid: this.props.aid,
          email: this.props.email,
        },
        this.props.token
      )
      .then(() => {});
  };

  isEditable = () => {
    let list = this.props.fetchedList.result
      ? this.props.fetchedList.result[0]
      : null;
    if (!list) return false;

    if (list.close) return false;

    if ((list.public && !list.close) || list.aid == this.props.aid) {
      return true;
    } else if (list.collaborators && list.collaborators.length > 0) {
      let collabIndex = list.collaborators
        .map((collab) => {
          return collab.email;
        })
        .indexOf(this.props.email);
      if (
        collabIndex !== -1 &&
        list.collaborators[collabIndex].status === "accepted"
      ) {
        return true;
      } else {
        return false;
      }
    } else if (this.props.user_type !== "normal") {
      return true;
    } else {
      return false;
    }
  };

  showDetails = () => {
    var item = this.state.selectedItem;
    const goBack = () => {
      this.setState({
        selectedItem: {},
        isSelected: false,
      });
    };

    var renderHighlights = () => {
      return (
        <div className="list-highlight-mob">
          <h3>Highlight</h3>
          {item.highlights.map((highlight, index) => (
            <li key={index}>{highlight}</li>
          ))}
        </div>
      );
    };

    var renderAbout = () => {
      return (
        <>
          <h3>About-{">"}</h3>
          {item.about.map((x, index) => (
            <>
              <a href={x.link} target="_blank">
                <img src={x.icon} alt="img"></img>
              </a>
              <br />
            </>
          ))}
        </>
      );
    };

    var renderDescription = () => {
      return (
        <>
          {/* <h3>Description-{">"}</h3> */}
          <p className="list-mob-description">{item.description}</p>
        </>
      );
    };

    var renderSections = () => {
      return (
        <>
          {/* <h3>Sections-{">"}</h3> */}
          {item.sections.map((section, index) => (
            <>
              <p className="list-mob-section-title">{section.title}</p>
              {section.items.map((item, index) => (
                
                  <li className="list-mob-section-item">
                  <>
                    <a  href={item.link} target="_blank">
                      <img src={item.icon} alt="img"></img>
                    </a>
                    <br />
                  </>
                </li>
                
              ))}
            </>
          ))}
        </>
      );
    };

    var renderOtherLinks = () => {
      return (
        <>
          <h3>Other Links-{">"}</h3>
          {item.links.map((link) => (
            <a href={link} target="_blank">
              {link}
            </a>
          ))}
          <br />
        </>
      );
    };
    return (
      <>
        <h1>{item.list_name}</h1>

        {item.description ? renderDescription() : null}
        {item.highlights.length > 0 ? renderHighlights() : null}
        {item.about.length > 0 ? renderAbout() : null}
        {item.sections.length > 0 ? renderSections() : null}
        {item.links.length > 0 && item.links[0] !== ""
          ? renderOtherLinks()
          : null}

        {!(
          (item.links.length && item.links[0]) ||
          item.sections.length ||
          item.about.length ||
          item.highlights.length ||
          item.description
        ) ? (
          <> Nothing more for this item </>
        ) : null}
        <div className="back-btn-box">
        <button className="back-btn" onClick={goBack}><i className="fas fa-times"></i></button>
        </div>
        
      </>
    );
  };

  showTags = () => {
    return this.state.list.tags.map((tag) => (
      <small className="tag-mob">#{tag.tagName}</small>
    ));
  };

  showListDetails = () => {
    return (
      <div className="list-description-mob">
        {this.state.list.description ? (
          <div className="list-description-desc-mob">
            {this.state.list.description}
          </div>
        ) : null}
        {this.state.list.tags ? (
          <>
            <br />
            {this.showTags()}
          </>
        ) : (
          <small>
            <i>No tags</i>
          </small>
        )}
        {this.state.list.description || this.state.list.tags ? (
          <hr className="line" />
        ) : null}
      </div>
    );
  };

  showList = () => {
    const onSelectItem = (selectedItem) => {
      const paramsId = this.props.match.params.id;
      const paramsName = this.props.match.params.name;
      this.props.history.replace(
        getListPath(paramsName, paramsId, selectedItem._id)
      );
      this.setState({
        selectedItem,
        isSelected: true,
      });
    };
    return (
      <>
        {this.showListDetails()}
        {this.state.listItems.map((item, index) => {
          return (
            <li
              key={index}
              onClick={() => onSelectItem(item)}
              className="list-item-mob"
            >
              <div className="list-item-mob-left">{index + 1}.</div> 
              <div className="list-item-mob-right">{item.list_name}  <i className="fas fa-chevron-right"></i></div>
            </li>
          );
        })}
      </>
    );
  };

  handleEditlist = () => {
    const id = this.state.lsid;
    const slug = this.props.match.params.name;
    this.props.history.push(getEditPath(slug, id));
  };

  onDeleteList = async () => {
    let isConfirmed = await confirmDeletion();
    if (isConfirmed) {
      const { token, aid } = this.props;
      const { id } = this.props.match.params;
      const data = {
        aid,
        lsid: id,
      };
      console.log(data);
      const res = await this.props.deleteList(data, token);
      if (res !== "ERR") {
        this.props.setRefreshDiscover();
        this.props.history.push(DISCOVER_PATH);
      } else {
        this.snackBar("error", "Something went wrong!!");
      }
    }
  };

  handleReport = (e) => {
    e.preventDefault();
    if (!this.props.token) {
      this.props.history.push(LOGIN_PATH, { redirect_to: HOME_PATH });
      return;
    }

    console.log("ListMobile.jsx: Open the report modal.");

    this.setState({
      state: {
        lsId: this.props.fetchedList.result[0]._id,
        lsItId:
          this.props.match.params.itemId !== "item"
            ? this.props.match.params.itemId
            : "",
        listOwner: this.props.fetchedList.result[0].aid,
        type: "list",
      },
      isReportModalOpen: true,
    });
    // this.setState({
    //   isReportModalOpen: true,
    // });
    //setReportModal(true);
  };

  handleReportClose = () => {
    this.setState({
      isReportModalOpen: false,
    });
    //setReportModal(false);
  };

  toggle = () => {
    if (!this.props.token) {
      this.props.history.push(LOGIN_PATH, { redirect_to: HOME_PATH });
      return;
    }
    if (!this.state.modal && this.state.folders.length === 0) {
      this.fetchMyFolders();
    }
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleClose = () => {
    this.setState({
      filteredData: [],
    });

    this.setState({
      query: "",
    });
    //setFilteredData([]);
    //setQuery('');
  };

  shortenTitle = (str) => {
    let wordsLen = str.split(" ");
    if (wordsLen.length > 7) {
      let newWords = wordsLen.slice(0, 7).join(" ");
      return newWords + "...";
    } else {
      return str;
    }
  };

  handleSearch = (e) => {
    this.setState({
      query: e.target.value,
    });

    const data = this.props.foldersList.filter((element) => {
      return element.name
        .toLowerCase()
        .includes(this.state.query.toLowerCase());
    });

    this.setState({
      filteredData: data,
    });
  };

  handleClick = (e, folderId, listId) => {
    this.toggle();
    this.handleAddListToFolder(e, folderId, listId);
  };

  handleAddListToFolder = async (e, folderId, lsid) => {
    e.preventDefault();
    let data = {
      aid: this.props.aid,
      folderId,
      lsid,
    };
    console.log(data);
    await this.props.addListToFolder(data, this.props.token).then(() => {
      this.snackBar("success", `Added list to folder!!`);
    });
  };

  render() {
    return (
     <> 
     
      <div className="list-mob">
        {this.state.isReportModalOpen && (
          <Report
            {...this.props}
            state={this.state.state}
            isReportModalOpen={this.state.isReportModalOpen}
            handleReportClose={this.handleReportClose}
          />
        )}

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          onClosed={this.handleClose}
          className="list-folder-modal"
        >
          <div className="folder-search">
            <input
              type="search"
              input={this.state.query}
              placeholder="🔍 Search.."
              name=""
              id=""
              onChange={(e) => {
                this.handleSearch(e);
              }}
            />
          </div>
          <ModalBody
            style={{ height: "400px", overflowY: "scroll", paddingTop: " 0px" }}
            className="list-display-modal"
          >
            <ul className="modal-folder-names">
              {this.state.filteredData.length === 0
                ? this.state.folders.map((folder, i) => (
                    <li key={i}>
                      <div className="row">
                        <div className="col-sm-10">
                          <div className="modal-folder-title">
                            {this.shortenTitle(folder.name)}
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="modal-folder-icon">
                            <AddRoundedIcon
                              onClick={(e) => {
                                this.handleClick(
                                  e,
                                  folder._id,
                                  this.props.currentList._id
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))
                : this.state.filteredData.map((folder, i) => (
                    <li key={i}>
                      <div className="row">
                        <div className="col-sm-10">
                          <div className="modal-folder-title">
                            {this.shortenTitle(folder.name)}
                          </div>
                        </div>
                        <div className="col-sm-2">
                          <div className="modal-folder-icon">
                            <AddRoundedIcon
                              onClick={(e) => {
                                this.handleClick(
                                  e,
                                  folder._id,
                                  this.props.fetchedList.result[0]._id
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={this.toggle}
              style={{ fontSize: " 13px" }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {this.state.fetched && (
          <ListSettingMob
            className="listsetting-mob"
            {...this.props}
            aid={this.props.aid}
            currentList={this.props.fetchedList.result[0]}
            promoters={this.state.promoters}
            promotes={this.state.promotes}
            isEditable={this.state.isEditable}
            handlePromoteList={this.handlePromoteList}
            handleEditlist={this.handleEditlist}
            openOptionsDrawer={() => this.setState({ openOptionsDrawer: true })}
          />
        )}
        <div className="list-mob-content">
          <div className="list-mob-content-title">
            <span className="list-mob-image-title">
              <img src={"/assets/lists.png"} alt="Listiee icon" />
              <h1> {this.state.list.name} </h1>
            </span>
          </div>
          <div className="list-main-area">
            <div  >
              {this.state.isSelected ? this.showDetails() : this.showList()}
            </div>
            <div className="recommendedList-mob">
              {this.state.fetched && this.props.recommendedList && (
              <RecommendationsMob
                recommendations={this.props.recommendedList}
              />
            )}
            </div>
            
          </div>
        </div>
        <Drawer
          anchor="bottom"
          open={this.state.openOptionsDrawer}
          onClose={() => this.setState({ openOptionsDrawer: false })}
        >
          <div className="list-mob-drawer-content">
            <h5 style={{ color: "grey" }}>Additional options</h5>
            {(this.state.fetched &&
              this.props.fetchedList.result[0].aid === this.props.aid) ||
            this.props.user_type === "amender" ? (
              <div className="metrics-item" onClick={this.onDeleteList}>
                <i
                  title="Delete List"
                  // data-toggle="modal"
                  // data-target="#deleteModal"
                  className={"far fa-trash-alt delete-btn"}
                />{" "}
                <b>Delete List</b>
              </div>
            ) : null}

            <div className="metrics-item" onClick={this.toggle}>
              <i className="fas fa-folder-plus" title="add to folder"></i>{" "}
              <b>Add to folder</b>
            </div>

            <div className="metrics-item" onClick={(e) => this.handleReport(e)}>
              <i className="fas fa-flag" title="Report/Flag"></i>{" "}
              <b>Report/Flag</b>
            </div>

            <div className="metrics-item">
              <i
                className="fa fa-sticky-note"
                title="List notes"
                style={{ paddingBottom: "0" }}
                onClick={this.handleShowListNotes}
              />{" "}
              <b>List notes</b>
            </div>
          </div>
        </Drawer>
      </div>
      </>
    );
  }
}

export default ListMobile;
