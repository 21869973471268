import {
    CREATE_PROSPECTIVE,
    CREATE_PROSPECTIVE_ITEM,
    EDIT_PROSPECTIVE,
    FETCH_PROSPECTIVE,
    DELETE_PROSPECTIVE,
    PROSPECTIVE_ERROR,
    CLEAR_PROSPECTIVE_ERROR
} from "../constants/actionTypes";

const initialState = {
    prospectives: [],
    prospectiveItems: [],
    editProspective: "",
    deleteProspective: "",
    createProspective: "",
    prospectiveError: ""
};

const prospective = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PROSPECTIVE:
            return {
                ...state,
                createProspective: action.payload
            };
        case CREATE_PROSPECTIVE_ITEM:
            return {
                ...state,
                prospectiveItems: action.payload
            };
        case EDIT_PROSPECTIVE:
            return {
                ...state,
                editProspective: action.payload
            };
        case FETCH_PROSPECTIVE:
            return {
                ...state,
                prospectives: action.payload
            };
        case DELETE_PROSPECTIVE:
            return {
                ...state,
                deleteProspective: action.payload
            };
        case PROSPECTIVE_ERROR:
            return {
                ...state,
                prospectiveError: action.payload
            };
        case CLEAR_PROSPECTIVE_ERROR:
            return {
                ...state,
                prospectiveError: ""
            };
        default:
            return state;
    }
};

export default prospective;
